import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { v4 } from 'uuid';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const hideLoading = request.headers.get('X-Hide-Loading') === 'true';
    const requestId = `HTTP-${v4()}`;

    if (!hideLoading) {
      this.loadingService.startLoading(requestId, request.url);
    }

    return next.handle(request).pipe(
      tap({
        finalize: () => {
          if (!hideLoading) {
            this.loadingService.stopLoading(requestId);
          }
        },
      }),
    );
  }
}
