import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ListingsService } from '../../../services/listings.service';
import { SelectOptionsService } from '../../../services/select-options.service';
import { map2User, routeListingWithState } from '../../../utils/utils';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCurrencyDirective } from 'ngx-currency';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { QuillModule } from 'ngx-quill';
import { BusinessListing, CommercialPropertyListing, User } from '../../../../../../bizmatch-server/src/models/db.model';
import { AutoCompleteCompleteEvent, ImageProperty, UploadParams, createDefaultCommercialPropertyListing, emailToDirName } from '../../../../../../bizmatch-server/src/models/main.model';

import { environment } from '../../../../environments/environment';
import { ConfirmationComponent } from '../../../components/confirmation/confirmation.component';
import { ConfirmationService } from '../../../components/confirmation/confirmation.service';
import { DragDropMixedComponent } from '../../../components/drag-drop-mixed/drag-drop-mixed.component';
import { ImageCropAndUploadComponent, UploadReponse } from '../../../components/image-crop-and-upload/image-crop-and-upload.component';
import { MessageService } from '../../../components/message/message.service';
import { ValidatedCityComponent } from '../../../components/validated-city/validated-city.component';
import { ValidatedInputComponent } from '../../../components/validated-input/validated-input.component';
import { ValidatedLocationComponent } from '../../../components/validated-location/validated-location.component';
import { ValidatedNgSelectComponent } from '../../../components/validated-ng-select/validated-ng-select.component';
import { ValidatedPriceComponent } from '../../../components/validated-price/validated-price.component';
import { ValidatedQuillComponent } from '../../../components/validated-quill/validated-quill.component';
import { ValidationMessagesService } from '../../../components/validation-messages.service';
import { ArrayToStringPipe } from '../../../pipes/array-to-string.pipe';
import { AuthService } from '../../../services/auth.service';
import { GeoService } from '../../../services/geo.service';
import { ImageService } from '../../../services/image.service';
import { LoadingService } from '../../../services/loading.service';
import { UserService } from '../../../services/user.service';
import { SharedModule } from '../../../shared/shared/shared.module';
import { TOOLBAR_OPTIONS } from '../../utils/defaults';
@Component({
  selector: 'commercial-property-listing',
  standalone: true,
  imports: [
    SharedModule,
    ArrayToStringPipe,
    DragDropModule,
    QuillModule,
    NgxCurrencyDirective,
    NgSelectModule,
    ImageCropperComponent,
    ConfirmationComponent,
    DragDropMixedComponent,
    ValidatedInputComponent,
    ValidatedQuillComponent,
    ValidatedNgSelectComponent,
    ValidatedPriceComponent,
    ValidatedLocationComponent,
    ValidatedCityComponent,
    ImageCropAndUploadComponent,
  ],
  providers: [],
  templateUrl: './edit-commercial-property-listing.component.html',
  styleUrl: './edit-commercial-property-listing.component.scss',
})
export class EditCommercialPropertyListingComponent {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  listingsCategory = 'commercialProperty';
  category: string;
  location: string;
  mode: 'edit' | 'create';
  separator: '\n\n';
  listing: CommercialPropertyListing;
  private id: string | undefined = this.activatedRoute.snapshot.params['id'] as string | undefined;
  user: User;
  maxFileSize = 3000000;
  environment = environment;
  responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1,
    },
  ];
  config = { aspectRatio: 16 / 9 };
  editorModules = TOOLBAR_OPTIONS;

  draggedImage: ImageProperty;
  faTrash = faTrash;
  suggestions: string[] | undefined;
  data: BusinessListing;
  userId: string;
  typesOfCommercialProperty = [];
  listingCategories = [];
  env = environment;
  ts = new Date().getTime();
  quillModules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ header: [1, 2, 3, 4, 5, 6, false] }], [{ color: [] }, { background: [] }], ['clean']],
  };
  //showModal = false;
  imageChangedEvent: any = '';
  croppedImage: Blob | null = null;
  uploadParams: UploadParams;
  constructor(
    public selectOptions: SelectOptionsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private listingsService: ListingsService,
    public userService: UserService,

    private geoService: GeoService,
    private imageService: ImageService,
    private loadingService: LoadingService,

    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private viewportRuler: ViewportRuler,
    private validationMessagesService: ValidationMessagesService,
    private authService: AuthService,
  ) {
    // Abonniere Router-Events, um den aktiven Link zu ermitteln
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.mode = event.url.startsWith('/createCommercialPropertyListing') ? 'create' : 'edit';
      }
    });
    this.route.data.subscribe(async () => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.data = this.router.getCurrentNavigation().extras.state['data'];
      }
    });
    this.typesOfCommercialProperty = selectOptions.typesOfCommercialProperty.map(e => {
      return { name: e.name, value: e.value };
    });
  }
  async ngOnInit() {
    const token = await this.authService.getToken();
    const keycloakUser = map2User(token);
    const email = keycloakUser.email;
    this.user = await this.userService.getByMail(email);
    this.listingCategories = this.selectOptions.listingCategories
      .filter(lc => lc.value === 'commercialProperty' || this.user.customerType === 'professional')
      .map(e => {
        return { name: e.name, value: e.value };
      });
    if (this.mode === 'edit') {
      this.listing = (await lastValueFrom(this.listingsService.getListingById(this.id, 'commercialProperty'))) as CommercialPropertyListing;
    } else {
      this.listing = createDefaultCommercialPropertyListing();
      const listingUser = await this.userService.getByMail(keycloakUser.email);
      this.listing.email = listingUser.email;
      this.listing.imagePath = `${emailToDirName(keycloakUser.email)}`;
      if (this.data) {
        this.listing.title = this.data?.title;
        this.listing.description = this.data?.description;
      }
    }
  }
  ngOnDestroy() {
    this.validationMessagesService.clearMessages(); // Löschen Sie alle bestehenden Validierungsnachrichten
  }
  async save() {
    try {
      this.listing = (await this.listingsService.save(this.listing, this.listing.listingsCategory)) as CommercialPropertyListing;
      this.router.navigate(['editCommercialPropertyListing', this.listing.id]);
      this.messageService.addMessage({ severity: 'success', text: 'Listing changes have been persisted', duration: 3000 });
      this.validationMessagesService.clearMessages(); // Löschen Sie alle bestehenden Validierungsnachrichten
    } catch (error) {
      this.messageService.addMessage({
        severity: 'danger',
        text: 'An error occurred while saving the profile',
        duration: 5000,
      });
      if (error.error && Array.isArray(error.error?.message)) {
        this.validationMessagesService.updateMessages(error.error.message);
      }
    }
  }

  async search(event: AutoCompleteCompleteEvent) {
    const result = await lastValueFrom(this.geoService.findCitiesStartingWith(event.query));
    this.suggestions = result.map(r => r.name).slice(0, 5);
  }

  uploadPropertyPicture() {
    this.uploadParams = { type: 'uploadPropertyPicture', imagePath: this.listing.imagePath, serialId: this.listing.serialId };
  }
  async uploadFinished(response: UploadReponse) {
    if (response.success) {
      this.listing = (await lastValueFrom(this.listingsService.getListingById(this.id, 'commercialProperty'))) as CommercialPropertyListing;
    }
  }

  async deleteConfirm(imageName: string) {
    const confirmed = await this.confirmationService.showConfirmation({ message: 'Are you sure you want to delete this image?' });
    if (confirmed) {
      this.listing.imageOrder = this.listing.imageOrder.filter(item => item !== imageName);
      await this.imageService.deleteListingImage(this.listing.imagePath, this.listing.serialId, imageName);
      await this.listingsService.save(this.listing, 'commercialProperty');
      this.listing = (await lastValueFrom(this.listingsService.getListingById(this.id, 'commercialProperty'))) as CommercialPropertyListing;
      this.messageService.addMessage({ severity: 'success', text: 'Image has been deleted', duration: 3000 });
      this.ts = new Date().getTime();
    } else {
      console.log('deny');
    }
  }

  changeListingCategory(value: 'business' | 'commercialProperty') {
    routeListingWithState(this.router, value, this.listing);
  }
  imageOrderChanged(imageOrder: string[]) {
    this.listing.imageOrder = imageOrder;
  }
}
