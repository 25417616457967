import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ListingCategoryGuard implements CanActivate {
  private apiBaseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const id = route.paramMap.get('id');
    const url = `${this.apiBaseUrl}/bizmatch/listings/undefined/${id}`;

    return this.http.get<any>(url).pipe(
      tap(response => {
        const category = response.listingsCategory;
        if (category === 'business') {
          this.router.navigate(['details-business-listing', id]);
        } else if (category === 'commercialProperty') {
          this.router.navigate(['details-commercial-property-listing', id]);
        } else {
          this.router.navigate(['not-found']);
        }
      }),
      catchError(() => {
        return of(this.router.createUrlTree(['/not-found']));
      }),
    );
  }
}
