import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private router: Router) {}

  handleError(error: any): void {
    // Prüfe, ob es sich um einen HttpErrorResponse handelt
    if (error instanceof HttpErrorResponse) {
      // Prüfe, ob es ein 401 Unauthorized Fehler ist
      if (error.status === 401) {
        // Führe den Login-Prozess über Keycloak aus
        this.router.navigate(['/login-register']);
      }
    }

    // Weiterhin normale Fehlerbehandlung
    console.error('Ein Fehler ist aufgetreten:', error);
  }
}
