import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { filter } from 'rxjs/operators';
import build from '../build';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ConfirmationService } from './components/confirmation/confirmation.service';
import { EMailComponent } from './components/email/email.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MessageContainerComponent } from './components/message/message-container.component';
import { SearchModalComponent } from './components/search-modal/search-modal.component';
import { AuditService } from './services/audit.service';
import { GeoService } from './services/geo.service';
import { LoadingService } from './services/loading.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent, MessageContainerComponent, SearchModalComponent, ConfirmationComponent, EMailComponent],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  build = build;
  title = 'bizmatch';
  actualRoute = '';

  public constructor(
    public loadingService: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private auditService: AuditService,
    private geoService: GeoService,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      let currentRoute = this.activatedRoute.root;
      while (currentRoute.children[0] !== undefined) {
        currentRoute = currentRoute.children[0];
      }
      // Hier haben Sie Zugriff auf den aktuellen Route-Pfad
      this.actualRoute = currentRoute.snapshot.url[0].path;
    });
  }
  ngOnInit() {
    // this.keycloakService.keycloakEvents$.subscribe({
    //   next: event => {
    //     if (event.type === KeycloakEventType.OnTokenExpired) {
    //       this.handleTokenExpiration();
    //     }
    //   },
    // });
  }
  // private async handleTokenExpiration(): Promise<void> {
  //   try {
  //     // Versuche, den Token zu erneuern
  //     const refreshed = await this.keycloakService.updateToken();
  //     if (!refreshed) {
  //       // Wenn der Token nicht erneuert werden kann, leite zur Login-Seite weiter
  //       this.keycloakService.login({
  //         redirectUri: window.location.href, // oder eine andere Seite
  //       });
  //     }
  //   } catch (error) {
  //     if (error.error === 'invalid_grant' && error.error_description === 'Token is not active') {
  //       // Hier wird der Fehler "invalid_grant" abgefangen
  //       this.keycloakService.login({
  //         redirectUri: window.location.href,
  //       });
  //     }
  //   }
  // }
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.ctrlKey && event.key === 'V') {
      this.showVersionDialog();
    }
  }

  showVersionDialog() {
    this.confirmationService.showConfirmation({ message: `App Version: ${this.build.timestamp}`, buttons: 'none' });
  }
}
