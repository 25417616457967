import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToString',
  standalone: true
})
export class ArrayToStringPipe implements PipeTransform {

  transform(value: string|string[], separator: string = '\n'): string {
    return Array.isArray(value)?value.join(separator):value;
  }

}
