import { CommonModule } from '@angular/common';
import { Component, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input/base-input.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { ValidationMessagesService } from '../validation-messages.service';

@Component({
  selector: 'app-validated-textarea',
  templateUrl: './validated-textarea.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, TooltipComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValidatedTextareaComponent),
      multi: true,
    },
  ],
})
export class ValidatedTextareaComponent extends BaseInputComponent {
  constructor(validationMessagesService: ValidationMessagesService) {
    super(validationMessagesService);
  }

  onInputChange(event: string): void {
    this.value = event?.length > 0 ? event : null;
    this.onChange(this.value);
  }
}
