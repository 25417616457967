import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ShareByEMail } from '../../../../../bizmatch-server/src/models/db.model';
import { MailService } from '../../services/mail.service';
import { ValidatedInputComponent } from '../validated-input/validated-input.component';
import { ValidationMessagesService } from '../validation-messages.service';
import { EMailService } from './email.service';

@UntilDestroy()
@Component({
  selector: 'app-email',
  standalone: true,
  imports: [CommonModule, FormsModule, ValidatedInputComponent],
  templateUrl: './email.component.html',
  template: ``,
})
export class EMailComponent {
  shareByEMail: ShareByEMail = {};
  constructor(public eMailService: EMailService, private mailService: MailService, private validationMessagesService: ValidationMessagesService) {}
  ngOnInit() {
    this.eMailService.shareByEMail$.pipe(untilDestroyed(this)).subscribe(val => {
      this.shareByEMail = val;
    });
  }
  async sendMail() {
    try {
      const result = await this.mailService.mailToFriend(this.shareByEMail);
      this.eMailService.accept(this.shareByEMail);
    } catch (error) {
      if (error.error && Array.isArray(error.error?.message)) {
        this.validationMessagesService.updateMessages(error.error.message);
      }
    }
  }
  ngOnDestroy() {
    this.validationMessagesService.clearMessages(); // Löschen Sie alle bestehenden Validierungsnachrichten
  }
}
