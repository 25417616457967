<div class="container mx-auto p-4">
  @if(user){
  <div class="bg-white drop-shadow-custom-bg-mobile md:drop-shadow-custom-bg rounded-lg overflow-hidden">
    <!-- Header -->
    <div class="flex items-center justify-between p-4 border-b relative">
      <div class="flex items-center space-x-4">
        <!-- <img src="https://placehold.co/80x80" alt="Profile picture of Avery Brown smiling" class="w-20 h-20 rounded-full" /> -->
        @if(user.hasProfile){
        <img src="{{ env.imageBaseUrl }}/pictures//profile/{{ emailToDirName(user.email) }}.avif?_ts={{ ts }}" class="w-20 h-20 rounded-full object-cover" />
        } @else {
        <img src="assets/images/person_placeholder.jpg" class="w-20 h-20 rounded-full" />
        }
        <div>
          <h1 class="text-2xl font-bold flex items-center">
            {{ user.firstname }} {{ user.lastname }}
            <span class="text-yellow-400 ml-2">&#9733;</span>
          </h1>
          <p class="text-gray-600">
            Company
            <span class="mx-1">-</span>
            {{ user.companyName }}
            <span class="mx-2">|</span>
            For Sale
            <span class="mx-1">-</span>
            <!-- <i class="fas fa-building text-red-500"></i> -->
            <span>{{ businessListings?.length + commercialPropListings?.length }}</span>
          </p>
        </div>
        @if(user.hasCompanyLogo){
        <img src="{{ env.imageBaseUrl }}/pictures/logo/{{ emailToDirName(user.email) }}.avif?_ts={{ ts }}" class="w-11 h-14" />
        }
        <!-- <img src="https://placehold.co/45x60" class="w-11 h-14" /> -->
      </div>
      <button
        (click)="historyService.goBack()"
        class="absolute top-4 right-4 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Description -->
    <p class="p-4 text-gray-700">{{ user.description }}</p>

    <!-- Company Profile -->
    <div class="p-4">
      <h2 class="text-xl font-semibold mb-4">Company Profile</h2>
      <p class="text-gray-700 mb-4" [innerHTML]="companyOverview"></p>

      <!-- Profile Details -->
      <div class="space-y-2">
        <div class="flex flex-col sm:flex-row sm:items-center bg-gray-100">
          <span class="font-semibold w-40 p-2">Name</span>
          <span class="p-2 flex-grow">{{ user.firstname }} {{ user.lastname }}</span>
        </div>
        <div class="flex flex-col sm:flex-row sm:items-center">
          <span class="font-semibold w-40 p-2">EMail Address</span>
          <span class="p-2 flex-grow">{{ user.email }}</span>
        </div>
        @if(user.customerType==='professional'){
        <div class="flex flex-col sm:flex-row sm:items-center bg-gray-100">
          <span class="font-semibold w-40 p-2">Phone Number</span>
          <span class="p-2 flex-grow">{{ formatPhoneNumber(user.phoneNumber) }}</span>
        </div>

        <div class="flex flex-col sm:flex-row sm:items-center">
          <span class="font-semibold w-40 p-2">Company Location</span>
          <span class="p-2 flex-grow">{{ user.location?.name }} - {{ user.location?.state }}</span>
        </div>
        <div class="flex flex-col sm:flex-row sm:items-center bg-gray-100">
          <span class="font-semibold w-40 p-2">Professional Type</span>
          <span class="p-2 flex-grow">{{ selectOptions.getCustomerSubType(user.customerSubType) }}</span>
        </div>
        }
      </div>
      @if(user.customerType==='professional'){
      <!-- Services -->
      <div class="mt-6">
        <h3 class="font-semibold mb-2">Services we offer</h3>
        <p class="text-gray-700 mb-4" [innerHTML]="offeredServices"></p>
      </div>

      <!-- Areas Served -->
      <div class="mt-6">
        <h3 class="font-semibold mb-2">Areas (Counties) we serve</h3>
        <div class="flex flex-wrap gap-2">
          @for (area of user.areasServed; track area) {
          <span class="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm">{{ area.county }}{{ area.county ? '-' : '' }}{{ area.state }}</span>
          }
        </div>
      </div>

      <!-- Licensed In -->
      <div class="mt-6">
        <h3 class="font-semibold mb-2">Licensed In</h3>
        @for (license of user.licensedIn; track license) {
        <span class="bg-green-100 text-green-800 px-2 py-1 rounded-full text-sm">{{ license.registerNo }}-{{ license.state }}</span>
        }
      </div>
      }
    </div>

    <!-- Business Listings -->

    <div class="p-4">
      @if(businessListings?.length>0){
      <h2 class="text-xl font-semibold mb-4">My Business Listings For Sale</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        @for (listing of businessListings; track listing) {
        <div class="border rounded-lg p-4 hover:cursor-pointer" [routerLink]="['/details-business-listing', listing.id]">
          <div class="flex items-center mb-2">
            <i [class]="selectOptions.getIconAndTextColorType(listing.type)" class="mr-2"></i>
            <span class="font-medium">{{ selectOptions.getBusiness(listing.type) }}</span>
          </div>
          <p class="text-gray-700">{{ listing.title }}</p>
        </div>
        }
      </div>
      }
      <!-- Commercial Property Listings -->
      @if(commercialPropListings?.length>0){
      <h2 class="text-xl font-semibold mb-4">My Commercial Property Listings For Sale</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        @for (listing of commercialPropListings; track listing) {
        <div class="border rounded-lg p-4 hover:cursor-pointer" [routerLink]="['/details-commercial-property-listing', listing.id]">
          <div class="flex items-center space-x-4">
            @if (listing.imageOrder?.length>0){
            <img src="{{ env.imageBaseUrl }}/pictures/property/{{ listing.imagePath }}/{{ listing.serialId }}/{{ listing.imageOrder[0] }}?_ts={{ ts }}" class="w-12 h-12 object-cover rounded" />
            } @else {
            <img src="assets/images/placeholder_properties.jpg" class="w-12 h-12 object-cover rounded" />
            }
            <div>
              <p class="font-medium">{{ selectOptions.getCommercialProperty(listing.type) }}</p>
              <p class="text-gray-700">{{ listing.title }}</p>
            </div>
          </div>
        </div>
        }
      </div>
      } @if( user?.email===keycloakUser?.email || (authService.isAdmin() | async)){
      <button class="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600" [routerLink]="['/account', user.id]">Edit</button>
      }
    </div>
  </div>
  }
</div>
