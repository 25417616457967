import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageComponent } from './message.component';
import { Message, MessageService } from './message.service';

@Component({
  selector: 'app-message-container',
  standalone: true,
  imports: [CommonModule, MessageComponent],
  template: `
    <div class="fixed top-5 right-5 z-50 flex flex-col items-end">
      <app-message *ngFor="let message of messages" [message]="message" (close)="removeMessage(message)"> </app-message>
    </div>
  `,
})
export class MessageContainerComponent implements OnInit {
  messages: Message[] = [];

  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    this.messageService.messages$.subscribe(messages => {
      this.messages = messages;
    });
  }

  removeMessage(message: Message): void {
    this.messageService.removeMessage(message);
  }
}
