import isDiffCertain from '../diff/is-diff-certain.js';
import isDiffArrays from '../diff/is-diff-arrays.js';
import { IMMUTABLE_OBJECT_METHODS } from './object.js';
const IMMUTABLE_ARRAY_METHODS = new Set(['concat', 'includes', 'indexOf', 'join', 'keys', 'lastIndexOf']);
export const MUTABLE_ARRAY_METHODS = {
  push: isDiffCertain,
  pop: isDiffCertain,
  shift: isDiffCertain,
  unshift: isDiffCertain,
  copyWithin: isDiffArrays,
  reverse: isDiffArrays,
  sort: isDiffArrays,
  splice: isDiffArrays,
  flat: isDiffArrays,
  fill: isDiffArrays
};
export const HANDLED_ARRAY_METHODS = new Set([...IMMUTABLE_OBJECT_METHODS, ...IMMUTABLE_ARRAY_METHODS, ...Object.keys(MUTABLE_ARRAY_METHODS)]);