<div #_container class="container">
  <div *ngFor="let item of items" cdkDrag (cdkDragEnded)="dragEnded($event)" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMoved($event)" [class.animation]="isAnimationActive" class="grid-item item">
    <div class="image-box hover:cursor-pointer">
      <img [src]="getImageUrl(item)" class="w-full h-full object-cover rounded-lg drop-shadow-custom-bg" />
      <div class="absolute top-2 right-2 bg-white rounded-full p-1 drop-shadow-custom-bg" (click)="imageToDelete.emit(item)">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 text-gray-600">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
  </div>
</div>
