<label [for]="name" class="block text-sm font-bold text-gray-700 mb-1 relative w-fit">
  {{ label }}
  @if(validationMessage){
  <div
    attr.data-tooltip-target="tooltip-{{ name }}"
    class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 dark:border-gray-900 hover:cursor-pointer"
    (click)="toggleTooltip($event)"
    (touchstart)="toggleTooltip($event)"
  >
    !
  </div>
  <app-tooltip id="tooltip-{{ name }}" [text]="validationMessage" [isVisible]="isTooltipVisible"></app-tooltip>
  }
</label>
<quill-editor [(ngModel)]="value" (ngModelChange)="onInputChange($event)" (onBlur)="onTouched()" [id]="name" [attr.name]="name" [modules]="quillModules"></quill-editor>
