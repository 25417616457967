import { CommonModule } from '@angular/common';
import { Component, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { BaseInputComponent } from '../base-input/base-input.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { ValidationMessagesService } from '../validation-messages.service';

@Component({
  selector: 'app-validated-quill',
  templateUrl: './validated-quill.component.html',
  styles: `quill-editor {
            width: 100%;
          }`,
  standalone: true,
  imports: [CommonModule, FormsModule, QuillModule, TooltipComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValidatedQuillComponent),
      multi: true,
    },
  ],
})
export class ValidatedQuillComponent extends BaseInputComponent {
  quillModules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ header: [1, 2, 3, 4, 5, 6, false] }], [{ color: [] }, { background: [] }], ['clean']],
  };
  constructor(validationMessagesService: ValidationMessagesService) {
    super(validationMessagesService);
  }

  onInputChange(event: Event): void {
    this.value = event;
    this.onChange(this.value);
  }
}
