import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BusinessListingCriteria, CommercialPropertyListingCriteria, UserListingCriteria } from '../../../../bizmatch-server/src/models/main.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  private apiBaseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}

  async generateAiReponse(prompt: string): Promise<BusinessListingCriteria | CommercialPropertyListingCriteria | UserListingCriteria> {
    let headers = new HttpHeaders();
    headers = headers.set('X-Hide-Loading', 'true');
    return lastValueFrom(this.http.post<BusinessListingCriteria | CommercialPropertyListingCriteria | UserListingCriteria>(`${this.apiBaseUrl}/bizmatch/ai`, { query: prompt }, { headers }));
  }
}
