<div>
  <label [for]="name" class="block text-sm font-bold text-gray-700 mb-1 relative w-fit">
    {{ label }}
    @if(validationMessage){
    <div
      attr.data-tooltip-target="tooltip-{{ name }}"
      class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 dark:border-gray-900 hover:cursor-pointer"
      (click)="toggleTooltip($event)"
      (touchstart)="toggleTooltip($event)"
    >
      !
    </div>
    <app-tooltip id="tooltip-{{ name }}" [text]="validationMessage" [isVisible]="isTooltipVisible"></app-tooltip>
    }
  </label>
  <select
    [disabled]="disabled"
    [id]="name"
    [name]="name"
    [ngModel]="value"
    (change)="onSelectChange($event)"
    (blur)="onTouched()"
    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
  >
    <option value="" disabled selected>Select an option</option>
    <option *ngFor="let option of options" [value]="option.value">
      {{ option.label }}
    </option>
  </select>
</div>
