import { Component } from '@angular/core';
import { BusinessListing, CommercialPropertyListing } from '../../../../../../bizmatch-server/src/models/db.model';
import { KeycloakUser } from '../../../../../../bizmatch-server/src/models/main.model';
import { ConfirmationComponent } from '../../../components/confirmation/confirmation.component';
import { ConfirmationService } from '../../../components/confirmation/confirmation.service';
import { AuthService } from '../../../services/auth.service';
import { ListingsService } from '../../../services/listings.service';
import { SelectOptionsService } from '../../../services/select-options.service';
import { SharedModule } from '../../../shared/shared/shared.module';
import { map2User } from '../../../utils/utils';

@Component({
  selector: 'app-favorites',
  standalone: true,
  imports: [SharedModule, ConfirmationComponent],
  templateUrl: './favorites.component.html',
  styleUrl: './favorites.component.scss',
})
export class FavoritesComponent {
  user: KeycloakUser;
  // listings: Array<ListingType> = []; //= dataListings as unknown as Array<BusinessListing>;
  favorites: Array<BusinessListing | CommercialPropertyListing>;
  constructor(private listingsService: ListingsService, public selectOptions: SelectOptionsService, private confirmationService: ConfirmationService, private authService: AuthService) {}
  async ngOnInit() {
    const token = await this.authService.getToken();
    this.user = map2User(token);
    const result = await Promise.all([await this.listingsService.getFavoriteListings('business'), await this.listingsService.getFavoriteListings('commercialProperty')]);
    this.favorites = [...result[0], ...result[1]];
  }
  async confirmDelete(listing: BusinessListing | CommercialPropertyListing) {
    const confirmed = await this.confirmationService.showConfirmation({ message: `Are you sure you want to remove this listing from your Favorites?` });
    if (confirmed) {
      // this.messageService.showMessage('Listing has been deleted');
      this.deleteListing(listing);
    }
  }
  async deleteListing(listing: BusinessListing | CommercialPropertyListing) {
    if (listing.listingsCategory === 'business') {
      await this.listingsService.removeFavorite(listing.id, 'business');
    } else {
      await this.listingsService.removeFavorite(listing.id, 'commercialProperty');
    }
    const result = await Promise.all([await this.listingsService.getFavoriteListings('business'), await this.listingsService.getFavoriteListings('commercialProperty')]);
    this.favorites = [...result[0], ...result[1]];
  }
}
