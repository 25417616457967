<div class="container mx-auto p-4">
  <div class="bg-white rounded-lg drop-shadow-custom-bg-mobile md:drop-shadow-custom-bg p-6">
    <h1 class="text-2xl font-bold md:mb-4">My Favorites</h1>

    <!-- Desktop view -->
    <div class="hidden md:block">
      <table class="w-full bg-white drop-shadow-inner-faint rounded-lg overflow-hidden">
        <thead class="bg-gray-100">
          <tr>
            <th class="py-2 px-4 text-left">Title</th>
            <th class="py-2 px-4 text-left">Category</th>
            <th class="py-2 px-4 text-left">Located in</th>
            <th class="py-2 px-4 text-left">Price</th>
            <th class="py-2 px-4 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          @for(listing of favorites; track listing){
          <tr class="border-b">
            <td class="py-2 px-4">{{ listing.title }}</td>
            <td class="py-2 px-4">{{ listing.listingsCategory === 'commercialProperty' ? 'Commercial Property' : 'Business' }}</td>
            <td class="py-2 px-4">{{ listing.location.name ? listing.location.name : listing.location.county }}, {{ listing.location.state }}</td>
            <td class="py-2 px-4">${{ listing.price.toLocaleString() }}</td>
            <td class="py-2 px-4 flex">
              @if(listing.listingsCategory==='business'){
              <button class="bg-green-500 text-white w-10 h-10 flex items-center justify-center rounded-full mr-2" [routerLink]="['/details-business-listing', listing.id]">
                <i class="fa-regular fa-eye"></i>
              </button>

              } @if(listing.listingsCategory==='commercialProperty'){
              <button class="bg-green-500 text-white w-10 h-10 flex items-center justify-center rounded-full mr-2" [routerLink]="['/details-commercial-property-listing', listing.id]">
                <i class="fa-regular fa-eye"></i>
              </button>
              }
              <button class="bg-red-500 text-white w-10 h-10 flex items-center justify-center rounded-full mr-2" (click)="confirmDelete(listing)">
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>

    <!-- Mobile view -->
    <div class="md:hidden">
      <div *ngFor="let listing of favorites" class="bg-white drop-shadow-inner-faint rounded-lg p-4 mb-4">
        <h2 class="text-xl font-semibold mb-2">{{ listing.title }}</h2>
        <p class="text-gray-600 mb-2">Category: {{ listing.listingsCategory === 'commercialProperty' ? 'Commercial Property' : 'Business' }}</p>
        <p class="text-gray-600 mb-2">Located in: {{ listing.location.name ? listing.location.name : listing.location.county }}, {{ listing.location.state }}</p>
        <p class="text-gray-600 mb-2">Price: ${{ listing.price.toLocaleString() }}</p>
        <div class="flex justify-start">
          @if(listing.listingsCategory==='business'){
          <button class="bg-green-500 text-white w-10 h-10 flex items-center justify-center rounded-full mr-2" [routerLink]="['/details-business-listing', listing.id]">
            <i class="fa-regular fa-eye"></i>
          </button>

          } @if(listing.listingsCategory==='commercialProperty'){
          <button class="bg-green-500 text-white w-10 h-10 flex items-center justify-center rounded-full mr-2" [routerLink]="['/details-commercial-property-listing', listing.id]">
            <i class="fa-regular fa-eye"></i>
          </button>
          }
          <button class="bg-red-500 text-white w-10 h-10 flex items-center justify-center rounded-full mr-2" (click)="confirmDelete(listing)">
            <i class="fa-solid fa-trash"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- <div class="flex items-center justify-between mt-4">
      <p class="text-sm text-gray-600">Showing 1 to 2 of 2 entries</p>
      <div class="flex items-center">
        <button class="px-2 py-1 border rounded-l-md bg-gray-100">&lt;&lt;</button>
        <button class="px-2 py-1 border-t border-b bg-gray-100">&lt;</button>
        <button class="px-2 py-1 border bg-blue-500 text-white">1</button>
        <button class="px-2 py-1 border-t border-b bg-gray-100">&gt;</button>
        <button class="px-2 py-1 border rounded-r-md bg-gray-100">&gt;&gt;</button>
        <select class="ml-2 border rounded-md px-2 py-1">
          <option>10</option>
        </select>
      </div>
    </div> -->
  </div>
</div>
<app-confirmation></app-confirmation>
