import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { CityAndStateResult, CountyResult, GeoResult, IpInfo } from '../../../../bizmatch-server/src/models/main.model';
import { Place } from '../../../../bizmatch-server/src/models/server.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  private apiBaseUrl = environment.apiBaseUrl;
  private baseUrl: string = 'https://nominatim.openstreetmap.org/search';
  private fetchingData: Observable<IpInfo> | null = null;
  private readonly storageKey = 'ipInfo';
  constructor(private http: HttpClient) {}

  findCitiesStartingWith(prefix: string, state?: string): Observable<GeoResult[]> {
    const stateString = state ? `/${state}` : '';
    return this.http.get<GeoResult[]>(`${this.apiBaseUrl}/bizmatch/geo/${prefix}${stateString}`);
  }
  findCitiesAndStatesStartingWith(prefix: string): Observable<CityAndStateResult[]> {
    return this.http.get<CityAndStateResult[]>(`${this.apiBaseUrl}/bizmatch/geo/citiesandstates/${prefix}`);
  }
  findCountiesStartingWith(prefix: string, states?: string[]): Observable<CountyResult[]> {
    return this.http.post<CountyResult[]>(`${this.apiBaseUrl}/bizmatch/geo/counties`, { prefix, states });
  }
  findLocationStartingWith(prefix: string): Observable<Place[]> {
    let headers = new HttpHeaders().set('X-Hide-Loading', 'true').set('Accept-Language', 'en-US');
    return this.http.get(`${this.baseUrl}?q=${prefix},US&format=json&addressdetails=1&limit=5`, { headers }) as Observable<Place[]>;
  }
  private fetchIpAndGeoLocation(): Observable<IpInfo> {
    return this.http.get<IpInfo>(`${this.apiBaseUrl}/bizmatch/geo/ipinfo/georesult/wysiwyg`);
  }

  async getIpInfo(): Promise<IpInfo | null> {
    // Versuche zuerst, die Daten aus dem sessionStorage zu holen
    const storedData = sessionStorage.getItem(this.storageKey);
    if (storedData) {
      return JSON.parse(storedData);
    }

    try {
      // Wenn keine Daten im Storage, hole sie vom Server
      const data = await lastValueFrom(this.http.get<IpInfo>(`${this.apiBaseUrl}/bizmatch/geo/ipinfo/georesult/wysiwyg`));

      // Speichere die Daten im sessionStorage
      sessionStorage.setItem(this.storageKey, JSON.stringify(data));

      return data;
    } catch (error) {
      console.error('Error fetching IP info:', error);
      return null;
    }
  }
}
