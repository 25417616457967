import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
export interface Confirmation {
  message: string;
  buttons?: 'both' | 'none';
  button_accept_label?: string;
  button_reject_label?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  private modalVisibleSubject = new BehaviorSubject<boolean>(false);
  private confirmationSubject = new BehaviorSubject<Confirmation>({ message: '' });
  private resolvePromise!: (value: boolean) => void;

  modalVisible$: Observable<boolean> = this.modalVisibleSubject.asObservable();
  confirmation$: Observable<Confirmation> = this.confirmationSubject.asObservable();

  showConfirmation(confirmation: Confirmation): Promise<boolean> {
    confirmation.buttons = confirmation.buttons ? confirmation.buttons : 'both';
    this.confirmationSubject.next(confirmation);
    this.modalVisibleSubject.next(true);
    return new Promise<boolean>(resolve => {
      this.resolvePromise = resolve;
    });
  }

  accept(): void {
    this.modalVisibleSubject.next(false);
    this.resolvePromise(true);
  }

  reject(): void {
    this.modalVisibleSubject.next(false);
    this.resolvePromise(false);
  }
}
