<div class="container mx-auto px-4 py-16">
  <h1 class="text-4xl font-bold text-center mb-12">Choose the Right Plan for Your Business</h1>

  <div
    [ngClass]="{
      'grid gap-8 mx-auto': true,
      'md:grid-cols-3 max-w-7xl': !user || !user.subscriptionPlan,
      'md:grid-cols-2 max-w-4xl': user && user.subscriptionPlan
    }"
  >
    @if(!user || !user.subscriptionPlan) {
    <!-- Free Plan -->
    <div class="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col h-full">
      <div class="px-6 py-8 bg-gray-50 text-center border-b">
        <h3 class="text-2xl font-semibold text-gray-700">Buyer & Seller</h3>
        <p class="mt-4 text-gray-600">Commercial Properties</p>
        <p class="mt-4 text-4xl font-bold text-gray-900">Free</p>
        <p class="mt-2 text-gray-600">Forever</p>
      </div>
      <div class="px-6 py-8 flex-grow">
        <ul class="text-sm text-gray-600">
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Create property listings
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Get early access to new listings
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Extended search functionality
          </li>
        </ul>
      </div>
      @if(!pricingOverview){
      <div class="px-6 py-4 mt-auto">
        <button (click)="register()" class="w-full bg-blue-500 text-white rounded-full px-4 py-2 font-semibold hover:bg-blue-600 transition duration-300">Sign Up Now</button>
      </div>
      }
    </div>
    }

    <!-- Professional Plan -->
    <div class="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col h-full">
      <div class="px-6 py-8 bg-blue-50 text-center border-b">
        <h3 class="text-2xl font-semibold text-gray-700">Professional</h3>
        <p class="mt-4 text-gray-600">CPA, Attorney, Title Company, etc.</p>
        <p class="mt-4 text-4xl font-bold text-gray-900">$29</p>
        <p class="mt-2 text-gray-600">per month</p>
      </div>
      <div class="px-6 py-8 flex-grow">
        <ul class="text-sm text-gray-600">
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Professionals Directory listing
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            3-Month Free Trial
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Detailed visitor statistics
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            In-portal contact forms
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            One-month refund guarantee
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Premium support
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Price stability
          </li>
        </ul>
      </div>
      @if(!pricingOverview){
      <div class="px-6 py-4 mt-auto">
        <button (click)="register('price_1PpSkpDjmFBOcNBs9UDPgBos')" class="w-full bg-blue-500 text-white rounded-full px-4 py-2 font-semibold hover:bg-blue-600 transition duration-300">Get Started</button>
      </div>
      }
    </div>

    <!-- Business Broker Plan -->
    <div class="bg-white rounded-lg shadow-lg overflow-hidden border-2 border-blue-500 flex flex-col h-full">
      <div class="px-6 py-8 bg-blue-500 text-center border-b">
        <h3 class="text-2xl font-semibold text-white">Business Broker</h3>
        <p class="mt-4 text-blue-100">Create & Manage Listings</p>
        <p class="mt-4 text-4xl font-bold text-white">$49</p>
        <p class="mt-2 text-blue-100">per month</p>
      </div>
      <div class="px-6 py-8 flex-grow">
        <ul class="text-sm text-gray-600">
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Create business listings
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Professionals Directory listing
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            3-Month Free Trial
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Detailed visitor statistics
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            In-portal contact forms
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            One-month refund guarantee
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Premium support
          </li>
          <li class="mb-4 flex items-center">
            <i class="fas fa-check text-green-500 mr-2"></i>
            Price stability
          </li>
        </ul>
      </div>
      @if(!pricingOverview){
      <div class="px-6 py-4 mt-auto">
        <button (click)="register('price_1PpSmRDjmFBOcNBsaaSp2nk9')" class="w-full bg-blue-500 text-white rounded-full px-4 py-2 font-semibold hover:bg-blue-600 transition duration-300">Start Listing Now</button>
      </div>
      }
    </div>
  </div>

  <div class="mt-16 text-center">
    <h2 class="text-2xl font-semibold mb-4">Not sure which plan is right for you?</h2>
    <p class="text-gray-600 mb-8">Contact our sales team for a personalized recommendation.</p>
    <a routerLink="/emailUs" class="bg-blue-500 text-white rounded-full px-6 py-3 font-semibold hover:bg-blue-600 transition duration-300">Contact Sales</a>
  </div>
</div>
