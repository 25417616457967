<div class="min-h-screen flex items-center justify-center">
  <div class="bg-white p-6 rounded-lg shadow-lg max-w-md">
    <div class="text-center">
      <!-- Neue Bedingung hinzufügen -->
      @if(maxAttemptsReached) {
      <h2 class="text-2xl font-bold text-red-800 mt-4">We're sorry!</h2>
      <p class="text-gray-600 mt-2">
        We regret to inform you that we have not yet received any response from our payment service provider regarding the status of your subscription. Please log in to the
        <a href="https://www.bizmatch.net" class="text-blue-500 hover:underline">website</a> and check your subscription status under the Account menu. If you have any questions, please contact us at
        <a href="mailto:support@bizmatch.net" class="text-blue-500 hover:underline">support&#64;bizmatch.net</a>.
      </p>
      } @else if(user && (user.subscriptionPlan==='professional' || user.subscriptionPlan==='broker')) {
      <svg class="mx-auto h-16 w-16 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
      </svg>
      <h2 class="text-2xl font-bold text-gray-800 mt-4">Subscription Successful!</h2>
      <p class="text-gray-600 mt-2">Thank you for subscribing to our service.</p>
      <p class="text-gray-600 mt-2">
        You have successfully subscribed to the @if(user.subscriptionPlan==='professional'){
        <span class="font-semibold text-gray-800">Professional (CPA, Attorney, Title Company) Plan</span>
        } @if(user.subscriptionPlan==='broker'){
        <span class="font-semibold text-gray-800">Business Broker Plan</span>. }
      </p>
      <p class="text-gray-600 mt-2">We are excited to have you on board!</p>

      <div class="mt-6">
        <a routerLink="/account" class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg">Go to your Account</a>
      </div>

      <div class="mt-4 text-sm text-gray-500">
        <p>If you have any questions, feel free to <a routerLink="/emailUs" class="text-blue-500 hover:underline">contact our support team</a>.</p>
      </div>
      } @else {
      <p class="text-gray-600 mt-2">We are processing your subscription - Please be patient</p>
      <div class="loader mt-8 mx-auto"></div>
      }
    </div>
  </div>
</div>
