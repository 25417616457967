import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FirebaseUserInfo, UserRole, UsersResponse } from '../../../../../../bizmatch-server/src/models/main.model';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  imports: [CommonModule, FormsModule],
  standalone: true,
})
export class UserListComponent implements OnInit {
  users: FirebaseUserInfo[] = [];
  loading = false;
  error: string | null = null;

  // Paginierung
  pageToken?: string;
  hasMoreUsers = false;
  maxResultsPerPage = 50;

  // Filterung
  selectedRole: UserRole | 'all' = 'all';

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(): void {
    this.loading = true;
    this.error = null;

    if (this.selectedRole !== 'all') {
      // Benutzer nach Rolle filtern
      this.userService.getUsersByRole(this.selectedRole).subscribe({
        next: response => {
          this.users = response.users;
          this.loading = false;
          this.hasMoreUsers = false; // Bei Rollenfilterung keine Paginierung
        },
        error: err => {
          this.error = 'Fehler beim Laden der Benutzer: ' + (err.message || err);
          this.loading = false;
        },
      });
    } else {
      // Alle Benutzer mit Paginierung laden
      this.userService.getAllUsers(this.maxResultsPerPage, this.pageToken).subscribe({
        next: (response: UsersResponse) => {
          this.users = this.pageToken
            ? [...this.users, ...response.users] // Anhängen bei Paginierung
            : response.users; // Ersetzen beim ersten Laden

          this.pageToken = response.pageToken;
          this.hasMoreUsers = !!response.pageToken;
          this.loading = false;
        },
        error: err => {
          this.error = 'Fehler beim Laden der Benutzer: ' + (err.message || err);
          this.loading = false;
        },
      });
    }
  }

  loadMoreUsers(): void {
    if (this.hasMoreUsers && !this.loading) {
      this.loadUsers();
    }
  }

  onRoleFilterChange(role: UserRole | 'all'): void {
    this.selectedRole = role;
    this.users = []; // Liste zurücksetzen
    this.pageToken = undefined; // Paginierung zurücksetzen
    this.loadUsers();
  }

  changeUserRole(user: FirebaseUserInfo, newRole: UserRole): void {
    this.userService.setUserRole(user.uid, newRole).subscribe({
      next: () => {
        // Benutzer in der lokalen Liste aktualisieren
        const index = this.users.findIndex(u => u.uid === user.uid);
        if (index !== -1) {
          this.users[index] = { ...user, role: newRole };
        }
      },
      error: err => {
        this.error = `Fehler beim Ändern der Rolle für ${user.email}: ${err.message || err}`;
      },
    });
  }
}
