import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private profilePhotoSource = new Subject<string>();
  currentProfilePhoto = this.profilePhotoSource.asObservable();

  constructor() {}

  changeProfilePhoto(photoUrl: string) {
    this.profilePhotoSource.next(photoUrl);
  }
}
