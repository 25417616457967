import { Component } from '@angular/core';
import { Control, DomEvent, DomUtil, icon, Icon, latLng, Layer, Map, MapOptions, Marker, tileLayer } from 'leaflet';
import { BusinessListing, CommercialPropertyListing } from '../../../../../bizmatch-server/src/models/db.model';
@Component({
  selector: 'app-base-details',
  template: ``,
  standalone: true,
  imports: [],
})
export abstract class BaseDetailsComponent {
  // Leaflet-Map-Einstellungen
  mapOptions: MapOptions;
  mapLayers: Layer[] = [];
  mapCenter: any;
  mapZoom: number = 13; // Standardzoomlevel
  protected listing: BusinessListing | CommercialPropertyListing;
  constructor() {
    this.mapOptions = {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; OpenStreetMap contributors',
        }),
      ],
      zoom: this.mapZoom,
      center: latLng(0, 0), // Platzhalter, wird später gesetzt
    };
  }
  protected configureMap() {
    const latitude = this.listing.location.latitude;
    const longitude = this.listing.location.longitude;

    if (latitude && longitude) {
      this.mapCenter = latLng(latitude, longitude);
      this.mapLayers = [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; OpenStreetMap contributors',
        }),
        new Marker([latitude, longitude], {
          icon: icon({
            ...Icon.Default.prototype.options,
            iconUrl: 'assets/leaflet/marker-icon.png',
            iconRetinaUrl: 'assets/leaflet/marker-icon-2x.png',
            shadowUrl: 'assets/leaflet/marker-shadow.png',
          }),
        }),
      ];
      this.mapOptions = {
        ...this.mapOptions,
        center: this.mapCenter,
        zoom: this.mapZoom,
      };
    }
  }
  onMapReady(map: Map) {
    if (this.listing.location.street) {
      const addressControl = new Control({ position: 'topright' });

      addressControl.onAdd = () => {
        const container = DomUtil.create('div', 'address-control bg-white p-2 rounded shadow');
        const address = `${this.listing.location.housenumber ? this.listing.location.housenumber : ''} ${this.listing.location.street}, ${
          this.listing.location.name ? this.listing.location.name : this.listing.location.county
        }, ${this.listing.location.state}`;
        container.innerHTML = `
          ${address}<br/>
          <a href="#" id="view-full-map">View larger map</a>
        `;

        // Verhindere, dass die Karte durch das Klicken des Links bewegt wird
        DomEvent.disableClickPropagation(container);

        // Füge einen Event Listener für den Link hinzu
        const link = container.querySelector('#view-full-map') as HTMLElement;
        if (link) {
          DomEvent.on(link, 'click', (e: Event) => {
            e.preventDefault();
            this.openFullMap();
          });
        }

        return container;
      };

      addressControl.addTo(map);
    }
  }
  openFullMap() {
    const latitude = this.listing.location.latitude;
    const longitude = this.listing.location.longitude;
    const address = `${this.listing.location.housenumber} ${this.listing.location.street}, ${this.listing.location.name ? this.listing.location.name : this.listing.location.county}, ${this.listing.location.state}`;
    const url = `https://www.openstreetmap.org/?mlat=${latitude}&mlon=${longitude}#map=15/${latitude}/${longitude}`;

    window.open(url, '_blank');
  }
}
