import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EventTypeEnum, ListingEvent } from '../../../../bizmatch-server/src/models/db.model';
import { LogMessage } from '../../../../bizmatch-server/src/models/main.model';
import { environment } from '../../environments/environment';
import { GeoService } from './geo.service';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private apiBaseUrl = environment.apiBaseUrl;
  private apiKey = environment.ipinfo_token;

  constructor(private http: HttpClient, private geoService: GeoService) {}

  async log(message: LogMessage): Promise<void> {
    lastValueFrom(this.http.post(`${this.apiBaseUrl}/bizmatch/log`, message));
  }
  async createEvent(id: string, eventType: EventTypeEnum, userId: string, additionalData?): Promise<void> {
    const ipInfo = await this.geoService.getIpInfo();
    const [latitude, longitude] = ipInfo.loc ? ipInfo.loc.split(',') : [null, null]; //.map(Number);
    const listingEvent: ListingEvent = {
      listingId: id,
      eventType,
      eventTimestamp: new Date(),
      userAgent: navigator.userAgent,
      email: userId,
      userIp: ipInfo.ip,
      locationCountry: ipInfo.country,
      locationCity: ipInfo.city,
      locationLat: latitude,
      locationLng: longitude,
      additionalData,
    };
    let headers = new HttpHeaders().set('X-Hide-Loading', 'true');
    lastValueFrom(this.http.post(`${this.apiBaseUrl}/bizmatch/event`, listingEvent, { headers }));
  }
}
