export const hostname = window.location.hostname;
export const environment_base = {
  // apiBaseUrl: 'http://localhost:3000',
  apiBaseUrl: `http://${hostname}:4200`,
  imageBaseUrl: 'https://dev.bizmatch.net',
  buildVersion: '<BUILD_VERSION>',
  mailinfoUrl: 'https://dev.bizmatch.net',
  keycloak: {
    url: 'https://auth.bizmatch.net',
    realm: 'bizmatch-dev',
    clientId: 'bizmatch-dev',
    redirectUri: 'https://dev.bizmatch.net',
  },
  ipinfo_token: '7029590fb91214',
  firebaseConfig: {
    apiKey: 'AIzaSyBqVutQqdgUzwD9tKiKJrJq2Q6rD1hNdzw',
    //authDomain: 'bizmatch-net.firebaseapp.com',
    authDomain: 'auth.bizmatch.net',
    projectId: 'bizmatch-net',
    storageBucket: 'bizmatch-net.firebasestorage.app',
    messagingSenderId: '1065122571067',
    appId: '1:1065122571067:web:1124571ab67bc0f5240d1e',
    measurementId: 'G-MHVDK1KSWV',
  },
};
