import { ChangeDetectorRef, Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { ShareButton } from 'ngx-sharebuttons/button';
import { lastValueFrom } from 'rxjs';
import { BusinessListing, EventTypeEnum, ShareByEMail, User } from '../../../../../../bizmatch-server/src/models/db.model';
import { KeycloakUser, MailInfo } from '../../../../../../bizmatch-server/src/models/main.model';
import { environment } from '../../../../environments/environment';
import { EMailService } from '../../../components/email/email.service';
import { MessageService } from '../../../components/message/message.service';
import { ValidatedInputComponent } from '../../../components/validated-input/validated-input.component';
import { ValidatedNgSelectComponent } from '../../../components/validated-ng-select/validated-ng-select.component';
import { ValidatedTextareaComponent } from '../../../components/validated-textarea/validated-textarea.component';
import { ValidationMessagesService } from '../../../components/validation-messages.service';
import { AuditService } from '../../../services/audit.service';
import { GeoService } from '../../../services/geo.service';
import { HistoryService } from '../../../services/history.service';
import { ListingsService } from '../../../services/listings.service';
import { MailService } from '../../../services/mail.service';
import { SelectOptionsService } from '../../../services/select-options.service';
import { UserService } from '../../../services/user.service';
import { SharedModule } from '../../../shared/shared/shared.module';
import { createMailInfo, map2User } from '../../../utils/utils';
// Import für Leaflet
// Benannte Importe für Leaflet
import dayjs from 'dayjs';
import { AuthService } from '../../../services/auth.service';
import { BaseDetailsComponent } from '../base-details.component';
@Component({
  selector: 'app-details-business-listing',
  standalone: true,
  imports: [SharedModule, ValidatedInputComponent, ValidatedTextareaComponent, ShareButton, ValidatedNgSelectComponent, LeafletModule],
  providers: [],
  templateUrl: './details-business-listing.component.html',
  styleUrl: '../details.scss',
})
export class DetailsBusinessListingComponent extends BaseDetailsComponent {
  // listings: Array<BusinessListing>;
  responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1,
    },
  ];
  private id: string | undefined = this.activatedRoute.snapshot.params['id'] as string | undefined;
  override listing: BusinessListing;
  mailinfo: MailInfo;
  environment = environment;
  keycloakUser: KeycloakUser;
  user: User;
  listingUser: User;
  description: SafeHtml;
  private history: string[] = [];
  ts = new Date().getTime();
  env = environment;

  constructor(
    private activatedRoute: ActivatedRoute,
    private listingsService: ListingsService,
    private router: Router,
    private userService: UserService,
    public selectOptions: SelectOptionsService,
    private mailService: MailService,
    private sanitizer: DomSanitizer,
    public historyService: HistoryService,
    private validationMessagesService: ValidationMessagesService,
    private messageService: MessageService,
    private auditService: AuditService,
    public emailService: EMailService,
    private geoService: GeoService,
    public authService: AuthService,
    private cdref: ChangeDetectorRef,
  ) {
    super();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
    this.mailinfo = { sender: {}, email: '', url: environment.mailinfoUrl };
    // Initialisiere die Map-Optionen
  }

  async ngOnInit() {
    const token = await this.authService.getToken();
    this.keycloakUser = map2User(token);
    if (this.keycloakUser) {
      this.user = await this.userService.getByMail(this.keycloakUser.email);
      this.mailinfo = createMailInfo(this.user);
    }
    try {
      this.listing = await lastValueFrom(this.listingsService.getListingById(this.id, 'business'));
      this.auditService.createEvent(this.listing.id, 'view', this.user?.email);
      this.listingUser = await this.userService.getByMail(this.listing.email);
      this.description = this.sanitizer.bypassSecurityTrustHtml(this.listing.description);
      if (this.listing.location.street) {
        this.configureMap();
      }
    } catch (error) {
      this.auditService.log({ severity: 'error', text: error.error.message });
      this.router.navigate(['notfound']);
    }
  }

  ngOnDestroy() {
    this.validationMessagesService.clearMessages(); // Löschen Sie alle bestehenden Validierungsnachrichten
  }

  async mail() {
    try {
      this.mailinfo.email = this.listingUser.email;
      this.mailinfo.listing = this.listing;
      await this.mailService.mail(this.mailinfo);
      this.validationMessagesService.clearMessages();
      this.auditService.createEvent(this.listing.id, 'contact', this.user?.email, this.mailinfo.sender);
      this.messageService.addMessage({ severity: 'success', text: 'Your message has been sent to the creator of the listing', duration: 3000 });
      this.mailinfo = createMailInfo(this.user);
    } catch (error) {
      this.messageService.addMessage({
        severity: 'danger',
        text: 'An error occurred while sending the request - Please check your inputs',
        duration: 5000,
      });
      if (error.error && Array.isArray(error.error?.message)) {
        this.validationMessagesService.updateMessages(error.error.message);
      }
    }
  }
  get listingDetails() {
    let typeOfRealEstate = '';
    if (this.listing.realEstateIncluded) {
      typeOfRealEstate = 'Real Estate Included';
    } else if (this.listing.leasedLocation) {
      typeOfRealEstate = 'Leased Location';
    } else if (this.listing.franchiseResale) {
      typeOfRealEstate = 'Franchise Re-Sale';
    }
    const result = [
      { label: 'Category', value: this.selectOptions.getBusiness(this.listing.type) },
      { label: 'Located in', value: `${this.listing.location.name ? this.listing.location.name : this.listing.location.county}, ${this.selectOptions.getState(this.listing.location.state)}` },
      { label: 'Asking Price', value: `${this.listing.price ? `$${this.listing.price.toLocaleString()}` : ''}` },
      { label: 'Sales revenue', value: `${this.listing.salesRevenue ? `$${this.listing.salesRevenue.toLocaleString()}` : ''}` },
      { label: 'Cash flow', value: `${this.listing.cashFlow ? `$${this.listing.cashFlow.toLocaleString()}` : ''}` },
      { label: 'Type of Real Estate', value: typeOfRealEstate },
      { label: 'Employees', value: this.listing.employees },
      { label: 'Established since', value: this.listing.established },
      { label: 'Support & Training', value: this.listing.supportAndTraining },
      { label: 'Reason for Sale', value: this.listing.reasonForSale },
      { label: 'Broker licensing', value: this.listing.brokerLicencing },
      { label: 'Listed since', value: `${this.dateInserted()} - ${this.getDaysListed()} days` },
      {
        label: 'Listing by',
        value: null, // Wird nicht verwendet
        isHtml: true,
        isListingBy: true, // Flag für den speziellen Fall
        user: this.listingUser, // Übergebe das User-Objekt
        imagePath: this.listing.imageName,
        imageBaseUrl: this.env.imageBaseUrl,
        ts: this.ts,
      },
    ];
    if (this.listing.draft) {
      result.push({ label: 'Draft', value: this.listing.draft ? 'Yes' : 'No' });
    }
    return result;
  }
  save() {
    this.listing.favoritesForUser.push(this.user.email);
    this.listingsService.save(this.listing, 'business');
    this.auditService.createEvent(this.listing.id, 'favorite', this.user?.email);
  }
  isAlreadyFavorite() {
    return this.listing.favoritesForUser.includes(this.user.email);
  }
  async showShareByEMail() {
    const result = await this.emailService.showShareByEMail({
      yourEmail: this.user ? this.user.email : null,
      yourName: this.user ? `${this.user.firstname} ${this.user.lastname}` : null,
      url: environment.mailinfoUrl,
      listingTitle: this.listing.title,
      id: this.listing.id,
      type: 'business',
    });
    if (result) {
      this.auditService.createEvent(this.listing.id, 'email', this.user?.email, <ShareByEMail>result);
      this.messageService.addMessage({
        severity: 'success',
        text: 'Your Email has beend sent',
        duration: 5000,
      });
    }
  }

  createEvent(eventType: EventTypeEnum) {
    this.auditService.createEvent(this.listing.id, eventType, this.user?.email);
  }
  getDaysListed() {
    return dayjs().diff(this.listing.created, 'day');
  }
  dateInserted() {
    return dayjs(this.listing.created).format('DD/MM/YYYY');
  }
}
