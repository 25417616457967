import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxCurrencyDirective } from 'ngx-currency';
import { BaseInputComponent } from '../base-input/base-input.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { ValidationMessagesService } from '../validation-messages.service';

@Component({
  selector: 'app-validated-price',
  standalone: true,
  imports: [CommonModule, FormsModule, TooltipComponent, NgxCurrencyDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValidatedPriceComponent),
      multi: true,
    },
  ],
  templateUrl: './validated-price.component.html',
  styles: `:host{width:100%}`,
})
export class ValidatedPriceComponent extends BaseInputComponent {
  @Input() inputClasses: string;
  @Input() placeholder: string = '';
  constructor(validationMessagesService: ValidationMessagesService) {
    super(validationMessagesService);
  }

  onInputChange(event: Event): void {
    this.value = !event ? null : event;
    this.onChange(this.value);
  }
}
