<div class="container mx-auto py-8 px-4 max-w-md">
  <div class="bg-white p-6 rounded-lg drop-shadow-custom-bg-mobile md:drop-shadow-custom-bg text-center">
    <!-- Loading state -->
    <ng-container *ngIf="verificationStatus === 'pending'">
      <div class="flex justify-center mb-4">
        <div class="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
      <p class="text-gray-700">Verifying your email address...</p>
    </ng-container>

    <!-- Success state -->
    <ng-container *ngIf="verificationStatus === 'success'">
      <div class="flex justify-center mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
      </div>
      <h2 class="text-2xl font-bold text-green-600 mb-5">Your email has been verified</h2>
      <p class="text-gray-700 mb-4">You will be redirected to your account page in 5 seconds</p>
      <a routerLink="/account" class="inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"> Go to Account Page Now </a>
    </ng-container>

    <!-- Error state -->
    <ng-container *ngIf="verificationStatus === 'error'">
      <div class="flex justify-center mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
      <h2 class="text-2xl font-bold text-red-600 mb-3">Verification Failed</h2>
      <p class="text-gray-700 mb-4">{{ errorMessage }}</p>
      <a routerLink="/login" class="inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"> Return to Login </a>
    </ng-container>
  </div>
</div>
