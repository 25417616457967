import { ChangeDetectorRef, Component } from '@angular/core';
import { CommercialPropertyListing, User } from '../../../../../../bizmatch-server/src/models/db.model';
import { ListingType } from '../../../../../../bizmatch-server/src/models/main.model';
import { ConfirmationComponent } from '../../../components/confirmation/confirmation.component';
import { ConfirmationService } from '../../../components/confirmation/confirmation.service';
import { MessageComponent } from '../../../components/message/message.component';
import { MessageService } from '../../../components/message/message.service';
import { AuthService } from '../../../services/auth.service';
import { ListingsService } from '../../../services/listings.service';
import { SelectOptionsService } from '../../../services/select-options.service';
import { UserService } from '../../../services/user.service';
import { SharedModule } from '../../../shared/shared/shared.module';
import { map2User } from '../../../utils/utils';

@Component({
  selector: 'app-my-listing',
  standalone: true,
  imports: [SharedModule, ConfirmationComponent, MessageComponent],
  providers: [],
  templateUrl: './my-listing.component.html',
  styleUrl: './my-listing.component.scss',
})
export class MyListingComponent {
  listings: Array<ListingType> = []; //dataListings as unknown as Array<BusinessListing>;
  myListings: Array<ListingType>;
  user: User;
  constructor(
    public userService: UserService,
    private listingsService: ListingsService,
    private cdRef: ChangeDetectorRef,
    public selectOptions: SelectOptionsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
  ) {}
  async ngOnInit() {
    const token = await this.authService.getToken();
    const keycloakUser = map2User(token);
    const email = keycloakUser.email;
    this.user = await this.userService.getByMail(email);
    const result = await Promise.all([await this.listingsService.getListingsByEmail(this.user.email, 'business'), await this.listingsService.getListingsByEmail(this.user.email, 'commercialProperty')]);
    this.myListings = [...result[0], ...result[1]];
  }

  async deleteListing(listing: ListingType) {
    if (listing.listingsCategory === 'business') {
      await this.listingsService.deleteBusinessListing(listing.id);
    } else {
      await this.listingsService.deleteCommercialPropertyListing(listing.id, (<CommercialPropertyListing>listing).imagePath);
    }
    const result = await Promise.all([await this.listingsService.getListingsByEmail(this.user.email, 'business'), await this.listingsService.getListingsByEmail(this.user.email, 'commercialProperty')]);
    this.myListings = [...result[0], ...result[1]];
  }

  async confirm(listing: ListingType) {
    const confirmed = await this.confirmationService.showConfirmation({ message: `Are you sure you want to delete this listing?` });
    if (confirmed) {
      // this.messageService.showMessage('Listing has been deleted');
      this.deleteListing(listing);
    }
  }
}
