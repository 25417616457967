import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ListingsService } from '../../../services/listings.service';
import { SelectOptionsService } from '../../../services/select-options.service';
import { map2User, routeListingWithState } from '../../../utils/utils';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { QuillModule } from 'ngx-quill';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCurrencyDirective } from 'ngx-currency';
import { BusinessListing, CommercialPropertyListing, User } from '../../../../../../bizmatch-server/src/models/db.model';
import { AutoCompleteCompleteEvent, ImageProperty, createDefaultBusinessListing, emailToDirName } from '../../../../../../bizmatch-server/src/models/main.model';

import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../components/message/message.service';
import { ValidatedCityComponent } from '../../../components/validated-city/validated-city.component';
import { ValidatedInputComponent } from '../../../components/validated-input/validated-input.component';
import { ValidatedLocationComponent } from '../../../components/validated-location/validated-location.component';
import { ValidatedNgSelectComponent } from '../../../components/validated-ng-select/validated-ng-select.component';
import { ValidatedPriceComponent } from '../../../components/validated-price/validated-price.component';
import { ValidatedQuillComponent } from '../../../components/validated-quill/validated-quill.component';
import { ValidatedTextareaComponent } from '../../../components/validated-textarea/validated-textarea.component';
import { ValidationMessagesService } from '../../../components/validation-messages.service';
import { ArrayToStringPipe } from '../../../pipes/array-to-string.pipe';
import { AuthService } from '../../../services/auth.service';
import { GeoService } from '../../../services/geo.service';
import { ImageService } from '../../../services/image.service';
import { LoadingService } from '../../../services/loading.service';
import { UserService } from '../../../services/user.service';
import { SharedModule } from '../../../shared/shared/shared.module';
import { TOOLBAR_OPTIONS } from '../../utils/defaults';
@Component({
  selector: 'business-listing',
  standalone: true,
  imports: [
    SharedModule,
    ArrayToStringPipe,
    DragDropModule,
    QuillModule,
    NgxCurrencyDirective,
    NgSelectModule,
    ValidatedInputComponent,
    ValidatedQuillComponent,
    ValidatedNgSelectComponent,
    ValidatedPriceComponent,
    ValidatedTextareaComponent,
    ValidatedCityComponent,
    ValidatedLocationComponent,
  ],
  providers: [],
  templateUrl: './edit-business-listing.component.html',
  styleUrl: './edit-business-listing.component.scss',
})
export class EditBusinessListingComponent {
  listingsCategory = 'business';
  category: string;
  location: string;
  mode: 'edit' | 'create';
  separator: '\n\n';
  listing: BusinessListing;
  private id: string | undefined = this.activatedRoute.snapshot.params['id'] as string | undefined;
  user: User;
  environment = environment;
  config = { aspectRatio: 16 / 9 };
  editorModules = TOOLBAR_OPTIONS;
  draggedImage: ImageProperty;
  faTrash = faTrash;
  data: CommercialPropertyListing;
  typesOfBusiness = [];
  quillModules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ header: [1, 2, 3, 4, 5, 6, false] }], [{ color: [] }, { background: [] }], ['clean']],
  };
  listingUser: User;
  constructor(
    public selectOptions: SelectOptionsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private listingsService: ListingsService,
    public userService: UserService,
    private geoService: GeoService,
    private imageService: ImageService,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private validationMessagesService: ValidationMessagesService,
    private authService: AuthService,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.mode = event.url.startsWith('/createBusinessListing') ? 'create' : 'edit';
      }
    });
    this.route.data.subscribe(async () => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.data = this.router.getCurrentNavigation().extras.state['data'];
      }
    });
    this.typesOfBusiness = selectOptions.typesOfBusiness.map(e => {
      return { name: e.name, value: e.value };
    });
  }
  async ngOnInit() {
    const token = await this.authService.getToken();
    const keycloakUser = map2User(token);
    this.listingUser = await this.userService.getByMail(keycloakUser.email);
    if (this.mode === 'edit') {
      this.listing = await lastValueFrom(this.listingsService.getListingById(this.id, 'business'));
    } else {
      this.listing = createDefaultBusinessListing();
      this.listing.email = this.listingUser.email;
      this.listing.imageName = emailToDirName(keycloakUser.email);
      if (this.data) {
        this.listing.title = this.data?.title;
        this.listing.description = this.data?.description;
      }
    }
  }
  ngOnDestroy() {
    this.validationMessagesService.clearMessages(); // Löschen Sie alle bestehenden Validierungsnachrichten
  }
  async save() {
    try {
      this.listing = await this.listingsService.save(this.listing, this.listing.listingsCategory);
      this.router.navigate(['editBusinessListing', this.listing.id]);
      this.messageService.addMessage({ severity: 'success', text: 'Listing changes have been persisted', duration: 3000 });
      this.validationMessagesService.clearMessages(); // Löschen Sie alle bestehenden Validierungsnachrichten
    } catch (error) {
      this.messageService.addMessage({
        severity: 'danger',
        text: 'An error occurred while saving the profile - Please check your inputs',
        duration: 5000,
      });
      if (error.error && Array.isArray(error.error?.message)) {
        this.validationMessagesService.updateMessages(error.error.message);
      }
    }
  }

  suggestions: string[] | undefined;

  async search(event: AutoCompleteCompleteEvent) {
    const result = await lastValueFrom(this.geoService.findCitiesStartingWith(event.query));
    this.suggestions = result.map(r => r.name).slice(0, 5);
  }

  changeListingCategory(value: 'business' | 'commercialProperty') {
    routeListingWithState(this.router, value, this.listing);
  }
  onNumericInputChange(value: string, modelProperty: string): void {
    const newValue = value === '' ? null : +value;
    this.setPropertyByPath(this, modelProperty, newValue);
  }

  private setPropertyByPath(obj: any, path: string, value: any): void {
    const keys = path.split('.');
    let target = obj;
    for (let i = 0; i < keys.length - 1; i++) {
      target = target[keys[i]];
    }
    target[keys[keys.length - 1]] = value;
  }
  onCheckboxChange(checkbox: string, value: boolean) {
    // Deaktivieren Sie alle Checkboxes
    this.listing.realEstateIncluded = false;
    this.listing.leasedLocation = false;
    this.listing.franchiseResale = false;

    // Aktivieren Sie nur die aktuell ausgewählte Checkbox
    this.listing[checkbox] = value;
  }
}
