import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-tooltip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tooltip.component.html',
})
export class TooltipComponent {
  @Input() id: string;
  @Input() text: string;
  @Input() isVisible: boolean = false;

  ngOnInit() {
    this.initializeTooltip();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isVisible']) {
      this.updateTooltipVisibility();
    }
  }

  private initializeTooltip() {
    setTimeout(() => {
      initFlowbite();
    }, 10);
  }

  private updateTooltipVisibility() {
    const tooltipElement = document.getElementById(this.id);
    if (tooltipElement) {
      if (this.isVisible) {
        tooltipElement.classList.remove('invisible', 'opacity-0');
        tooltipElement.classList.add('visible', 'opacity-100');
      } else {
        tooltipElement.classList.remove('visible', 'opacity-100');
        tooltipElement.classList.add('invisible', 'opacity-0');
      }
    }
  }
}
