<div>
  <label for="type" class="block text-sm font-bold text-gray-700 mb-1 relative w-fit {{ labelClasses }}"
    >{{ label }} @if(validationMessage){
    <div
      attr.data-tooltip-target="tooltip-{{ name }}"
      class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 dark:border-gray-900 hover:cursor-pointer"
      (click)="toggleTooltip($event)"
      (touchstart)="toggleTooltip($event)"
    >
      !
    </div>
    <app-tooltip id="tooltip-{{ name }}" [text]="validationMessage" [isVisible]="isTooltipVisible"></app-tooltip>
    }
  </label>
  <ng-select
    class="custom"
    [multiple]="false"
    [hideSelected]="true"
    [trackByFn]="trackByFn"
    [minTermLength]="2"
    [loading]="placeLoading"
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="placeInput$"
    ngModel="{{ formatGeoAddress(value) }}"
    (ngModelChange)="onInputChange($event)"
  >
    @for (place of places$ | async; track place.place_id) {
    <ng-option [value]="place">{{ formatPlaceAddress(place) }}</ng-option>
    }
  </ng-select>
</div>
