import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { ShareByEMail } from '../../../../bizmatch-server/src/models/db.model';
import { ErrorResponse, MailInfo } from '../../../../bizmatch-server/src/models/main.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  private apiBaseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}

  async mail(mailinfo: MailInfo): Promise<void | ErrorResponse> {
    return await lastValueFrom(this.http.post(`${this.apiBaseUrl}/bizmatch/mail`, mailinfo));
  }
  async mailToFriend(shareByEMail: ShareByEMail): Promise<void | ErrorResponse> {
    return await lastValueFrom(this.http.post(`${this.apiBaseUrl}/bizmatch/mail/send2Friend`, shareByEMail));
  }
  /**
   * Sendet eine E-Mail-Verifizierung an die angegebene E-Mail-Adresse
   * @param email Die E-Mail-Adresse des Benutzers
   * @param redirectConfig Konfiguration für die Weiterleitung nach Verifizierung
   * @returns Observable mit der API-Antwort
   */
  sendVerificationEmail(
    email: string,
    redirectConfig?: { 
      protocol?: string, 
      hostname?: string, 
      port?: number 
    }
  ): Observable<any> {
    // Extrahiere aktuelle URL-Informationen, wenn nicht explizit angegeben
    const currentUrl = new URL(window.location.href);
    
    const config = {
      protocol: redirectConfig?.protocol || currentUrl.protocol.replace(':', ''),
      hostname: redirectConfig?.hostname || currentUrl.hostname,
      port: redirectConfig?.port || (currentUrl.port ? parseInt(currentUrl.port) : undefined)
    };
    
    return this.http.post(`${this.apiBaseUrl}/bizmatch/mail/verify-email`, { 
      email, 
      redirectConfig: config 
    });
  }
}
