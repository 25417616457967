import { Routes } from '@angular/router';
import { LogoutComponent } from './components/logout/logout.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

import { EmailAuthorizedComponent } from './components/email-authorized/email-authorized.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { LoginRegisterComponent } from './components/login-register/login-register.component';
import { AuthGuard } from './guards/auth.guard';
import { ListingCategoryGuard } from './guards/listing-category.guard';
import { UserListComponent } from './pages/admin/user-list/user-list.component';
import { DetailsBusinessListingComponent } from './pages/details/details-business-listing/details-business-listing.component';
import { DetailsCommercialPropertyListingComponent } from './pages/details/details-commercial-property-listing/details-commercial-property-listing.component';
import { DetailsUserComponent } from './pages/details/details-user/details-user.component';
import { HomeComponent } from './pages/home/home.component';
import { BrokerListingsComponent } from './pages/listings/broker-listings/broker-listings.component';
import { BusinessListingsComponent } from './pages/listings/business-listings/business-listings.component';
import { CommercialPropertyListingsComponent } from './pages/listings/commercial-property-listings/commercial-property-listings.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { AccountComponent } from './pages/subscription/account/account.component';
import { EditBusinessListingComponent } from './pages/subscription/edit-business-listing/edit-business-listing.component';
import { EditCommercialPropertyListingComponent } from './pages/subscription/edit-commercial-property-listing/edit-commercial-property-listing.component';
import { EmailUsComponent } from './pages/subscription/email-us/email-us.component';
import { FavoritesComponent } from './pages/subscription/favorites/favorites.component';
import { MyListingComponent } from './pages/subscription/my-listing/my-listing.component';
import { SuccessComponent } from './pages/success/success.component';

export const routes: Routes = [
  {
    path: 'businessListings',
    component: BusinessListingsComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'commercialPropertyListings',
    component: CommercialPropertyListingsComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'brokerListings',
    component: BrokerListingsComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  // #########
  // Listings  Details
  {
    path: 'details-business-listing/:id',
    component: DetailsBusinessListingComponent,
  },
  {
    path: 'details-commercial-property-listing/:id',
    component: DetailsCommercialPropertyListingComponent,
  },
  {
    path: 'listing/:id',
    canActivate: [ListingCategoryGuard],
    component: NotFoundComponent, // Dummy-Komponente, wird nie angezeigt, da der Guard weiterleitet
  },
  // {
  //   path: 'login/:page',
  //   component: LoginComponent, // Dummy-Komponente, wird nie angezeigt, da der Guard weiterleitet
  // },
  {
    path: 'login/:page',
    component: LoginRegisterComponent, // Dummy-Komponente, wird nie angezeigt, da der Guard weiterleitet
  },
  {
    path: 'login',
    component: LoginRegisterComponent, // Dummy-Komponente, wird nie angezeigt, da der Guard weiterleitet
  },
  {
    path: 'notfound',
    component: NotFoundComponent,
  },
  // #########
  // User  Details
  {
    path: 'details-user/:id',
    component: DetailsUserComponent,
  },
  // #########
  // User  edit
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:id',
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  // #########
  // Create, Update Listings
  {
    path: 'editBusinessListing/:id',
    component: EditBusinessListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'createBusinessListing',
    component: EditBusinessListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'editCommercialPropertyListing/:id',
    component: EditCommercialPropertyListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'createCommercialPropertyListing',
    component: EditCommercialPropertyListingComponent,
    canActivate: [AuthGuard],
  },
  // #########
  // My Listings
  {
    path: 'myListings',
    component: MyListingComponent,
    canActivate: [AuthGuard],
  },
  // #########
  // My Favorites
  {
    path: 'myFavorites',
    component: FavoritesComponent,
    canActivate: [AuthGuard],
  },
  // #########
  // EMAil Us
  {
    path: 'emailUs',
    component: EmailUsComponent,
    // canActivate: [AuthGuard],
  },
  // #########
  // Logout
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuard],
  },
  // #########
  // Pricing
  {
    path: 'pricing',
    component: PricingComponent,
  },
  {
    path: 'emailVerification',
    component: EmailVerificationComponent,
  },
  {
    path: 'email-authorized',
    component: EmailAuthorizedComponent,
  },
  {
    path: 'pricingOverview',
    component: PricingComponent,
    data: {
      pricingOverview: true,
    },
  },
  {
    path: 'pricing/:id',
    component: PricingComponent,
  },
  {
    path: 'success',
    component: SuccessComponent,
  },
  {
    path: 'admin/users',
    component: UserListComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: 'home' },
];
