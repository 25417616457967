import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-email-authorized',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './email-authorized.component.html',
})
export class EmailAuthorizedComponent implements OnInit {
  verificationStatus: 'pending' | 'success' | 'error' = 'pending';
  errorMessage: string | null = null;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private authService: AuthService, private userService: UserService) {}

  ngOnInit(): void {
    const oobCode = this.route.snapshot.queryParamMap.get('oobCode');
    const email = this.route.snapshot.queryParamMap.get('email');
    const mode = this.route.snapshot.queryParamMap.get('mode');

    if (mode === 'verifyEmail' && oobCode && email) {
      this.verifyEmail(oobCode, email);
    } else {
      this.verificationStatus = 'error';
      this.errorMessage = 'Invalid verification link';
    }
  }

  private verifyEmail(oobCode: string, email: string): void {
    this.http.post<{ message: string; token: string }>(`${environment.apiBaseUrl}/bizmatch/auth/verify-email`, { oobCode, email }).subscribe({
      next: async response => {
        this.verificationStatus = 'success';

        try {
          // Use the custom token from the server to sign in with Firebase
          await this.authService.signInWithCustomToken(response.token);

          // Try to get user info
          try {
            const user = await this.userService.getByMail(email);
            console.log('User retrieved:', user);
          } catch (userError) {
            console.error('Error getting user:', userError);
            // Don't change verification status - it's still a success
          }

          // Redirect to dashboard after a short delay
          setTimeout(() => {
            this.router.navigate(['/account']);
          }, 5000);
        } catch (authError) {
          console.error('Error signing in with custom token:', authError);
          // Keep success status for verification, but add warning about login
          this.errorMessage = 'Email verified, but there was an issue signing you in. Please try logging in manually.';
        }
      },
      error: err => {
        this.verificationStatus = 'error';
        this.errorMessage = err.error?.message || 'Verification failed';
      },
    });
  }
}
