import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { emailToDirName } from '../../../../bizmatch-server/src/models/main.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private apiBaseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) {}

  async uploadImage(imageBlob: Blob, type: 'uploadPropertyPicture' | 'uploadCompanyLogo' | 'uploadProfile', imagePath: string, serialId?: number) {
    let uploadUrl = `${this.apiBaseUrl}/bizmatch/image/${type}/${imagePath}`;
    if (type === 'uploadPropertyPicture') {
      uploadUrl = `${this.apiBaseUrl}/bizmatch/image/${type}/${imagePath}/${serialId}`;
    }
    const formData = new FormData();
    formData.append('file', imageBlob, 'image.png');

    // return this.http.post(uploadUrl, formData, {
    //   observe: 'events',
    // });
    return await lastValueFrom(this.http.post(uploadUrl, formData));
  }

  async deleteListingImage(imagePath: string, serial: number, name?: string) {
    return await lastValueFrom(this.http.delete<[]>(`${this.apiBaseUrl}/bizmatch/image/propertyPicture/${imagePath}/${serial}/${name}`));
  }

  async deleteLogoImagesByMail(email: string) {
    const adjustedEmail = emailToDirName(email);
    await lastValueFrom(this.http.delete<[]>(`${this.apiBaseUrl}/bizmatch/image/logo/${adjustedEmail}`));
  }
  async deleteProfileImagesByMail(email: string) {
    const adjustedEmail = emailToDirName(email);
    await lastValueFrom(this.http.delete<[]>(`${this.apiBaseUrl}/bizmatch/image/profile/${adjustedEmail}`));
  }
}
