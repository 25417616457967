import { CommonModule } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input/base-input.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { ValidationMessagesService } from '../validation-messages.service';

@Component({
  selector: 'app-validated-select',
  templateUrl: './validated-select.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, TooltipComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValidatedSelectComponent),
      multi: true,
    },
  ],
})
export class ValidatedSelectComponent extends BaseInputComponent {
  @Input() options: Array<{ value: any; label: string }> = [];
  @Input() disabled = false;
  @Output() valueChange = new EventEmitter<any>();

  constructor(validationMessagesService: ValidationMessagesService) {
    super(validationMessagesService);
  }

  onSelectChange(event: Event): void {
    const value = (event.target as HTMLSelectElement).value;
    this.value = value;
    this.onChange(value);
    this.valueChange.emit(value);
  }
}
