import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CriteriaChangeService {
  private criteriaChangeSubject = new Subject<void>();

  criteriaChange$ = this.criteriaChangeSubject.asObservable();

  notifyCriteriaChange() {
    this.criteriaChangeSubject.next();
  }
}
