<div class="container mx-auto p-4">
  <div class="bg-white rounded-lg drop-shadow-custom-bg-mobile md:drop-shadow-custom-bg p-6">
    <h1 class="text-2xl font-semibold mb-6">Edit Listing</h1>
    @if(listing){
    <form #listingForm="ngForm" class="space-y-4">
      <div class="mb-4">
        <label for="listingsCategory" class="block text-sm font-bold text-gray-700 mb-1">Listing category</label>
        <ng-select
          [readonly]="mode === 'edit'"
          [items]="selectOptions?.listingCategories"
          bindLabel="name"
          bindValue="value"
          [(ngModel)]="listing.listingsCategory"
          (ngModelChange)="changeListingCategory($event)"
          name="listingsCategory"
        >
        </ng-select>
      </div>

      <!-- <div class="mb-4">
        <label for="title" class="block text-sm font-bold text-gray-700 mb-1">Title of Listing</label>
        <input type="text" id="title" [(ngModel)]="listing.title" name="title" class="w-full p-2 border border-gray-300 rounded-md" />
      </div> -->
      <div>
        <app-validated-input label="Title of Listing" name="title" [(ngModel)]="listing.title"></app-validated-input>
      </div>

      <!-- <div class="mb-4">
        <label for="description" class="block text-sm font-bold text-gray-700 mb-1">Description</label>
        <quill-editor [(ngModel)]="listing.description" name="description" [modules]="quillModules"></quill-editor>
      </div> -->
      <div>
        <app-validated-quill label="Description" name="description" [(ngModel)]="listing.description"></app-validated-quill>
      </div>

      <!-- <div class="mb-4">
        <label for="type" class="block text-sm font-bold text-gray-700 mb-1">Type of business</label>
        <ng-select [items]="typesOfBusiness" bindLabel="name" bindValue="value" [(ngModel)]="listing.type" name="type"> </ng-select>
      </div> -->
      <div>
        <app-validated-ng-select label="Type of business" name="type" [(ngModel)]="listing.type" [items]="typesOfBusiness"></app-validated-ng-select>
      </div>

      <!-- <div class="flex mb-4 space-x-4">
        <div class="w-1/2">
          <label for="state" class="block text-sm font-bold text-gray-700 mb-1">State</label>
          <ng-select [items]="selectOptions?.states" bindLabel="name" bindValue="value" [(ngModel)]="listing.state" name="state"> </ng-select>
        </div>
        <div class="w-1/2">
          <label for="city" class="block text-sm font-bold text-gray-700 mb-1">City</label>
          <input type="text" id="city" [(ngModel)]="listing.city" name="city" class="w-full p-2 border border-gray-300 rounded-md" />
        </div>
      </div> -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <!-- <app-validated-ng-select label="State" name="state" [(ngModel)]="listing.location.state" [items]="selectOptions?.states"></app-validated-ng-select>
        <app-validated-input label="City" name="city" [(ngModel)]="listing.location.city"></app-validated-input> -->
        <!-- <app-validated-city label="Location" name="location" [(ngModel)]="listing.location"></app-validated-city> -->
        <app-validated-location label="Location" name="location" [(ngModel)]="listing.location"></app-validated-location>
        <app-validated-price label="Price" name="price" [(ngModel)]="listing.price"></app-validated-price>
      </div>

      <!-- <div class="flex mb-4 space-x-4">
        <div class="w-1/2">
          <label for="price" class="block text-sm font-bold text-gray-700 mb-1">Price</label>
          <input
            type="text"
            id="price"
            [(ngModel)]="listing.price"
            name="price"
            class="w-full p-2 border border-gray-300 rounded-md"
            [options]="{ prefix: '$', thousands: ',', decimal: '.', precision: 0, align: 'left' }"
            currencyMask
          />
        </div>
        <div class="w-1/2">
          <label for="salesRevenue" class="block text-sm font-bold text-gray-700 mb-1">Sales Revenue</label>
          <input
            type="text"
            id="salesRevenue"
            [(ngModel)]="listing.salesRevenue"
            name="salesRevenue"
            class="w-full p-2 border border-gray-300 rounded-md"
            [options]="{ prefix: '$', thousands: ',', decimal: '.', precision: 0, align: 'left' }"
            currencyMask
          />
        </div>
      </div> -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <app-validated-price label="Sales Revenue" name="salesRevenue" [(ngModel)]="listing.salesRevenue"></app-validated-price>
        <app-validated-price label="Cash Flow" name="cashFlow" [(ngModel)]="listing.cashFlow"></app-validated-price>
      </div>

      <!-- <div class="mb-4">
        <label for="cashFlow" class="block text-sm font-bold text-gray-700 mb-1">Cash Flow</label>
        <input
          type="text"
          id="cashFlow"
          [(ngModel)]="listing.cashFlow"
          name="cashFlow"
          class="w-full p-2 border border-gray-300 rounded-md"
          [options]="{ prefix: '$', thousands: ',', decimal: '.', precision: 0, align: 'left' }"
          currencyMask
        />
      </div> -->
      <!-- <div>
        
      </div> -->

      <!-- <div class="flex mb-4 space-x-4">
        <div class="w-1/2">
          <label for="established" class="block text-sm font-bold text-gray-700 mb-1">Years Established Since</label>
          <input type="number" id="established" [(ngModel)]="listing.established" name="established" class="w-full p-2 border border-gray-300 rounded-md" />
        </div>
        <div class="w-1/2">
          <label for="employees" class="block text-sm font-bold text-gray-700 mb-1">Employees</label>
          <input type="number" id="employees" [(ngModel)]="listing.employees" name="employees" class="w-full p-2 border border-gray-300 rounded-md" />
        </div>
      </div> -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <app-validated-input label="Established In" name="established" [(ngModel)]="listing.established" mask="0000" kind="number"></app-validated-input>
        <app-validated-input label="Employees" name="employees" [(ngModel)]="listing.employees" mask="0000" kind="number"></app-validated-input>
      </div>

      <div class="flex mb-4 space-x-4">
        <div class="flex items-center">
          <input
            type="checkbox"
            id="realEstateIncluded"
            [(ngModel)]="listing.realEstateIncluded"
            (ngModelChange)="onCheckboxChange('realEstateIncluded', $event)"
            name="realEstateIncluded"
            class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 mr-2"
          />
          <label for="realEstateIncluded" class="text-sm font-bold text-gray-700">Real Estate Included</label>
        </div>
        <div class="flex items-center">
          <input
            type="checkbox"
            id="leasedLocation"
            [(ngModel)]="listing.leasedLocation"
            (ngModelChange)="onCheckboxChange('leasedLocation', $event)"
            name="leasedLocation"
            class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 mr-2"
          />
          <label for="leasedLocation" class="text-sm font-bold text-gray-700">Leased Location</label>
        </div>
        <div class="flex items-center">
          <input
            type="checkbox"
            id="franchiseResale"
            [(ngModel)]="listing.franchiseResale"
            (ngModelChange)="onCheckboxChange('franchiseResale', $event)"
            name="franchiseResale"
            class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 mr-2"
          />
          <label for="franchiseResale" class="text-sm font-bold text-gray-700">Franchise Re-Sale</label>
        </div>
      </div>

      <!-- <div class="mb-4">
        <label for="supportAndTraining" class="block text-sm font-bold text-gray-700 mb-1">Support & Training</label>
        <input type="text" id="supportAndTraining" [(ngModel)]="listing.supportAndTraining" name="supportAndTraining" class="w-full p-2 border border-gray-300 rounded-md" />
      </div> 
      <div class="mb-4">
        <label for="reasonForSale" class="block text-sm font-bold text-gray-700 mb-1">Reason for Sale</label>
        <input type="text" id="reasonForSale" [(ngModel)]="listing.reasonForSale" name="reasonForSale" class="w-full p-2 border border-gray-300 rounded-md" />
      </div>-->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <app-validated-input label="Support & Training" name="supportAndTraining" [(ngModel)]="listing.supportAndTraining"></app-validated-input>
        <app-validated-input label="Reason for Sale" name="reasonForSale" [(ngModel)]="listing.reasonForSale"></app-validated-input>
      </div>

      <!-- <div class="flex mb-4 space-x-4">
        <div class="w-1/2">
          <label for="brokerLicencing" class="block text-sm font-bold text-gray-700 mb-1">Broker Licensing</label>
          <input type="text" id="brokerLicencing" [(ngModel)]="listing.brokerLicencing" name="brokerLicencing" class="w-full p-2 border border-gray-300 rounded-md" />
        </div>
        <div class="w-1/2">
          <label for="internalListingNumber" class="block text-sm font-bold text-gray-700 mb-1">Internal Listing Number</label>
          <input type="number" id="internalListingNumber" [(ngModel)]="listing.internalListingNumber" name="internalListingNumber" class="w-full p-2 border border-gray-300 rounded-md" />
        </div>
      </div> -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <!-- <app-validated-input label="Broker Licensing" name="brokerLicencing" [(ngModel)]="listing.brokerLicencing"></app-validated-input> -->
          <label for="brokerLicencing" class="block text-sm font-bold text-gray-700 mb-1">Broker Licensing (please maintain your license in your account)</label>
          <!-- @if(listingUser){ -->
          <ng-select [(ngModel)]="listing.brokerLicencing" name="brokerLicencing">
            @for (licensedIn of listingUser?.licensedIn; track listingUser?.licensedIn) {
            <ng-option [value]="licensedIn.registerNo">{{ licensedIn.state }} {{ licensedIn.registerNo }}</ng-option>
            }
          </ng-select>
        </div>
        <!-- } -->
        <app-validated-input label="Internal Listing Number" name="internalListingNumber" [(ngModel)]="listing.internalListingNumber" kind="number" mask="00000000000000000000"></app-validated-input>
      </div>

      <!-- <div class="mb-4">
        <label for="internals" class="block text-sm font-bold text-gray-700 mb-1">Internal Notes (Will not be shown on the listing, for your records only.)</label>
        <textarea id="internals" [(ngModel)]="listing.internals" name="internals" class="w-full p-2 border border-gray-300 rounded-md" rows="3"></textarea>
      </div> -->
      <div>
        <app-validated-textarea label="Internal Notes (Will not be shown on the listing, for your records only.)" name="internals" [(ngModel)]="listing.internals"></app-validated-textarea>
      </div>

      <div class="flex items-center mb-4">
        <label class="flex items-center cursor-pointer">
          <div class="relative">
            <input type="checkbox" [(ngModel)]="listing.draft" name="draft" class="hidden" />
            <div class="toggle-bg block w-12 h-6 rounded-full bg-gray-600 transition"></div>
          </div>
          <div class="ml-3 text-gray-700 font-medium">Draft Mode (Will not be shown as public listing)</div>
        </label>
      </div>
      @if (mode==='create'){
      <button (click)="save()" class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Post Listing</button>
      } @else {
      <button (click)="save()" class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Update Listing</button>
      }
    </form>
    }
  </div>
</div>

<!-- <div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
  <div class="p-fluid flex flex-column lg:flex-row">
    <menu-account></menu-account>
    <p-toast></p-toast>
    <div *ngIf="listing" class="surface-card p-5 shadow-2 border-round flex-auto">
      <div class="text-900 font-semibold text-lg mt-3">{{ mode === 'create' ? 'New' : 'Edit' }} Listing</div>
      <p-divider></p-divider>
      <div class="flex gap-5 flex-column-reverse md:flex-row">
        <div class="flex-auto p-fluid">
          <div class="mb-4">
            <label for="listingCategory" class="block font-medium text-900 mb-2">Listing category</label>
            <p-dropdown
              id="listingCategory"
              [options]="selectOptions?.listingCategories"
              [ngModel]="listingsCategory"
              optionLabel="name"
              optionValue="value"
              (ngModelChange)="changeListingCategory($event)"
              placeholder="Listing category"
              [disabled]="mode === 'edit'"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
          <div class="mb-4">
            <label for="email" class="block font-medium text-900 mb-2">Title of Listing</label>
            <input id="email" type="text" pInputText [(ngModel)]="listing.title" />
          </div>
          <div>
            <div class="mb-4">
              <label for="description" class="block font-medium text-900 mb-2">Description</label>
              <p-editor [(ngModel)]="listing.description" [style]="{ height: '320px' }" [modules]="editorModules">
                <ng-template pTemplate="header"></ng-template>
              </p-editor>
            </div>
          </div>
          <div class="mb-4">
            <label for="type" class="block font-medium text-900 mb-2">Type of business</label>
            <p-dropdown
              id="type"
              [filter]="true"
              filterBy="name"
              [options]="typesOfBusiness"
              [(ngModel)]="listing.type"
              optionLabel="name"
              optionValue="value"
              [showClear]="true"
              placeholder="Type of business"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
          <div class="grid">
            <div class="mb-4 col-12 md:col-6">
              <label for="listingCategory" class="block font-medium text-900 mb-2">State</label>
              <p-dropdown
                id="listingCategory"
                [filter]="true"
                filterBy="name"
                [options]="selectOptions?.states"
                [(ngModel)]="listing.state"
                optionLabel="name"
                optionValue="value"
                [showClear]="true"
                placeholder="State"
                [style]="{ width: '100%' }"
              ></p-dropdown>
            </div>
            <div class="mb-4 col-12 md:col-6">
              <label for="listingCategory" class="block font-medium text-900 mb-2">City</label>
              <p-autoComplete [(ngModel)]="listing.city" [suggestions]="suggestions" (completeMethod)="search($event)"></p-autoComplete>
            </div>
          </div>
        </div>
      </div>
      <p-divider></p-divider>
      <div class="flex gap-5 flex-column-reverse md:flex-row">
        <div class="flex-auto p-fluid">
          <div class="grid">
            <div class="mb-4 col-12 md:col-6">
              <label for="price" class="block font-medium text-900 mb-2">Price</label>
              <p-inputNumber mode="currency" currency="USD" locale="en-US" inputId="price" [(ngModel)]="listing.price"></p-inputNumber>
            </div>
            <div class="mb-4 col-12 md:col-6">
              <label for="salesRevenue" class="block font-medium text-900 mb-2">Sales Revenue</label>
              <p-inputNumber mode="currency" currency="USD" inputId="salesRevenue" [(ngModel)]="listing.salesRevenue"></p-inputNumber>
            </div>
          </div>
          <div class="grid">
            <div class="mb-4 col-12 md:col-6">
              <label for="cashFlow" class="block font-medium text-900 mb-2">Cash Flow</label>
              <p-inputNumber mode="currency" currency="USD" inputId="cashFlow" [(ngModel)]="listing.cashFlow"></p-inputNumber>
            </div>
          </div>
          <div class="grid">
            <div class="mb-4 col-12 md:col-6">
              <label for="established" class="block font-medium text-900 mb-2">Years Established Since</label>
              <p-inputNumber mode="decimal" inputId="established" [(ngModel)]="listing.established" [useGrouping]="false"></p-inputNumber>
            </div>
            <div class="mb-4 col-12 md:col-6">
              <label for="employees" class="block font-medium text-900 mb-2">Employees</label>
              <p-inputNumber mode="decimal" inputId="employees" [(ngModel)]="listing.employees" [useGrouping]="false"></p-inputNumber>
            </div>
          </div>
          <div class="grid">
            <div class="mb-4 col-12 md:col-4">
              <p-checkbox [binary]="true" [(ngModel)]="listing.realEstateIncluded"></p-checkbox>
              <span class="ml-2 text-900">Real Estate Included</span>
            </div>
            <div class="mb-4 col-12 md:col-4">
              <p-checkbox [binary]="true" [(ngModel)]="listing.leasedLocation"></p-checkbox>
              <span class="ml-2 text-900">Leased Location</span>
            </div>
            <div class="mb-4 col-12 md:col-4">
              <p-checkbox [binary]="true" [(ngModel)]="listing.franchiseResale"></p-checkbox>
              <span class="ml-2 text-900">Franchise Re-Sale</span>
            </div>
          </div>
          <div class="mb-4">
            <label for="supportAndTraining" class="block font-medium text-900 mb-2">Support & Training</label>
            <input id="supportAndTraining" type="text" pInputText [(ngModel)]="listing.supportAndTraining" />
          </div>
          <div class="mb-4">
            <label for="reasonForSale" class="block font-medium text-900 mb-2">Reason for Sale</label>
            <textarea id="reasonForSale" type="text" pInputTextarea rows="5" [autoResize]="true" [(ngModel)]="listing.reasonForSale"></textarea>
          </div>
          <div class="grid">
            <div class="mb-4 col-12 md:col-6">
              <label for="brokerLicensing" class="block font-medium text-900 mb-2">Broker Licensing</label>
              <input id="brokerLicensing" type="text" pInputText [(ngModel)]="listing.brokerLicencing" />
            </div>
            <div class="mb-4 col-12 md:col-6">
              <label for="internalListingNumber" class="block font-medium text-900 mb-2">Internal Listing Number</label>
              <p-inputNumber mode="decimal" inputId="internalListingNumber" type="text" [(ngModel)]="listing.internalListingNumber" [useGrouping]="false"></p-inputNumber>
            </div>
          </div>
          <div class="mb-4">
            <label for="internalListing" class="block font-medium text-900 mb-2">Internal Notes (Will not be shown on the listing, for your records only.)</label>
            <input id="internalListing" type="text" pInputText [(ngModel)]="listing.internals" />
          </div>
          <div class="grid">
            <div class="mb-4 col-12 md:col-6">
              <p-inputSwitch inputId="draft" [(ngModel)]="listing.draft"></p-inputSwitch>
              <span class="ml-2 text-900 absolute translate-y-5">Draft Mode (Will not be shown as public listing)</span>
            </div>
          </div>
          <div>
            @if (mode==='create'){
            <button pButton pRipple label="Post Listing" class="w-auto" (click)="save()"></button>
            } @else {
            <button pButton pRipple label="Update Listing" class="w-auto" (click)="save()"></button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog> -->
