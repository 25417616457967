import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseInputComponent } from '../base-input/base-input.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { ValidationMessagesService } from '../validation-messages.service';

@Component({
  selector: 'app-validated-ng-select',
  standalone: true,
  imports: [CommonModule, FormsModule, NgSelectModule, TooltipComponent],
  templateUrl: './validated-ng-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValidatedNgSelectComponent),
      multi: true,
    },
  ],
})
export class ValidatedNgSelectComponent extends BaseInputComponent {
  @Input() items;
  constructor(validationMessagesService: ValidationMessagesService) {
    super(validationMessagesService);
  }
  onInputChange(event: Event): void {
    this.value = event;
    this.onChange(this.value);
  }
}
