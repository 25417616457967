import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BusinessListing, CommercialPropertyListing, User } from '../../../../../../bizmatch-server/src/models/db.model';
import { KeycloakUser, emailToDirName } from '../../../../../../bizmatch-server/src/models/main.model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth.service';
import { HistoryService } from '../../../services/history.service';
import { ImageService } from '../../../services/image.service';
import { ListingsService } from '../../../services/listings.service';
import { SelectOptionsService } from '../../../services/select-options.service';
import { UserService } from '../../../services/user.service';
import { SharedModule } from '../../../shared/shared/shared.module';
import { formatPhoneNumber, map2User } from '../../../utils/utils';

@Component({
  selector: 'app-details-user',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './details-user.component.html',
  styleUrl: '../details.scss',
})
export class DetailsUserComponent {
  private id: string | undefined = this.activatedRoute.snapshot.params['id'] as string | undefined;
  user: User;
  user$: Observable<KeycloakUser>;
  keycloakUser: KeycloakUser;
  environment = environment;
  businessListings: BusinessListing[];
  commercialPropListings: CommercialPropertyListing[];
  companyOverview: SafeHtml;
  offeredServices: SafeHtml;
  ts = new Date().getTime();
  env = environment;
  emailToDirName = emailToDirName;
  formatPhoneNumber = formatPhoneNumber;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private listingsService: ListingsService,

    public selectOptions: SelectOptionsService,
    private sanitizer: DomSanitizer,
    private imageService: ImageService,
    public historyService: HistoryService,
    public authService: AuthService,
  ) {}

  async ngOnInit() {
    this.user = await this.userService.getById(this.id);
    const results = await Promise.all([await this.listingsService.getListingsByEmail(this.user.email, 'business'), await this.listingsService.getListingsByEmail(this.user.email, 'commercialProperty')]);
    // Zuweisen der Ergebnisse zu den Member-Variablen der Klasse
    this.businessListings = results[0];
    this.commercialPropListings = results[1] as CommercialPropertyListing[];
    const token = await this.authService.getToken();
    this.keycloakUser = map2User(token);
    this.companyOverview = this.sanitizer.bypassSecurityTrustHtml(this.user.companyOverview ? this.user.companyOverview : '');
    this.offeredServices = this.sanitizer.bypassSecurityTrustHtml(this.user.offeredServices ? this.user.offeredServices : '');
  }
}
