<div class="container mx-auto p-4">
  <div class="bg-white rounded-lg drop-shadow-custom-bg-mobile md:drop-shadow-custom-bg p-6">
    <h1 class="text-2xl font-semibold mb-6">Edit Listing</h1>
    @if (listing){
    <form #listingForm="ngForm" class="space-y-4">
      <div>
        <label for="listingsCategory" class="block text-sm font-bold text-gray-700 mb-1">Listing category</label>
        <ng-select
          [readonly]="mode === 'edit'"
          [items]="listingCategories"
          bindLabel="name"
          bindValue="value"
          (ngModelChange)="changeListingCategory($event)"
          [(ngModel)]="listing.listingsCategory"
          [clearable]="false"
          name="listingsCategory"
        >
        </ng-select>
      </div>

      <div>
        <app-validated-input label="Title of Listing" name="title" [(ngModel)]="listing.title"></app-validated-input>
      </div>

      <div>
        <app-validated-quill label="Description" name="description" [(ngModel)]="listing.description"></app-validated-quill>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <app-validated-ng-select label="Property Category" name="type" [(ngModel)]="listing.type" [items]="typesOfCommercialProperty"></app-validated-ng-select>
        <app-validated-location label="Location" name="location" [(ngModel)]="listing.location"></app-validated-location>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <app-validated-price label="Price" name="price" [(ngModel)]="listing.price"></app-validated-price>
        <div class="flex justify-center">
          <label class="flex items-center cursor-pointer">
            <div class="relative">
              <input type="checkbox" [(ngModel)]="listing.draft" name="draft" class="hidden" />
              <div class="toggle-bg block w-12 h-6 rounded-full bg-gray-600 transition"></div>
            </div>
            <div class="ml-3 text-gray-700 font-medium">Draft Mode (Will not be shown as public listing)</div>
          </label>
        </div>
      </div>

      <div class="container mx-auto pt-2">
        <app-drag-drop-mixed [listing]="listing" [ts]="ts" (imageOrderChanged)="imageOrderChanged($event)" (imageToDelete)="deleteConfirm($event)"></app-drag-drop-mixed>
        <!-- </div> -->
      </div>

      <div class="bg-white px-4 pb-4 rounded-lg shadow">
        <h2 class="text-lg font-semibold mb-2">Property Pictures</h2>
        @if(mode === 'create'){
        <p class="text-sm text-gray-500 mb-4">(Pictures can be uploaded once the listing is posted initially)</p>
        } @if(mode !== 'create'){
        <button
          (click)="uploadPropertyPicture()"
          class="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <svg class="mr-2 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"></path>
          </svg>
          Upload
        </button>
        }
      </div>
      @if (mode==='create'){
      <button (click)="save()" class="bg-blue-500 text-white px-4 py-2 mt-3 rounded-md hover:bg-blue-600">Post Listing</button>
      } @else {
      <button (click)="save()" class="bg-blue-500 text-white px-4 py-2 mt-3 rounded-md hover:bg-blue-600">Update Listing</button>
      }
    </form>
    }
  </div>
</div>

<app-image-crop-and-upload [uploadParams]="uploadParams" (uploadFinished)="uploadFinished($event)"></app-image-crop-and-upload>
<app-confirmation></app-confirmation>
