import CloneObject from './clone-object.js';
export default class CloneWeakMap extends CloneObject {
  constructor(value, path, argumentsList, hasOnValidate) {
    super(undefined, path, argumentsList, hasOnValidate);
    this._weakKey = argumentsList[0];
    this._weakHas = value.has(this._weakKey);
    this._weakValue = value.get(this._weakKey);
  }
  isChanged(value) {
    return this._weakValue !== value.get(this._weakKey);
  }
  undo(object) {
    const weakHas = object.has(this._weakKey);
    if (this._weakHas && !weakHas) {
      object.set(this._weakKey, this._weakValue);
    } else if (!this._weakHas && weakHas) {
      object.delete(this._weakKey);
    } else if (this._weakValue !== object.get(this._weakKey)) {
      object.set(this._weakKey, this._weakValue);
    }
  }
}