// 1. Shared Service (modal.service.ts)
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BusinessListingCriteria, CommercialPropertyListingCriteria, ModalResult, UserListingCriteria } from '../../../../../bizmatch-server/src/models/main.model';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalVisibleSubject = new BehaviorSubject<boolean>(false);
  private messageSubject = new BehaviorSubject<BusinessListingCriteria | CommercialPropertyListingCriteria | UserListingCriteria>(null);
  private resolvePromise!: (value: ModalResult) => void;

  modalVisible$: Observable<boolean> = this.modalVisibleSubject.asObservable();
  message$: Observable<BusinessListingCriteria | CommercialPropertyListingCriteria | UserListingCriteria> = this.messageSubject.asObservable();

  showModal(message: BusinessListingCriteria | CommercialPropertyListingCriteria | UserListingCriteria): Promise<ModalResult> {
    this.messageSubject.next(message);
    this.modalVisibleSubject.next(true);
    return new Promise<ModalResult>(resolve => {
      this.resolvePromise = resolve;
    });
  }

  accept(): void {
    this.modalVisibleSubject.next(false);
    this.resolvePromise({ accepted: true });
  }

  reject(backupCriteria: BusinessListingCriteria | CommercialPropertyListingCriteria | UserListingCriteria): void {
    this.modalVisibleSubject.next(false);
    this.resolvePromise({ accepted: false, criteria: backupCriteria });
  }
}
