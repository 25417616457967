import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BusinessListing, User } from '../../../../../../bizmatch-server/src/models/db.model';
import { LISTINGS_PER_PAGE, ListingType, UserListingCriteria, emailToDirName } from '../../../../../../bizmatch-server/src/models/main.model';
import { environment } from '../../../../environments/environment';
import { CustomerSubTypeComponent } from '../../../components/customer-sub-type/customer-sub-type.component';
import { PaginatorComponent } from '../../../components/paginator/paginator.component';
import { ModalService } from '../../../components/search-modal/modal.service';
import { CriteriaChangeService } from '../../../services/criteria-change.service';
import { ImageService } from '../../../services/image.service';
import { ListingsService } from '../../../services/listings.service';
import { SearchService } from '../../../services/search.service';
import { SelectOptionsService } from '../../../services/select-options.service';
import { UserService } from '../../../services/user.service';
import { assignProperties, getCriteriaProxy, resetUserListingCriteria } from '../../../utils/utils';
@UntilDestroy()
@Component({
  selector: 'app-broker-listings',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, NgOptimizedImage, PaginatorComponent, CustomerSubTypeComponent],
  templateUrl: './broker-listings.component.html',
  styleUrls: ['./broker-listings.component.scss', '../../pages.scss'],
})
export class BrokerListingsComponent {
  environment = environment;
  listings: Array<BusinessListing>;
  users: Array<User>;
  filteredListings: Array<ListingType>;
  criteria: UserListingCriteria;
  realEstateChecked: boolean;
  maxPrice: string;
  minPrice: string;
  type: string;
  statesSet = new Set();
  state: string;
  first: number = 0;
  rows: number = 12;
  totalRecords: number = 0;
  ts = new Date().getTime();
  env = environment;
  public category: 'business' | 'commercialProperty' | 'professionals_brokers' | undefined;
  emailToDirName = emailToDirName;
  page = 1;
  pageCount = 1;
  constructor(
    public selectOptions: SelectOptionsService,
    private listingsService: ListingsService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private imageService: ImageService,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private modalService: ModalService,
    private criteriaChangeService: CriteriaChangeService,
  ) {
    this.criteria = getCriteriaProxy('brokerListings', this) as UserListingCriteria;
    this.init();
    this.searchService.currentCriteria.pipe(untilDestroyed(this)).subscribe(criteria => {
      if (criteria && criteria.criteriaType === 'brokerListings') {
        this.criteria = criteria as UserListingCriteria;
        this.search();
      }
    });
  }
  async ngOnInit() {}
  async init() {
    this.search();
  }
  async search() {
    const usersReponse = await this.userService.search(this.criteria);
    this.users = usersReponse.results;
    this.totalRecords = usersReponse.totalCount;
    this.pageCount = this.totalRecords % LISTINGS_PER_PAGE === 0 ? this.totalRecords / LISTINGS_PER_PAGE : Math.floor(this.totalRecords / LISTINGS_PER_PAGE) + 1;
    this.page = this.criteria.page ? this.criteria.page : 1;
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }
  onPageChange(page: any) {
    this.criteria.start = (page - 1) * LISTINGS_PER_PAGE;
    this.criteria.length = LISTINGS_PER_PAGE;
    this.criteria.page = page;
    this.search();
  }

  reset() {}

  // New methods for filter actions
  clearAllFilters() {
    // Reset criteria to default values
    resetUserListingCriteria(this.criteria);

    // Reset pagination
    this.criteria.page = 1;
    this.criteria.start = 0;

    this.criteriaChangeService.notifyCriteriaChange();

    // Search with cleared filters
    this.searchService.search(this.criteria);
  }

  async openFilterModal() {
    // Open the search modal with current criteria
    const modalResult = await this.modalService.showModal(this.criteria);
    if (modalResult.accepted) {
      this.searchService.search(this.criteria);
    } else {
      this.criteria = assignProperties(this.criteria, modalResult.criteria);
    }
  }
}
