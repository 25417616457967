import { Component, ElementRef, Input, output, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { UploadParams } from '../../../../../bizmatch-server/src/models/main.model';
import { ImageService } from '../../services/image.service';
import { ListingsService } from '../../services/listings.service';
import { SharedModule } from '../../shared/shared/shared.module';
export interface UploadReponse {
  success: boolean;
  type: 'uploadPropertyPicture' | 'uploadCompanyLogo' | 'uploadProfile';
}
@Component({
  selector: 'app-image-crop-and-upload',
  standalone: true,
  imports: [SharedModule, ImageCropperComponent],
  templateUrl: './image-crop-and-upload.component.html',
  styleUrl: './image-crop-and-upload.component.scss',
})
export class ImageCropAndUploadComponent {
  showModal = false;
  imageChangedEvent: any = '';
  croppedImage: Blob | null = null;
  @Input() uploadParams: UploadParams;
  uploadFinished = output<UploadReponse>();
  @ViewChild('fileInput', { static: true }) fileInput!: ElementRef<HTMLInputElement>;

  constructor(private imageService: ImageService, private listingsService: ListingsService) {}

  ngOnInit() {}
  ngOnChanges() {
    this.openFileDialog();
  }
  openFileDialog() {
    if (this.uploadParams) {
      this.fileInput.nativeElement.click();
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showModal = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
  }

  closeModal() {
    this.imageChangedEvent = null;
    this.croppedImage = null;
    this.showModal = false;
    this.fileInput.nativeElement.value = '';
    this.uploadFinished.emit({ success: false, type: this.uploadParams.type });
  }

  async uploadImage() {
    if (this.croppedImage) {
      await this.imageService.uploadImage(this.croppedImage, this.uploadParams.type, this.uploadParams.imagePath, this.uploadParams.serialId);
      this.closeModal();
      this.uploadFinished.emit({ success: true, type: this.uploadParams.type });
    }
  }

  loadImageFailed() {
    console.error('Load image failed');
  }
}
