import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Message } from './message.service';

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div [@toastAnimation]="'in'" [ngClass]="getClasses()" role="alert">
      <div class="ms-3 text-sm font-medium">{{ message.text }}</div>
      <button type="button" (click)="onClose()" class="ms-auto -mx-1.5 -my-1.5 rounded-lg p-1.5 inline-flex items-center justify-center h-8 w-8" [ngClass]="getCloseButtonClasses()" aria-label="Close">
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
        </svg>
      </button>
    </div>
  `,
  animations: [
    trigger('toastAnimation', [
      transition(':enter', [style({ transform: 'translateY(100%)', opacity: 0 }), animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))]),
      transition(':leave', [animate('300ms ease-in', style({ transform: 'translateY(100%)', opacity: 0 }))]),
    ]),
  ],
})
export class MessageComponent {
  @Input() message!: Message;
  @Output() close = new EventEmitter<void>();

  onClose(): void {
    this.close.emit();
  }

  getClasses(): string {
    return `flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 rounded-lg shadow ${this.getSeverityClasses()}`;
  }

  getCloseButtonClasses(): string {
    switch (this.message.severity) {
      case 'success':
        return 'text-green-600 hover:bg-green-200 focus:ring-green-400';
      case 'danger':
        return 'text-red-600 hover:bg-red-200 focus:ring-red-400';
      case 'warning':
        return 'text-yellow-600 hover:bg-yellow-200 focus:ring-yellow-400';
      default:
        return 'text-blue-600 hover:bg-blue-200 focus:ring-blue-400';
    }
  }

  private getSeverityClasses(): string {
    switch (this.message.severity) {
      case 'success':
        return 'bg-green-100 text-green-700';
      case 'danger':
        return 'bg-red-100 text-red-700';
      case 'warning':
        return 'bg-yellow-100 text-yellow-700';
      default:
        return 'bg-blue-100 text-blue-700';
    }
  }
}
