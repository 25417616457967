import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { User } from '../../../../../bizmatch-server/src/models/db.model';
import { AuditService } from '../../services/audit.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { map2User } from '../../utils/utils';

@Component({
  selector: 'app-success',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './success.component.html',
  styleUrl: './success.component.scss',
})
export class SuccessComponent {
  user: User;
  maxAttemptsReached: boolean = false; // Neue Variable hinzufügen

  constructor(private userService: UserService, private auditService: AuditService, private router: Router, private authService: AuthService) {}

  async ngOnInit() {
    let email = null;
    try {
      const token = await this.authService.getToken();
      const keycloakUser = map2User(token);
      email = keycloakUser.email;
      this.user = await this.userService.getByMail(email);
      this.checkSubscriptionPlan(email);
    } catch (e) {
      this.checkSubscriptionPlan(email, e.message);
    }
  }

  async checkSubscriptionPlan(email: string, error?: string) {
    if (!email) {
      this.auditService.log({ severity: 'error', text: `Unauthorized Access to Success Page ${error}` });
      this.router.navigate(['home']);
      return;
    }

    let attempts = 0;
    const maxAttempts = 20;
    const interval = 5000; // 5 Sekunden

    const intervalId = setInterval(async () => {
      if (attempts >= maxAttempts) {
        clearInterval(intervalId);
        console.error('Max attempts reached');
        this.maxAttemptsReached = true; // Setze die Variable auf true, wenn die max. Versuche erreicht wurden
        return;
      }

      attempts++;

      this.user = await this.userService.getByMail(email, true);

      if (this.user && this.user.subscriptionPlan) {
        clearInterval(intervalId);
        console.log('Subscription plan is set:', this.user.subscriptionPlan);
      } else {
        console.log(`Attempt ${attempts}: Subscription plan is not set yet.`);
      }
    }, interval);
  }
}
