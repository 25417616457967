<!-- Modal -->
<div *ngIf="showModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
  <div class="bg-white p-5 rounded-lg shadow-xl" style="width: 90%; max-width: 600px">
    <h3 class="text-lg font-semibold mb-4">Crop Image</h3>
    <image-cropper (loadImageFailed)="loadImageFailed()" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" format="png" (imageCropped)="imageCropped($event)"></image-cropper>
    <div class="mt-4 flex justify-end">
      <button (click)="closeModal()" class="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300">Cancel</button>
      <button (click)="uploadImage()" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Upload</button>
    </div>
  </div>
</div>
<input type="file" #fileInput style="display: none" (change)="fileChangeEvent($event)" accept="image/*" />
