import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeService } from 'ngx-stripe';
import { switchMap } from 'rxjs';
import { User } from '../../../../../bizmatch-server/src/models/db.model';
import { Checkout, KeycloakUser } from '../../../../../bizmatch-server/src/models/main.model';
import { environment } from '../../../environments/environment';
import { AuditService } from '../../services/audit.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { SharedModule } from '../../shared/shared/shared.module';
import { map2User } from '../../utils/utils';

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss',
})
export class PricingComponent {
  private apiBaseUrl = environment.apiBaseUrl;
  private id: string | undefined = this.activatedRoute.snapshot.params['id'] as string | undefined;
  pricingOverview: boolean | undefined = this.activatedRoute.snapshot.data['pricingOverview'] as boolean | undefined;
  keycloakUser: KeycloakUser;
  user: User;
  constructor(
    private http: HttpClient,
    private stripeService: StripeService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private auditService: AuditService,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    const token = await this.authService.getToken();
    this.keycloakUser = map2User(token);
    if (this.keycloakUser) {
      this.user = await this.userService.getByMail(this.keycloakUser.email);
      const originalKeycloakUser = await this.userService.getKeycloakUser(this.keycloakUser.id);
      const priceId = originalKeycloakUser.attributes && originalKeycloakUser.attributes['priceID'] ? originalKeycloakUser.attributes['priceID'][0] : null;
      if (priceId) {
        originalKeycloakUser.attributes['priceID'] = null;
        await this.userService.updateKeycloakUser(originalKeycloakUser);
      }
      if (!this.user.subscriptionPlan) {
        if (this.id === 'free' || priceId === 'free') {
          this.user.subscriptionPlan = 'free';
          await this.userService.saveGuaranteed(this.user);
          this.router.navigate([`/account`]);
        } else if (this.id || priceId) {
          const base64PriceId = this.id ? this.id : priceId;
          this.checkout({ priceId: atob(base64PriceId), email: this.keycloakUser.email, name: `${this.keycloakUser.firstName} ${this.keycloakUser.lastName}` });
        }
      }
    } else {
      this.pricingOverview = false;
    }
  }

  async register(priceId?: string) {
    if (this.keycloakUser) {
      if (!priceId) {
        this.user = await this.userService.getByMail(this.keycloakUser.email);
        this.user.subscriptionPlan = 'free';
        await this.userService.saveGuaranteed(this.user);
        this.router.navigate([`/account`]);
      } else {
        this.checkout({ priceId: priceId, email: this.keycloakUser.email, name: `${this.keycloakUser.firstName} ${this.keycloakUser.lastName}` });
      }
    } else {
      // if (priceId) {
      //   this.keycloakService.register({
      //     redirectUri: `${window.location.origin}/pricing/${btoa(priceId)}`,
      //   });
      // } else {
      //   this.keycloakService.register({ redirectUri: `${window.location.origin}/pricing/free` });
      // }
    }
  }

  checkout(checkout: Checkout) {
    // Check the server.js tab to see an example implementation
    this.http
      .post(`${this.apiBaseUrl}/bizmatch/payment/create-checkout-session`, checkout)
      .pipe(
        switchMap((session: any) => {
          return this.stripeService.redirectToCheckout({ sessionId: session.id });
        }),
      )
      .subscribe(result => {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.
        if (result.error) {
          alert(result.error.message);
        }
      });
  }
}
