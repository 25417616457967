import { Component } from '@angular/core';
import { User } from '../../../../../../bizmatch-server/src/models/db.model';
import { ErrorResponse, KeycloakUser, MailInfo } from '../../../../../../bizmatch-server/src/models/main.model';
import { MessageService } from '../../../components/message/message.service';
import { ValidatedInputComponent } from '../../../components/validated-input/validated-input.component';
import { ValidatedNgSelectComponent } from '../../../components/validated-ng-select/validated-ng-select.component';
import { ValidatedTextareaComponent } from '../../../components/validated-textarea/validated-textarea.component';
import { ValidationMessagesService } from '../../../components/validation-messages.service';
import { AuditService } from '../../../services/audit.service';
import { AuthService } from '../../../services/auth.service';
import { MailService } from '../../../services/mail.service';
import { SelectOptionsService } from '../../../services/select-options.service';
import { UserService } from '../../../services/user.service';
import { SharedModule } from '../../../shared/shared/shared.module';
import { createMailInfo, map2User } from '../../../utils/utils';
@Component({
  selector: 'app-email-us',
  standalone: true,
  imports: [SharedModule, ValidatedInputComponent, ValidatedTextareaComponent, ValidatedNgSelectComponent],
  providers: [],
  templateUrl: './email-us.component.html',
  styleUrl: './email-us.component.scss',
})
export class EmailUsComponent {
  mailinfo: MailInfo;
  keycloakUser: KeycloakUser;
  user: User;
  errorResponse: ErrorResponse;
  constructor(
    private mailService: MailService,
    private userService: UserService,
    private validationMessagesService: ValidationMessagesService,
    private messageService: MessageService,
    public selectOptions: SelectOptionsService,
    private auditService: AuditService,
    private authService: AuthService,
  ) {
    this.mailinfo = createMailInfo();
  }
  async ngOnInit() {
    const token = await this.authService.getToken();
    this.keycloakUser = map2User(token);
    if (this.keycloakUser) {
      this.user = await this.userService.getByMail(this.keycloakUser.email);
      this.mailinfo = createMailInfo(this.user);
    }
  }
  ngOnDestroy() {
    this.validationMessagesService.clearMessages(); // Löschen Sie alle bestehenden Validierungsnachrichten
  }
  async mail() {
    try {
      this.validationMessagesService.updateMessages([]);
      this.mailinfo.email = 'support@bizmatch.net';
      await this.mailService.mail(this.mailinfo);
      this.messageService.addMessage({ severity: 'success', text: 'Your request has been forwarded to the support team of bizmatch.', duration: 3000 });
      this.auditService.createEvent(null, 'emailus', this.mailinfo.email, this.mailinfo);
      this.mailinfo = createMailInfo(this.user);
    } catch (error) {
      this.messageService.addMessage({
        severity: 'danger',
        text: 'Please check your inputs',
        duration: 5000,
      });
      if (error.error && Array.isArray(error.error?.message)) {
        this.validationMessagesService.updateMessages(error.error.message);
      }
    }
  }
  containsError(fieldname: string) {
    return this.errorResponse?.fields.map(f => f.fieldname).includes(fieldname);
  }
}
