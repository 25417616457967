<nav class="bg-white border-gray-200 dark:bg-gray-900 print:hidden">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <a routerLink="/home" class="flex items-center space-x-3 rtl:space-x-reverse">
      <img src="assets/images/header-logo.png" class="h-8" alt="Flowbite Logo" />
    </a>
    <div class="flex items-center md:order-2 space-x-3 rtl:space-x-reverse">
      <!-- Filter button -->
      @if(isFilterUrl()){
      <button
        type="button"
        #triggerButton
        (click)="openModal()"
        id="filterDropdownButton"
        class="max-sm:hidden px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      >
        <i class="fas fa-filter mr-2"></i>Filter ({{ getNumberOfFiltersSet() }})
      </button>
      <!-- Sort button -->
      <div class="relative">
        <button
          type="button"
          id="sortDropdownButton"
          class="max-sm:hidden px-4 py-2 text-sm font-medium bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          (click)="toggleSortDropdown()"
          [ngClass]="{ 'text-blue-500': selectOptions.getSortByOption(criteria?.sortBy) !== 'Sort', 'text-gray-900': selectOptions.getSortByOption(criteria?.sortBy) === 'Sort' }"
        >
          <i class="fas fa-sort mr-2"></i>{{ selectOptions.getSortByOption(criteria?.sortBy) }}
        </button>

        <!-- Sort options dropdown -->
        <div *ngIf="sortDropdownVisible" class="absolute right-0 z-50 w-48 md:mt-2 max-md:mt-20 max-md:mr-[-2.5rem] bg-white border border-gray-200 rounded-lg drop-shadow-custom-bg dark:bg-gray-800 dark:border-gray-600">
          <ul class="py-1 text-sm text-gray-700 dark:text-gray-200">
            @for(item of sortByOptions; track item){
            <li (click)="sortBy(item.value)" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">{{ item.selectName ? item.selectName : item.name }}</li>
            }
          </ul>
        </div>
      </div>
      }
      <button
        type="button"
        class="flex text-sm bg-gray-400 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
        id="user-menu-button"
        aria-expanded="false"
        [attr.data-dropdown-toggle]="user ? 'user-login' : 'user-unknown'"
        data-dropdown-placement="bottom"
      >
        <span class="sr-only">Open user menu</span>
        @if(isProfessional || (authService.isAdmin() | async) && user?.hasProfile){
        <img class="w-8 h-8 rounded-full object-cover" src="{{ profileUrl }}" alt="user photo" />
        } @else {
        <i class="flex justify-center items-center text-stone-50 w-8 h-8 rounded-full fa-solid fa-bars"></i>
        }
      </button>
      <!-- Dropdown menu -->
      @if(user){
      <div class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600" id="user-login">
        <div class="px-4 py-3">
          <span class="block text-sm text-gray-900 dark:text-white">Welcome, {{ user.firstname }} </span>
          <span class="block text-sm text-gray-500 truncate dark:text-gray-400">{{ user.email }}</span>
        </div>
        <ul class="py-2" aria-labelledby="user-menu-button">
          <li>
            <a routerLink="/account" (click)="closeDropdown()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Account</a>
          </li>
          @if((user.customerType==='professional' && user.customerSubType==='broker') || user.customerType==='seller' || (authService.isAdmin() | async)){
          <li>
            @if(user.customerType==='professional'){
            <a routerLink="/createBusinessListing" (click)="closeDropdown()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >Create Listing</a
            >
            }@else {
            <a routerLink="/createCommercialPropertyListing" (click)="closeDropdown()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >Create Listing</a
            >
            }
          </li>
          <li>
            <a routerLink="/myListings" (click)="closeDropdown()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">My Listings</a>
          </li>
          }
          <li>
            <a routerLink="/myFavorites" (click)="closeDropdown()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">My Favorites</a>
          </li>
          <li>
            <a routerLink="/emailUs" (click)="closeDropdown()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">EMail Us</a>
          </li>
          <li>
            <a routerLink="/logout" (click)="closeDropdown()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Logout</a>
          </li>
        </ul>
        @if(authService.isAdmin() | async){
        <ul class="py-2">
          <li>
            <a routerLink="admin/users" (click)="closeDropdown()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Users (Admin)</a>
          </li>
        </ul>
        }
        <ul class="py-2 md:hidden">
          <li>
            <a
              routerLink="/businessListings"
              [ngClass]="{ 'text-blue-700': isActive('/businessListings'), 'text-gray-700': !isActive('/businessListings') }"
              class="block px-4 py-2 text-sm font-semibold"
              (click)="closeMenusAndSetCriteria('businessListings')"
              >Businesses</a
            >
          </li>
          @if ((numberOfCommercial$ | async) > 0) {
          <li>
            <a
              routerLink="/commercialPropertyListings"
              [ngClass]="{ 'text-blue-700': isActive('/commercialPropertyListings'), 'text-gray-700': !isActive('/commercialPropertyListings') }"
              class="block px-4 py-2 text-sm font-semibold"
              (click)="closeMenusAndSetCriteria('commercialPropertyListings')"
              >Properties</a
            >
          </li>
          } @if ((numberOfBroker$ | async) > 0) {
          <li>
            <a
              routerLink="/brokerListings"
              [ngClass]="{ 'text-blue-700': isActive('/brokerListings'), 'text-gray-700': !isActive('/brokerListings') }"
              class="block px-4 py-2 text-sm font-semibold"
              (click)="closeMenusAndSetCriteria('brokerListings')"
              >Professionals</a
            >
          </li>
          }
        </ul>
      </div>
      } @else {
      <div class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600" id="user-unknown">
        <ul class="py-2" aria-labelledby="user-menu-button">
          <li>
            <a routerLink="/login" [queryParams]="{ mode: 'login' }" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Log In</a>
          </li>
          <li>
            <a routerLink="/login" [queryParams]="{ mode: 'register' }" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign Up</a>
          </li>
        </ul>
        <ul class="py-2 md:hidden">
          <li>
            <a
              routerLink="/businessListings"
              [ngClass]="{ 'text-blue-700': isActive('/businessListings'), 'text-gray-700': !isActive('/businessListings') }"
              class="block px-4 py-2 text-sm font-bold"
              (click)="closeMenusAndSetCriteria('businessListings')"
              >Businesses</a
            >
          </li>
          @if ((numberOfCommercial$ | async) > 0) {
          <li>
            <a
              routerLink="/commercialPropertyListings"
              [ngClass]="{ 'text-blue-700': isActive('/commercialPropertyListings'), 'text-gray-700': !isActive('/commercialPropertyListings') }"
              class="block px-4 py-2 text-sm font-bold"
              (click)="closeMenusAndSetCriteria('commercialPropertyListings')"
              >Properties</a
            >
          </li>
          } @if ((numberOfBroker$ | async) > 0) {
          <li>
            <a
              routerLink="/brokerListings"
              [ngClass]="{ 'text-blue-700': isActive('/brokerListings'), 'text-gray-700': !isActive('/brokerListings') }"
              class="block px-4 py-2 text-sm font-bold"
              (click)="closeMenusAndSetCriteria('brokerListings')"
              >Professionals</a
            >
          </li>
          }
        </ul>
      </div>
      }
    </div>
    <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-user">
      <ul
        class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
      >
        <li>
          <a
            routerLinkActive="active-link"
            routerLink="/businessListings"
            [ngClass]="{ 'bg-blue-700 text-white md:text-blue-700 md:bg-transparent md:dark:text-blue-500': isActive('/businessListings') }"
            class="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            aria-current="page"
            (click)="closeMenusAndSetCriteria('businessListings')"
            >Businesses</a
          >
        </li>
        @if ((numberOfCommercial$ | async) > 0) {
        <li>
          <a
            routerLinkActive="active-link"
            routerLink="/commercialPropertyListings"
            [ngClass]="{ 'bg-blue-700 text-white md:text-blue-700 md:bg-transparent md:dark:text-blue-500': isActive('/commercialPropertyListings') }"
            class="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            (click)="closeMenusAndSetCriteria('commercialPropertyListings')"
            >Properties</a
          >
        </li>
        } @if ((numberOfBroker$ | async) > 0) {
        <li>
          <a
            routerLinkActive="active-link"
            routerLink="/brokerListings"
            [ngClass]="{ 'bg-blue-700 text-white md:text-blue-700 md:bg-transparent md:dark:text-blue-500': isActive('/brokerListings') }"
            class="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            (click)="closeMenusAndSetCriteria('brokerListings')"
            >Professionals</a
          >
        </li>
        }
      </ul>
    </div>
  </div>
  <!-- Mobile filter button -->
  <div class="md:hidden flex justify-center pb-4">
    <button
      (click)="openModal()"
      type="button"
      id="filterDropdownMobileButton"
      class="w-full mx-4 px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
    >
      <i class="fas fa-filter mr-2"></i>Filter ({{ getNumberOfFiltersSet() }})
    </button>
    <!-- Sorting -->
    <button
      (click)="toggleSortDropdown()"
      type="button"
      id="sortDropdownMobileButton"
      class="mx-4 w-1/2 px-4 py-2 text-sm font-medium bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      [ngClass]="{ 'text-blue-500': selectOptions.getSortByOption(criteria?.sortBy) !== 'Sort', 'text-gray-900': selectOptions.getSortByOption(criteria?.sortBy) === 'Sort' }"
    >
      <i class="fas fa-sort mr-2"></i>{{ selectOptions.getSortByOption(criteria?.sortBy) }}
    </button>
  </div>
</nav>
