import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { BaseInputComponent } from '../base-input/base-input.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { ValidationMessagesService } from '../validation-messages.service';

@Component({
  selector: 'app-validated-input',
  templateUrl: './validated-input.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, TooltipComponent, NgxMaskDirective, NgxMaskPipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValidatedInputComponent),
      multi: true,
    },
    provideNgxMask(),
  ],
})
export class ValidatedInputComponent extends BaseInputComponent {
  @Input() kind: 'text' | 'number' | 'email' = 'text';
  @Input() mask: string;
  constructor(validationMessagesService: ValidationMessagesService) {
    super(validationMessagesService);
  }

  onInputChange(event: string | number): void {
    if (this.kind === 'number') {
      if (typeof event === 'number') {
        this.value = event;
      } else {
        this.value = parseFloat(event);
      }
    } else {
      const text = event as string;
      this.value = text?.length > 0 ? event : null;
    }
    // this.value = event?.length > 0 ? (this.kind === 'number' ? parseFloat(event) : event) : null;
    this.onChange(this.value);
  }
}
