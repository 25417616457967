import { Component, NgZone } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { GalleryModule, ImageItem } from 'ng-gallery';
import { ShareButton } from 'ngx-sharebuttons/button';
import { lastValueFrom } from 'rxjs';
import { CommercialPropertyListing, EventTypeEnum, ShareByEMail, User } from '../../../../../../bizmatch-server/src/models/db.model';
import { CommercialPropertyListingCriteria, ErrorResponse, KeycloakUser, MailInfo } from '../../../../../../bizmatch-server/src/models/main.model';
import { environment } from '../../../../environments/environment';
import { EMailService } from '../../../components/email/email.service';
import { MessageService } from '../../../components/message/message.service';
import { ValidatedInputComponent } from '../../../components/validated-input/validated-input.component';
import { ValidatedNgSelectComponent } from '../../../components/validated-ng-select/validated-ng-select.component';
import { ValidatedTextareaComponent } from '../../../components/validated-textarea/validated-textarea.component';
import { ValidationMessagesService } from '../../../components/validation-messages.service';
import { AuditService } from '../../../services/audit.service';
import { AuthService } from '../../../services/auth.service';
import { HistoryService } from '../../../services/history.service';
import { ImageService } from '../../../services/image.service';
import { ListingsService } from '../../../services/listings.service';
import { MailService } from '../../../services/mail.service';
import { SelectOptionsService } from '../../../services/select-options.service';
import { UserService } from '../../../services/user.service';
import { SharedModule } from '../../../shared/shared/shared.module';
import { createMailInfo, map2User } from '../../../utils/utils';
import { BaseDetailsComponent } from '../base-details.component';

@Component({
  selector: 'app-details-commercial-property-listing',
  standalone: true,
  imports: [SharedModule, ValidatedInputComponent, ValidatedTextareaComponent, ShareButton, ValidatedNgSelectComponent, GalleryModule, LeafletModule],
  providers: [],
  templateUrl: './details-commercial-property-listing.component.html',
  styleUrl: '../details.scss',
})
export class DetailsCommercialPropertyListingComponent extends BaseDetailsComponent {
  responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1,
    },
  ];
  private id: string | undefined = this.activatedRoute.snapshot.params['id'] as string | undefined;
  override listing: CommercialPropertyListing;
  criteria: CommercialPropertyListingCriteria;
  mailinfo: MailInfo;
  environment = environment;
  keycloakUser: KeycloakUser;
  user: User;
  listingUser: User;
  description: SafeHtml;
  ts = new Date().getTime();
  env = environment;
  errorResponse: ErrorResponse;
  faTimes = faTimes;
  propertyDetails = [];
  images: Array<ImageItem> = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private listingsService: ListingsService,
    private router: Router,
    private userService: UserService,
    public selectOptions: SelectOptionsService,
    private mailService: MailService,
    private sanitizer: DomSanitizer,
    public historyService: HistoryService,
    private imageService: ImageService,
    private ngZone: NgZone,
    private validationMessagesService: ValidationMessagesService,
    private messageService: MessageService,
    private auditService: AuditService,
    private emailService: EMailService,
    public authService: AuthService,
  ) {
    super();
    this.mailinfo = { sender: {}, email: '', url: environment.mailinfoUrl };
  }

  async ngOnInit() {
    const token = await this.authService.getToken();
    this.keycloakUser = map2User(token);
    if (this.keycloakUser) {
      this.user = await this.userService.getByMail(this.keycloakUser.email);
      this.mailinfo = createMailInfo(this.user);
    }
    try {
      this.listing = (await lastValueFrom(this.listingsService.getListingById(this.id, 'commercialProperty'))) as CommercialPropertyListing;
      this.auditService.createEvent(this.listing.id, 'view', this.user?.email);
      this.listingUser = await this.userService.getByMail(this.listing.email);
      this.description = this.sanitizer.bypassSecurityTrustHtml(this.listing.description);
      import('flowbite').then(flowbite => {
        flowbite.initCarousels();
      });
      this.propertyDetails = [
        { label: 'Property Category', value: this.selectOptions.getCommercialProperty(this.listing.type) },
        { label: 'Located in', value: this.selectOptions.getState(this.listing.location.state) },
        { label: this.listing.location.name ? 'City' : 'County', value: this.listing.location.name ? this.listing.location.name : this.listing.location.county },
        { label: 'Asking Price:', value: `$${this.listing.price?.toLocaleString()}` },
        { label: 'Listed since', value: `${this.dateInserted()} - ${this.getDaysListed()} days` },
        {
          label: 'Listing by',
          value: null, // Wird nicht verwendet
          isHtml: true,
          isListingBy: true, // Flag für den speziellen Fall
          user: this.listingUser, // Übergebe das User-Objekt
          imagePath: this.listing.imagePath,
          imageBaseUrl: this.env.imageBaseUrl,
          ts: this.ts,
        },
      ];
      if (this.listing.draft) {
        this.propertyDetails.push({ label: 'Draft', value: this.listing.draft ? 'Yes' : 'No' });
      }
      this.listing.imageOrder.forEach(image => {
        const imageURL = `${this.env.imageBaseUrl}/pictures/property/${this.listing.imagePath}/${this.listing.serialId}/${image}`;
        this.images.push(new ImageItem({ src: imageURL, thumb: imageURL }));
      });
      if (this.listing.location.street) {
        this.configureMap();
      }
    } catch (error) {
      this.auditService.log({ severity: 'error', text: error.error.message });
      this.router.navigate(['notfound']);
    }

    //this.initFlowbite();
  }
  ngOnDestroy() {
    this.validationMessagesService.clearMessages(); // Löschen Sie alle bestehenden Validierungsnachrichten
  }
  private initFlowbite() {
    this.ngZone.runOutsideAngular(() => {
      import('flowbite')
        .then(flowbite => {
          flowbite.initCarousels();
        })
        .catch(error => console.error('Error initializing Flowbite:', error));
    });
  }
  async mail() {
    try {
      this.mailinfo.email = this.listingUser.email;
      this.mailinfo.listing = this.listing;
      await this.mailService.mail(this.mailinfo);
      this.validationMessagesService.clearMessages();
      this.auditService.createEvent(this.listing.id, 'contact', this.user?.email, this.mailinfo.sender);
      this.messageService.addMessage({ severity: 'success', text: 'Your message has been sent to the creator of the listing', duration: 3000 });
      this.mailinfo = createMailInfo(this.user);
    } catch (error) {
      this.messageService.addMessage({
        severity: 'danger',
        text: 'An error occurred while sending the request - Please check your inputs',
        duration: 5000,
      });
      if (error.error && Array.isArray(error.error?.message)) {
        this.validationMessagesService.updateMessages(error.error.message);
      }
    }
  }
  containsError(fieldname: string) {
    return this.errorResponse?.fields.map(f => f.fieldname).includes(fieldname);
  }
  getImageIndices(): number[] {
    return this.listing && this.listing.imageOrder ? this.listing.imageOrder.slice(1).map((e, i) => i + 1) : [];
  }
  save() {
    this.listing.favoritesForUser.push(this.user.email);
    this.listingsService.save(this.listing, 'commercialProperty');
    this.auditService.createEvent(this.listing.id, 'favorite', this.user?.email);
  }
  isAlreadyFavorite() {
    return this.listing.favoritesForUser.includes(this.user.email);
  }
  async showShareByEMail() {
    const result = await this.emailService.showShareByEMail({
      yourEmail: this.user ? this.user.email : null,
      yourName: this.user ? `${this.user.firstname} ${this.user.lastname}` : null,
      url: environment.mailinfoUrl,
      listingTitle: this.listing.title,
      id: this.listing.id,
      type: 'commercialProperty',
    });
    if (result) {
      this.auditService.createEvent(this.listing.id, 'email', this.user?.email, <ShareByEMail>result);
      this.messageService.addMessage({
        severity: 'success',
        text: 'Your Email has beend sent',
        duration: 5000,
      });
    }
  }
  createEvent(eventType: EventTypeEnum) {
    this.auditService.createEvent(this.listing.id, eventType, this.user?.email);
  }
  getDaysListed() {
    return dayjs().diff(this.listing.created, 'day');
  }
  dateInserted() {
    return dayjs(this.listing.created).format('DD/MM/YYYY');
  }
}
