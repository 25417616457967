import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ShareByEMail } from '../../../../../bizmatch-server/src/models/db.model';

@Injectable({
  providedIn: 'root',
})
export class EMailService {
  private modalVisibleSubject = new Subject<boolean>();
  private shareByEMailSubject = new Subject<ShareByEMail>();
  private resolvePromise!: (value: boolean | ShareByEMail) => void;

  modalVisible$: Observable<boolean> = this.modalVisibleSubject.asObservable();
  shareByEMail$: Observable<ShareByEMail> = this.shareByEMailSubject.asObservable();

  showShareByEMail(shareByEMail: ShareByEMail): Promise<boolean | ShareByEMail> {
    this.shareByEMailSubject.next(shareByEMail);
    this.modalVisibleSubject.next(true);
    return new Promise<boolean | ShareByEMail>(resolve => {
      this.resolvePromise = resolve;
    });
  }

  accept(value: ShareByEMail): void {
    this.modalVisibleSubject.next(false);
    this.resolvePromise(value);
  }

  reject(): void {
    this.modalVisibleSubject.next(false);
    this.resolvePromise(false);
  }
}
