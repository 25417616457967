<footer class="bg-white px-4 py-2 md:px-6 mt-auto w-full print:hidden">
  <div class="container mx-auto flex flex-col lg:flex-row justify-between items-center">
    <div class="flex flex-col lg:flex-row items-center mb-4 lg:mb-0">
      <!-- <img src="assets/images/header-logo.png" alt="BizMatch Logo" class="h-8 mb-2 lg:mb-0 lg:mr-4" /> -->
      <a routerLink="/home" class="flex items-center space-x-3 rtl:space-x-reverse">
        <img src="assets/images/header-logo.png" class="h-8" class="h-8 mb-2 lg:mb-0 lg:mr-4" />
      </a>
      <p class="text-sm text-gray-600 text-center lg:text-left">© {{ currentYear }} Bizmatch All rights reserved.</p>
    </div>

    <div class="flex flex-col lg:flex-row items-center order-3 lg:order-2">
      <a class="text-sm text-blue-600 hover:underline hover:cursor-pointer mx-2" data-drawer-target="terms-of-use" data-drawer-show="terms-of-use" aria-controls="terms-of-use">Terms of use</a>
      <a class="text-sm text-blue-600 hover:underline hover:cursor-pointer mx-2" data-drawer-target="privacy" data-drawer-show="privacy" aria-controls="privacy">Privacy statement</a>
      <!-- <a class="text-sm text-blue-600 hover:underline hover:cursor-pointer mx-2" routerLink="/pricingOverview">Pricing</a> -->
    </div>

    <div class="flex flex-col lg:flex-row items-center order-2 lg:order-3">
      <div class="mb-4 lg:mb-0 lg:mr-6 text-center lg:text-right">
        <p class="text-sm text-gray-600 mb-1 lg:mb-2">BizMatch, Inc., 1001 Blucher Street, Corpus</p>
        <p class="text-sm text-gray-600">Christi, Texas 78401</p>
      </div>

      <div class="mb-4 lg:mb-0 flex flex-col items-center lg:items-end">
        <a class="text-sm text-gray-600 mb-1 lg:mb-2 hover:text-blue-600 w-full"> <i class="fas fa-phone-alt mr-2"></i>1-800-840-6025 </a>
        <a class="text-sm text-gray-600 hover:text-blue-600"> <i class="fas fa-envelope mr-2"></i>info&#64;bizmatch.net </a>
      </div>
    </div>
  </div>
</footer>
<div id="privacy" class="fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform -translate-x-full bg-white lg:w-1/3 w-96 dark:bg-gray-800" tabindex="-1" aria-labelledby="drawer-label">
  <h5 id="drawer-label" class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
    <svg class="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
    </svg>
    Privacy Statement
  </h5>
  <button
    type="button"
    data-drawer-hide="privacy"
    aria-controls="privacy"
    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
  >
    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <span class="sr-only">Close menu</span>
  </button>
  <section id="content" role="main">
    <article id="post-2" class="post-2 page type-page status-publish hentry pmpro-has-access">
      <section class="entry-content">
        <div class="container" style="padding: 3.5% 0 3.75% 0 !important">
          <p>
            <strong>Privacy Policy</strong><br />
            We are committed to protecting your privacy. We have established this statement as a testament to our commitment to your privacy.
          </p>
          <p>This Privacy Policy relates to the use of any personal information you provide to us through this websites.</p>
          <p>
            By accepting the Privacy Policy during registration or the sending of an enquiry, you expressly consent to our collection, storage, use and disclosure of your personal information as described in this Privacy
            Policy.
          </p>
          <p>
            We may update our Privacy Policy from time to time. Our Privacy Policy was last updated in Febuary 2018 and is effective upon acceptance for new users. By continuing to use our websites or otherwise
            continuing to deal with us, you accept this Privacy Policy.
          </p>
          <p>
            <strong>Collection of personal information</strong><br />
            Anyone can browse our websites without revealing any personally identifiable information.
          </p>
          <p>However, should you wish to contact a business for sale, a franchise opportunity or an intermediary, we will require you to provide some personal information.</p>
          <p>Should you wish to advertise your services, your business (es) or your franchise opportunity, we will require you to provide some personal information.</p>
          <p>By providing personal information, you are consenting to the transfer and storage of that information on our servers located in the United States.</p>
          <p>We may collect and store the following personal information:</p>
          <p>
            Your name, email address, physical address, telephone numbers, and (depending on the service used), your business information, financial information, such as credit / payment card details;<br />
            transactional information based on your activities on the site; information that you disclose in a forum on any of our websites, feedback, correspondence through our websites, and correspondence sent to
            us;<br />
            other information from your interaction with our websites, services, content and advertising, including computer and connection information, statistics on page views, traffic to and from the sites, ad data,
            IP address and standard web log information;<br />
            supplemental information from third parties (for example, if you incur a debt, we will generally conduct a credit check by obtaining additional information about you from a credit bureau, as permitted by law;
            or if the information you provide cannot be verified,<br />
            we may ask you to send us additional information, or to answer additional questions online to help verify your information).
          </p>
          <p>
            <strong>How we use your information</strong><br />
            The primary reason we collect your personal information is to improve the services we deliver to you through our website. By registering or sending an enquiry through our website, you agree that we may use
            your personal information to:<br />
            provide the services and customer support you request;<br />
            connect you with relevant parties:<br />
            If you are a buyer we will pass some or all of your details on to the seller / intermediary along with any message you have typed. This allows the seller to contact you in order to pursue a possible sale of a
            business;<br />
            If you are a seller / intermediary, we will disclose your details where you have given us permission to do so;<br />
            resolve disputes, collect fees, and troubleshoot problems;<br />
            prevent potentially prohibited or illegal activities, and enforce our Terms and Conditions;<br />
            customize, measure and improve our services, conduct internal market research, provide content and advertising;<br />
            tell you about other Biz-Match products and services, target marketing, send you service updates, and promotional offers based on your communication preferences.
          </p>
          <p>
            <strong>Our disclosure of your information</strong><br />
            We may disclose personal information to respond to legal requirements, enforce our policies, respond to claims that a listing or other content infringes the rights of others, or protect anyone’s rights,
            property, or safety.
          </p>
          <p>
            We may also share your personal information with<br />
            When you select to register an account as a business buyer, you provide your personal details and we will pass this on to a seller of a business or franchise when you request more information.
          </p>
          <p>
            When you select to register an account as a business broker or seller on the site, we provide a public platform on which to establish your business profile. This profile consists of pertinent facts about your
            business along with your personal information; namely, the contact information you provide to facilitate contact between you and other users’ of the site. Direct email addresses and telephone numbers will not
            be publicly displayed unless you specifically include it on your profile.
          </p>
          <p>
            The information a user includes within the forums provided on the site is publicly available to other users’ of the site. Please be aware that any personal information you elect to provide in a public forum
            may be used to send you unsolicited messages; we are not responsible for the personal information a user elects to disclose within their public profile, or in the private communications that users’ engage in
            on the site.
          </p>
          <p>
            We post testimonials on the site obtained from users’. These testimonials may include the name, city, state or region and business of the user. We obtain permission from our users’ prior to posting their
            testimonials on the site. We are not responsible for any personal information a user selects to include within their testimonial.
          </p>
          <p>
            When you elect to email a friend about the site, or a particular business, we request the third party’s email address to send this one time email. We do not share this information with any third parties for
            their promotional purposes and only store the information to gauge the effectiveness of our referral program.
          </p>
          <p>We may share your personal information with our service providers where necessary. We employ the services of a payment processor to fulfil payment for services purchased on the site.</p>
          <p>
            We works with a number of partners or affiliates, where we provide marketing services for these companies. These third party agents collect your personal information to facilitate your service request and the
            information submitted here is governed by their privacy policy.
          </p>
          <p>
            <strong>Masking Policy</strong><br />
            In some cases, where the third party agent collects your information, the affiliate portal may appear within a BizMatch.net frame. It is presented as a BizMatch.net page for a streamlined user interface
            however the data collected on such pages is governed by the third party agent’s privacy policy.
          </p>
          <p>
            <strong>Legal Disclosure</strong><br />
            In certain circumstances, we may be legally required to disclose information collected on the site to law enforcement, government agencies or other third parties. We reserve the right to disclose information
            to our service providers and to law enforcement or government agencies where a formal request such as in response to a court order, subpoena or judicial proceeding is made. Where we believe in good faith that
            disclosure of information is necessary to prevent imminent physical or financial harm, or loss, or in protecting against illegal activity on the site, we reserve to disclose information.
          </p>
          <p>
            Should the company undergo the merger, acquisition or sale of some or all of its assets, your personal information may likely be a part of the transferred assets. In such an event, your personal information
            on the site, would be governed by this privacy statement; any changes to the privacy practices governing your information as a result of transfer would be relayed to you by means of a prominent notice on the
            Site, or by email.
          </p>
          <p>
            <strong>Using information from BizMatch.net website</strong><br />
            In certain cases, (where you are receiving contact details of buyers interested in your business opportunity or a business opportunity you represent), you must comply with data protection laws, and give other
            users a chance to remove themselves from your database and a chance to review what information you have collected about them.
          </p>
          <p>
            <strong>You agree to use BizMatch.net user information only for:</strong>
          </p>
          <p>
            BizMatch.net transaction-related purposes that are not unsolicited commercial messages;<br />
            using services offered through BizMatch.net, or<br />
            other purposes that a user expressly chooses.
          </p>
          <p>
            <strong>Marketing</strong><br />
            We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. Where you explicitly express your consent at the point of collection to receive
            offers from third party partners or affiliates, we will communicate to you on their behalf. We will not pass your information on.
          </p>
          <p>
            You will receive email marketing communications from us throughout the duration of your relationship with our websites. If you do not wish to receive marketing communications from us you may unsubscribe and /
            or change your preferences at any time by following instructions included within a communication or emailing Customer Services.
          </p>
          <p>If you have an account with one of our websites you can also log in and click the email preferences link to unsubscribe and / or change your preferences.</p>
          <p>
            Please note that we reserve the right to send all website users notifications and administrative emails where necessary which are considered a part of the service. Given that these messages aren’t promotional
            in nature, you will be unable to opt-out of them.
          </p>
          <p>
            <strong>Cookies</strong><br />
            A cookie is a small text file written to your hard drive that contains information about you. Cookies do not contain any personal information about users. Once you close your browser or log out of the
            website, the cookie simply terminates. We use cookies so that we can personalise your experience of our websites.
          </p>
          <p>
            If you set up your browser to reject the cookie, you may still use the website however; doing so may interfere with your use of some aspects of our websites. Some of our business partners use cookies on our
            site (for example, advertisers). We have no access to or control over these cookies.
          </p>
          <p>For more information about how BizMatch.net uses cookies please read our Cookie Policy.</p>
          <p>
            <strong>Spam, spyware or spoofing</strong><br />
            We and our users do not tolerate spam. Make sure to set your email preferences so we can communicate with you, as you prefer. Please add us to your safe senders list. To report spam or spoof emails, please
            contact us using the contact information provided in the Contact Us section of this privacy statement.
          </p>
          <p>
            You may not use our communication tools to send spam or otherwise send content that would breach our Terms and Conditions. We automatically scan and may manually filter messages to check for spam, viruses,
            phishing attacks and other malicious activity or illegal or prohibited content. We may also store these messages for back up purposes only.
          </p>
          <p>
            If you send an email to an email address that is not registered in our community, we do not permanently store that email or use that email address for any marketing purpose. We do not rent or sell these email
            addresses.
          </p>
          <p>
            <strong>Account protection</strong><br />
            Your password is the key to your account. Make sure this is stored safely. Use unique numbers, letters and special characters, and do not disclose your password to anyone. If you do share your password or
            your personal information with others, remember that you are responsible for all actions taken in the name of your account. If you lose control of your password, you may lose substantial control over your
            personal information and may be subject to legally binding actions taken on your behalf. Therefore, if your password has been compromised for any reason, you should immediately notify us and change your
            password.
          </p>
          <p>
            <strong>Accessing, reviewing and changing your personal information</strong><br />
            You can view and amend your personal information at any time by logging in to your account online. You must promptly update your personal information if it changes or is inaccurate.
          </p>
          <p>If at any time you wish to close your account, please contact Customer Services and instruct us to do so. We will process your request as soon as we can.</p>
          <p>You may also contact us at any time to find out what information we hold about you, what we do with it and ask us to update it for you.</p>
          <p>
            We do retain personal information from closed accounts to comply with law, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigations, enforce our Terms and
            Conditions, and take other actions otherwise permitted by law.
          </p>
          <p>
            <strong>Security</strong><br />
            Your information is stored on our servers located in the USA. We treat data as an asset that must be protected and use a variety of tools (encryption, passwords, physical security, etc.) to protect your
            personal information against unauthorized access and disclosure. However, no method of security is 100% effective and while we take every measure to protect your personal information, we make no guarantees of
            its absolute security.
          </p>
          <p>We employ the use of SSL encryption during the transmission of sensitive data across our websites.</p>
          <p>
            <strong>Third parties</strong><br />
            Except as otherwise expressly included in this Privacy Policy, this document addresses only the use and disclosure of information we collect from you. If you disclose your information to others, whether they
            are buyers or sellers on our websites or other sites throughout the internet, different rules may apply to their use or disclosure of the information you disclose to them. Dynamis does not control the privacy
            policies of third parties, and you are subject to the privacy policies of those third parties where applicable.
          </p>
          <p>We encourage you to ask questions before you disclose your personal information to others.</p>
          <p>
            <strong>General</strong><br />
            We may change this Privacy Policy from time to time as we add new products and applications, as we improve our current offerings, and as technologies and laws change. You can determine when this Privacy
            Policy was last revised by referring to the “Last Updated” legend at the top of this page.
          </p>
          <p>
            Any changes will become effective upon our posting of the revised Privacy Policy on our affected websites. We will provide notice to you if these changes are material and, where required by applicable law, we
            will obtain your consent. This notice may be provided by email, by posting notice of the changes on our affected websites or by other means, consistent with applicable laws.
          </p>
          <p>
            <strong>Contact Us</strong><br />
            If you have any questions or comments about our privacy policy, and you can’t find the answer to your question on our help pages, please contact us using this form or email support&#64;bizmatch.net, or write
            to us at BizMatch, 715 S. Tanahua, Corpus Christi, TX 78401.)
          </p>
        </div>
      </section>
    </article>
  </section>
</div>
<div id="terms-of-use" class="fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform -translate-x-full bg-white lg:w-1/3 w-96 dark:bg-gray-800" tabindex="-1" aria-labelledby="drawer-label">
  <h5 id="drawer-label" class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
    <svg class="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
    </svg>
    Terms of use
  </h5>
  <button
    type="button"
    data-drawer-hide="terms-of-use"
    aria-controls="terms-of-use"
    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
  >
    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <span class="sr-only">Close menu</span>
  </button>
  <section id="content" role="main">
    <article id="post-1" class="post-1 page type-page status-publish hentry pmpro-has-access">
      <section class="entry-content">
        <div class="container" style="padding: 3.5% 0 3.75% 0 !important">
          <b><span>AGREEMENT BETWEEN USER AND BizMatch</span></b
          ><span
            ><p></p>
            <p><span>The BizMatch Web Site is comprised of various Web pages operated by BizMatch.</span><span></span></p>
            <p>
              <span
                >The BizMatch Web Site is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein. Your use of the BizMatch Web Site constitutes your
                agreement to all such terms, conditions, and notices.</span
              ><span></span>
            </p>
            <p>
              <b><span>MODIFICATION OF THESE TERMS OF USE</span></b
              ><span></span>
            </p>
            <p>
              <span
                >BizMatch reserves the right to change the terms, conditions, and notices under which the BizMatch Web Site is offered, including but not limited to the charges associated with the use of the BizMatch Web
                Site.</span
              ><span></span>
            </p>
            <p>
              <b><span>LINKS TO THIRD PARTY SITES</span></b
              ><span></span>
            </p>
            <p>
              <span
                >The BizMatch Web Site may contain links to other Web Sites ("Linked Sites"). The Linked Sites are not under the control of BizMatch and BizMatch is not responsible for the contents of any Linked Site,
                including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. BizMatch is not responsible for webcasting or any other form of transmission received from any
                Linked Site. BizMatch is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by BizMatch of the site or any association with its operators.</span
              ><span></span>
            </p>
            <p>
              <b><span>NO UNLAWFUL OR PROHIBITED USE</span></b
              ><span></span>
            </p>
            <p>
              <span
                >As a condition of your use of the BizMatch Web Site, you warrant to BizMatch that you will not use the BizMatch Web Site for any purpose that is unlawful or prohibited by these terms, conditions, and
                notices. You may not use the BizMatch Web Site in any manner which could damage, disable, overburden, or impair the BizMatch Web Site or interfere with any other party’s use and enjoyment of the BizMatch
                Web Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the BizMatch Web Sites.</span
              ><span></span>
            </p>
            <p>
              <b><span>USE OF COMMUNICATION SERVICES</span></b
              ><span></span>
            </p>
            <p>
              <span
                >The BizMatch Web Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable
                you to communicate with the public at large or with a group (collectively, "Communication Services"), you agree to use the Communication Services only to post, send and receive messages and material that
                are proper and related to the particular Communication Service. By way of example, and not as a limitation, you agree that when using a Communication Service, you will not:</span
              ><span></span>
            </p>
            <p>&nbsp;</p>
            <p class="MsoNormal"></p>
            <p>
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others.</span><span></span>
            </p>
            <p>&nbsp;</p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span
                >Upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all
                necessary consents.</span
              >
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another’s computer.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Conduct or forward surveys, contests, pyramid schemes or chain letters.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span
                >Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is
                uploaded.</span
              >
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Restrict or inhibit any other user from using and enjoying the Communication Services.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Violate any code of conduct or other guidelines which may be applicable for any particular Communication Service.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Harvest or otherwise collect information about others, including e-mail addresses, without their consent.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Violate any applicable laws or regulations.</span>
            </p>
            <p class="MsoNormal">
              <span
                >BizMatch has no obligation to monitor the Communication Services. However, BizMatch reserves the right to review materials posted to a Communication Service and to remove any materials in its sole
                discretion. BizMatch reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.</span
              ><span></span>
            </p>
            <p>
              <span
                >BizMatch reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove
                any information or materials, in whole or in part, in BizMatch’s sole discretion.</span
              ><span></span>
            </p>
            <p>
              <span
                >Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. BizMatch does not control or endorse the content, messages or
                information found in any Communication Service and, therefore, BizMatch specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in
                any Communication Service. Managers and hosts are not authorized BizMatch spokespersons, and their views do not necessarily reflect those of BizMatch.</span
              ><span></span>
            </p>
            <p>
              <span
                >Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you download the
                materials.</span
              ><span></span>
            </p>
            <p>
              <b><span>MATERIALS PROVIDED TO BizMatch OR POSTED AT ANY BizMatch WEB SITE</span></b
              ><span></span>
            </p>
            <p>
              <span
                >BizMatch does not claim ownership of the materials you provide to BizMatch (including feedback and suggestions) or post, upload, input or submit to any BizMatch Web Site or its associated services
                (collectively "Submissions"). However, by posting, uploading, inputting, providing or submitting your Submission you are granting BizMatch, its affiliated companies and necessary sublicensees permission
                to use your Submission in connection with the operation of their Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform,
                reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission.</span
              ><span></span>
            </p>
            <p>
              <span
                >No compensation will be paid with respect to the use of your Submission, as provided herein. BizMatch is under no obligation to post or use any Submission you may provide and may remove any Submission at
                any time in BizMatch’s sole discretion.</span
              ><span></span>
            </p>
            <p>
              <span
                >By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section
                including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.</span
              ><span></span>
            </p>
            <p>
              <b><span>LIABILITY DISCLAIMER</span></b
              ><span></span>
            </p>
            <p>
              <span
                >THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE BizMatch WEB SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                INFORMATION HEREIN. BizMatch AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE BizMatch WEB SITE AT ANY TIME. ADVICE RECEIVED VIA THE BizMatch WEB SITE SHOULD NOT BE RELIED UPON FOR
                PERSONAL, MEDICAL, LEGAL OR FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION.</span
              ><span></span>
            </p>
            <p>
              <span
                >BizMatch AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
                CONTAINED ON THE BizMatch WEB SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
                WARRANTY OR CONDITION OF ANY KIND. BizMatch AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING
                ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</span
              ><span></span>
            </p>
            <p>
              <span
                >TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL BizMatch AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
                WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE BizMatch WEB SITE, WITH THE DELAY OR INABILITY
                TO USE THE BizMatch WEB SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE BizMatch
                WEB SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE BizMatch WEB SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF BizMatch OR ANY OF ITS SUPPLIERS HAS BEEN
                ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY
                TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE BizMatch WEB SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE BizMatch WEB SITE.</span
              ><span></span>
            </p>
            <p><span>SERVICE CONTACT : info&#64;bizmatch.net</span><span></span></p>
            <p>
              <b><span>TERMINATION/ACCESS RESTRICTION</span></b
              ><span></span>
            </p>
            <p>
              <span
                >BizMatch reserves the right, in its sole discretion, to terminate your access to the BizMatch Web Site and the related services or any portion thereof at any time, without notice. GENERAL To the maximum
                extent permitted by law, this agreement is governed by the laws of the State of Washington, U.S.A. and you hereby consent to the exclusive jurisdiction and venue of courts in King County, Washington,
                U.S.A. in all disputes arising out of or relating to the use of the BizMatch Web Site. Use of the BizMatch Web Site is unauthorized in any jurisdiction that does not give effect to all provisions of these
                terms and conditions, including without limitation this paragraph. You agree that no joint venture, partnership, employment, or agency relationship exists between you and BizMatch as a result of this
                agreement or use of the BizMatch Web Site. BizMatch’s performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of BizMatch’s
                right to comply with governmental, court and law enforcement requests or requirements relating to your use of the BizMatch Web Site or information provided to or gathered by BizMatch with respect to such
                use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth
                above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the
                agreement shall continue in effect. Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and BizMatch with respect to the BizMatch Web Site and it supersedes
                all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and BizMatch with respect to the BizMatch Web Site. A printed version of this agreement and
                of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent an d subject to the same conditions as
                other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be drawn up in English.</span
              ><span></span>
            </p>
            <p>
              <b><span>COPYRIGHT AND TRADEMARK NOTICES:</span></b
              ><span></span>
            </p>
            <p><span>All contents of the BizMatch Web Site are: Copyright 2011 by Bizmatch Business Solutions and/or its suppliers. All rights reserved.</span><span></span></p>
            <p>
              <b><span>TRADEMARKS</span></b
              ><span></span>
            </p>
            <p><span>The names of actual companies and products mentioned herein may be the trademarks of their respective owners.</span><span></span></p>
            <p>
              <span
                >The example companies, organizations, products, people and events depicted herein are fictitious. No association with any real company, organization, product, person, or event is intended or should be
                inferred.</span
              ><span></span>
            </p>
            <p><span>Any rights not expressly granted herein are reserved.</span><span></span></p>
            <p>
              <b><span>NOTICES AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT</span></b
              ><span></span>
            </p>
            <p>
              <span
                >Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of claimed copyright infringement under United States copyright law should be sent to Service Provider’s Designated Agent. ALL
                INQUIRIES NOT RELEVANT TO THE FOLLOWING PROCEDURE WILL RECEIVE NO RESPONSE. See Notice and Procedure for Making Claims of Copyright Infringement.</span
              ><span><br /> </span>
            </p>
            <p class="MsoNormal">&nbsp;</p>
            <p class="MsoNormal">
              We reserve the right to update or revise these Terms of Use at any time without&nbsp;notice. Please check the Terms of Use periodically for changes. The revised&nbsp;terms will be effective immediately as
              soon as they are posted on the WebSite&nbsp;and by continuing to use the Site you agree to be bound by the revised terms<span><br /> </span></p
          ></span>
        </div>
      </section>
    </article>
  </section>
</div>
<!-- <p-sidebar [(visible)]="privacyVisible" styleClass="w-30rem" position="right">
  <ng-template pTemplate="header">
    <span class="font-semibold text-xl">Privacy Statement</span>
  </ng-template>
  <section id="content" role="main">
    <article id="post-2" class="post-2 page type-page status-publish hentry pmpro-has-access">
      <section class="entry-content">
        <div class="container" style="padding: 3.5% 0 3.75% 0 !important">
          <p>
            <strong>Privacy Policy</strong><br />
            We are committed to protecting your privacy. We have established this statement as a testament to our commitment to your privacy.
          </p>
          <p>This Privacy Policy relates to the use of any personal information you provide to us through this websites.</p>
          <p>
            By accepting the Privacy Policy during registration or the sending of an enquiry, you expressly consent to our collection, storage, use and disclosure of your personal information as described in this Privacy
            Policy.
          </p>
          <p>
            We may update our Privacy Policy from time to time. Our Privacy Policy was last updated in Febuary 2018 and is effective upon acceptance for new users. By continuing to use our websites or otherwise
            continuing to deal with us, you accept this Privacy Policy.
          </p>
          <p>
            <strong>Collection of personal information</strong><br />
            Anyone can browse our websites without revealing any personally identifiable information.
          </p>
          <p>However, should you wish to contact a business for sale, a franchise opportunity or an intermediary, we will require you to provide some personal information.</p>
          <p>Should you wish to advertise your services, your business (es) or your franchise opportunity, we will require you to provide some personal information.</p>
          <p>By providing personal information, you are consenting to the transfer and storage of that information on our servers located in the United States.</p>
          <p>We may collect and store the following personal information:</p>
          <p>
            Your name, email address, physical address, telephone numbers, and (depending on the service used), your business information, financial information, such as credit / payment card details;<br />
            transactional information based on your activities on the site; information that you disclose in a forum on any of our websites, feedback, correspondence through our websites, and correspondence sent to
            us;<br />
            other information from your interaction with our websites, services, content and advertising, including computer and connection information, statistics on page views, traffic to and from the sites, ad data,
            IP address and standard web log information;<br />
            supplemental information from third parties (for example, if you incur a debt, we will generally conduct a credit check by obtaining additional information about you from a credit bureau, as permitted by law;
            or if the information you provide cannot be verified,<br />
            we may ask you to send us additional information, or to answer additional questions online to help verify your information).
          </p>
          <p>
            <strong>How we use your information</strong><br />
            The primary reason we collect your personal information is to improve the services we deliver to you through our website. By registering or sending an enquiry through our website, you agree that we may use
            your personal information to:<br />
            provide the services and customer support you request;<br />
            connect you with relevant parties:<br />
            If you are a buyer we will pass some or all of your details on to the seller / intermediary along with any message you have typed. This allows the seller to contact you in order to pursue a possible sale of a
            business;<br />
            If you are a seller / intermediary, we will disclose your details where you have given us permission to do so;<br />
            resolve disputes, collect fees, and troubleshoot problems;<br />
            prevent potentially prohibited or illegal activities, and enforce our Terms and Conditions;<br />
            customize, measure and improve our services, conduct internal market research, provide content and advertising;<br />
            tell you about other Biz-Match products and services, target marketing, send you service updates, and promotional offers based on your communication preferences.
          </p>
          <p>
            <strong>Our disclosure of your information</strong><br />
            We may disclose personal information to respond to legal requirements, enforce our policies, respond to claims that a listing or other content infringes the rights of others, or protect anyone’s rights,
            property, or safety.
          </p>
          <p>
            We may also share your personal information with<br />
            When you select to register an account as a business buyer, you provide your personal details and we will pass this on to a seller of a business or franchise when you request more information.
          </p>
          <p>
            When you select to register an account as a business broker or seller on the site, we provide a public platform on which to establish your business profile. This profile consists of pertinent facts about your
            business along with your personal information; namely, the contact information you provide to facilitate contact between you and other users’ of the site. Direct email addresses and telephone numbers will not
            be publicly displayed unless you specifically include it on your profile.
          </p>
          <p>
            The information a user includes within the forums provided on the site is publicly available to other users’ of the site. Please be aware that any personal information you elect to provide in a public forum
            may be used to send you unsolicited messages; we are not responsible for the personal information a user elects to disclose within their public profile, or in the private communications that users’ engage in
            on the site.
          </p>
          <p>
            We post testimonials on the site obtained from users’. These testimonials may include the name, city, state or region and business of the user. We obtain permission from our users’ prior to posting their
            testimonials on the site. We are not responsible for any personal information a user selects to include within their testimonial.
          </p>
          <p>
            When you elect to email a friend about the site, or a particular business, we request the third party’s email address to send this one time email. We do not share this information with any third parties for
            their promotional purposes and only store the information to gauge the effectiveness of our referral program.
          </p>
          <p>We may share your personal information with our service providers where necessary. We employ the services of a payment processor to fulfil payment for services purchased on the site.</p>
          <p>
            We works with a number of partners or affiliates, where we provide marketing services for these companies. These third party agents collect your personal information to facilitate your service request and the
            information submitted here is governed by their privacy policy.
          </p>
          <p>
            <strong>Masking Policy</strong><br />
            In some cases, where the third party agent collects your information, the affiliate portal may appear within a BizMatch.net frame. It is presented as a BizMatch.net page for a streamlined user interface
            however the data collected on such pages is governed by the third party agent’s privacy policy.
          </p>
          <p>
            <strong>Legal Disclosure</strong><br />
            In certain circumstances, we may be legally required to disclose information collected on the site to law enforcement, government agencies or other third parties. We reserve the right to disclose information
            to our service providers and to law enforcement or government agencies where a formal request such as in response to a court order, subpoena or judicial proceeding is made. Where we believe in good faith that
            disclosure of information is necessary to prevent imminent physical or financial harm, or loss, or in protecting against illegal activity on the site, we reserve to disclose information.
          </p>
          <p>
            Should the company undergo the merger, acquisition or sale of some or all of its assets, your personal information may likely be a part of the transferred assets. In such an event, your personal information
            on the site, would be governed by this privacy statement; any changes to the privacy practices governing your information as a result of transfer would be relayed to you by means of a prominent notice on the
            Site, or by email.
          </p>
          <p>
            <strong>Using information from BizMatch.net website</strong><br />
            In certain cases, (where you are receiving contact details of buyers interested in your business opportunity or a business opportunity you represent), you must comply with data protection laws, and give other
            users a chance to remove themselves from your database and a chance to review what information you have collected about them.
          </p>
          <p>
            <strong>You agree to use BizMatch.net user information only for:</strong>
          </p>
          <p>
            BizMatch.net transaction-related purposes that are not unsolicited commercial messages;<br />
            using services offered through BizMatch.net, or<br />
            other purposes that a user expressly chooses.
          </p>
          <p>
            <strong>Marketing</strong><br />
            We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. Where you explicitly express your consent at the point of collection to receive
            offers from third party partners or affiliates, we will communicate to you on their behalf. We will not pass your information on.
          </p>
          <p>
            You will receive email marketing communications from us throughout the duration of your relationship with our websites. If you do not wish to receive marketing communications from us you may unsubscribe and /
            or change your preferences at any time by following instructions included within a communication or emailing Customer Services.
          </p>
          <p>If you have an account with one of our websites you can also log in and click the email preferences link to unsubscribe and / or change your preferences.</p>
          <p>
            Please note that we reserve the right to send all website users notifications and administrative emails where necessary which are considered a part of the service. Given that these messages aren’t promotional
            in nature, you will be unable to opt-out of them.
          </p>
          <p>
            <strong>Cookies</strong><br />
            A cookie is a small text file written to your hard drive that contains information about you. Cookies do not contain any personal information about users. Once you close your browser or log out of the
            website, the cookie simply terminates. We use cookies so that we can personalise your experience of our websites.
          </p>
          <p>
            If you set up your browser to reject the cookie, you may still use the website however; doing so may interfere with your use of some aspects of our websites. Some of our business partners use cookies on our
            site (for example, advertisers). We have no access to or control over these cookies.
          </p>
          <p>For more information about how BizMatch.net uses cookies please read our Cookie Policy.</p>
          <p>
            <strong>Spam, spyware or spoofing</strong><br />
            We and our users do not tolerate spam. Make sure to set your email preferences so we can communicate with you, as you prefer. Please add us to your safe senders list. To report spam or spoof emails, please
            contact us using the contact information provided in the Contact Us section of this privacy statement.
          </p>
          <p>
            You may not use our communication tools to send spam or otherwise send content that would breach our Terms and Conditions. We automatically scan and may manually filter messages to check for spam, viruses,
            phishing attacks and other malicious activity or illegal or prohibited content. We may also store these messages for back up purposes only.
          </p>
          <p>
            If you send an email to an email address that is not registered in our community, we do not permanently store that email or use that email address for any marketing purpose. We do not rent or sell these email
            addresses.
          </p>
          <p>
            <strong>Account protection</strong><br />
            Your password is the key to your account. Make sure this is stored safely. Use unique numbers, letters and special characters, and do not disclose your password to anyone. If you do share your password or
            your personal information with others, remember that you are responsible for all actions taken in the name of your account. If you lose control of your password, you may lose substantial control over your
            personal information and may be subject to legally binding actions taken on your behalf. Therefore, if your password has been compromised for any reason, you should immediately notify us and change your
            password.
          </p>
          <p>
            <strong>Accessing, reviewing and changing your personal information</strong><br />
            You can view and amend your personal information at any time by logging in to your account online. You must promptly update your personal information if it changes or is inaccurate.
          </p>
          <p>If at any time you wish to close your account, please contact Customer Services and instruct us to do so. We will process your request as soon as we can.</p>
          <p>You may also contact us at any time to find out what information we hold about you, what we do with it and ask us to update it for you.</p>
          <p>
            We do retain personal information from closed accounts to comply with law, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigations, enforce our Terms and
            Conditions, and take other actions otherwise permitted by law.
          </p>
          <p>
            <strong>Security</strong><br />
            Your information is stored on our servers located in the USA. We treat data as an asset that must be protected and use a variety of tools (encryption, passwords, physical security, etc.) to protect your
            personal information against unauthorized access and disclosure. However, no method of security is 100% effective and while we take every measure to protect your personal information, we make no guarantees of
            its absolute security.
          </p>
          <p>We employ the use of SSL encryption during the transmission of sensitive data across our websites.</p>
          <p>
            <strong>Third parties</strong><br />
            Except as otherwise expressly included in this Privacy Policy, this document addresses only the use and disclosure of information we collect from you. If you disclose your information to others, whether they
            are buyers or sellers on our websites or other sites throughout the internet, different rules may apply to their use or disclosure of the information you disclose to them. Dynamis does not control the privacy
            policies of third parties, and you are subject to the privacy policies of those third parties where applicable.
          </p>
          <p>We encourage you to ask questions before you disclose your personal information to others.</p>
          <p>
            <strong>General</strong><br />
            We may change this Privacy Policy from time to time as we add new products and applications, as we improve our current offerings, and as technologies and laws change. You can determine when this Privacy
            Policy was last revised by referring to the “Last Updated” legend at the top of this page.
          </p>
          <p>
            Any changes will become effective upon our posting of the revised Privacy Policy on our affected websites. We will provide notice to you if these changes are material and, where required by applicable law, we
            will obtain your consent. This notice may be provided by email, by posting notice of the changes on our affected websites or by other means, consistent with applicable laws.
          </p>
          <p>
            <strong>Contact Us</strong><br />
            If you have any questions or comments about our privacy policy, and you can’t find the answer to your question on our help pages, please contact us using this form or email support&#64;bizmatch.net, or write
            to us at BizMatch, 715 S. Tanahua, Corpus Christi, TX 78401.)
          </p>
        </div>
      </section>
    </article>
  </section>
</p-sidebar> -->

<!-- <p-sidebar [(visible)]="termsVisible" styleClass="w-30rem" position="right">
  <ng-template pTemplate="header">
    <span class="font-semibold text-xl">Terms of use</span>
  </ng-template>
  <section id="content" role="main">
    <article id="post-1" class="post-1 page type-page status-publish hentry pmpro-has-access">
      <section class="entry-content">
        <div class="container" style="padding: 3.5% 0 3.75% 0 !important">
          <b><span>AGREEMENT BETWEEN USER AND BizMatch</span></b
          ><span
            ><p></p>
            <p><span>The BizMatch Web Site is comprised of various Web pages operated by BizMatch.</span><span></span></p>
            <p>
              <span
                >The BizMatch Web Site is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein. Your use of the BizMatch Web Site constitutes your
                agreement to all such terms, conditions, and notices.</span
              ><span></span>
            </p>
            <p>
              <b><span>MODIFICATION OF THESE TERMS OF USE</span></b
              ><span></span>
            </p>
            <p>
              <span
                >BizMatch reserves the right to change the terms, conditions, and notices under which the BizMatch Web Site is offered, including but not limited to the charges associated with the use of the BizMatch Web
                Site.</span
              ><span></span>
            </p>
            <p>
              <b><span>LINKS TO THIRD PARTY SITES</span></b
              ><span></span>
            </p>
            <p>
              <span
                >The BizMatch Web Site may contain links to other Web Sites ("Linked Sites"). The Linked Sites are not under the control of BizMatch and BizMatch is not responsible for the contents of any Linked Site,
                including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. BizMatch is not responsible for webcasting or any other form of transmission received from any
                Linked Site. BizMatch is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by BizMatch of the site or any association with its operators.</span
              ><span></span>
            </p>
            <p>
              <b><span>NO UNLAWFUL OR PROHIBITED USE</span></b
              ><span></span>
            </p>
            <p>
              <span
                >As a condition of your use of the BizMatch Web Site, you warrant to BizMatch that you will not use the BizMatch Web Site for any purpose that is unlawful or prohibited by these terms, conditions, and
                notices. You may not use the BizMatch Web Site in any manner which could damage, disable, overburden, or impair the BizMatch Web Site or interfere with any other party’s use and enjoyment of the BizMatch
                Web Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the BizMatch Web Sites.</span
              ><span></span>
            </p>
            <p>
              <b><span>USE OF COMMUNICATION SERVICES</span></b
              ><span></span>
            </p>
            <p>
              <span
                >The BizMatch Web Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable
                you to communicate with the public at large or with a group (collectively, "Communication Services"), you agree to use the Communication Services only to post, send and receive messages and material that
                are proper and related to the particular Communication Service. By way of example, and not as a limitation, you agree that when using a Communication Service, you will not:</span
              ><span></span>
            </p>
            <p>&nbsp;</p>
            <p class="MsoNormal"></p>
            <p>
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others.</span><span></span>
            </p>
            <p>&nbsp;</p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span
                >Upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all
                necessary consents.</span
              >
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another’s computer.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Conduct or forward surveys, contests, pyramid schemes or chain letters.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span
                >Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is
                uploaded.</span
              >
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Restrict or inhibit any other user from using and enjoying the Communication Services.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Violate any code of conduct or other guidelines which may be applicable for any particular Communication Service.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Harvest or otherwise collect information about others, including e-mail addresses, without their consent.</span>
            </p>
            <p class="MsoNormal">
              <span
                ><span>§<span>&nbsp; </span></span></span
              ><span>Violate any applicable laws or regulations.</span>
            </p>
            <p class="MsoNormal">
              <span
                >BizMatch has no obligation to monitor the Communication Services. However, BizMatch reserves the right to review materials posted to a Communication Service and to remove any materials in its sole
                discretion. BizMatch reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.</span
              ><span></span>
            </p>
            <p>
              <span
                >BizMatch reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove
                any information or materials, in whole or in part, in BizMatch’s sole discretion.</span
              ><span></span>
            </p>
            <p>
              <span
                >Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. BizMatch does not control or endorse the content, messages or
                information found in any Communication Service and, therefore, BizMatch specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in
                any Communication Service. Managers and hosts are not authorized BizMatch spokespersons, and their views do not necessarily reflect those of BizMatch.</span
              ><span></span>
            </p>
            <p>
              <span
                >Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you download the
                materials.</span
              ><span></span>
            </p>
            <p>
              <b><span>MATERIALS PROVIDED TO BizMatch OR POSTED AT ANY BizMatch WEB SITE</span></b
              ><span></span>
            </p>
            <p>
              <span
                >BizMatch does not claim ownership of the materials you provide to BizMatch (including feedback and suggestions) or post, upload, input or submit to any BizMatch Web Site or its associated services
                (collectively "Submissions"). However, by posting, uploading, inputting, providing or submitting your Submission you are granting BizMatch, its affiliated companies and necessary sublicensees permission
                to use your Submission in connection with the operation of their Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform,
                reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission.</span
              ><span></span>
            </p>
            <p>
              <span
                >No compensation will be paid with respect to the use of your Submission, as provided herein. BizMatch is under no obligation to post or use any Submission you may provide and may remove any Submission at
                any time in BizMatch’s sole discretion.</span
              ><span></span>
            </p>
            <p>
              <span
                >By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section
                including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.</span
              ><span></span>
            </p>
            <p>
              <b><span>LIABILITY DISCLAIMER</span></b
              ><span></span>
            </p>
            <p>
              <span
                >THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE BizMatch WEB SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                INFORMATION HEREIN. BizMatch AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE BizMatch WEB SITE AT ANY TIME. ADVICE RECEIVED VIA THE BizMatch WEB SITE SHOULD NOT BE RELIED UPON FOR
                PERSONAL, MEDICAL, LEGAL OR FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION.</span
              ><span></span>
            </p>
            <p>
              <span
                >BizMatch AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
                CONTAINED ON THE BizMatch WEB SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
                WARRANTY OR CONDITION OF ANY KIND. BizMatch AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING
                ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</span
              ><span></span>
            </p>
            <p>
              <span
                >TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL BizMatch AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
                WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE BizMatch WEB SITE, WITH THE DELAY OR INABILITY
                TO USE THE BizMatch WEB SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE BizMatch
                WEB SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE BizMatch WEB SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF BizMatch OR ANY OF ITS SUPPLIERS HAS BEEN
                ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY
                TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE BizMatch WEB SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE BizMatch WEB SITE.</span
              ><span></span>
            </p>
            <p><span>SERVICE CONTACT : info&#64;bizmatch.net</span><span></span></p>
            <p>
              <b><span>TERMINATION/ACCESS RESTRICTION</span></b
              ><span></span>
            </p>
            <p>
              <span
                >BizMatch reserves the right, in its sole discretion, to terminate your access to the BizMatch Web Site and the related services or any portion thereof at any time, without notice. GENERAL To the maximum
                extent permitted by law, this agreement is governed by the laws of the State of Washington, U.S.A. and you hereby consent to the exclusive jurisdiction and venue of courts in King County, Washington,
                U.S.A. in all disputes arising out of or relating to the use of the BizMatch Web Site. Use of the BizMatch Web Site is unauthorized in any jurisdiction that does not give effect to all provisions of these
                terms and conditions, including without limitation this paragraph. You agree that no joint venture, partnership, employment, or agency relationship exists between you and BizMatch as a result of this
                agreement or use of the BizMatch Web Site. BizMatch’s performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of BizMatch’s
                right to comply with governmental, court and law enforcement requests or requirements relating to your use of the BizMatch Web Site or information provided to or gathered by BizMatch with respect to such
                use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth
                above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the
                agreement shall continue in effect. Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and BizMatch with respect to the BizMatch Web Site and it supersedes
                all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and BizMatch with respect to the BizMatch Web Site. A printed version of this agreement and
                of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent an d subject to the same conditions as
                other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be drawn up in English.</span
              ><span></span>
            </p>
            <p>
              <b><span>COPYRIGHT AND TRADEMARK NOTICES:</span></b
              ><span></span>
            </p>
            <p><span>All contents of the BizMatch Web Site are: Copyright 2011 by Bizmatch Business Solutions and/or its suppliers. All rights reserved.</span><span></span></p>
            <p>
              <b><span>TRADEMARKS</span></b
              ><span></span>
            </p>
            <p><span>The names of actual companies and products mentioned herein may be the trademarks of their respective owners.</span><span></span></p>
            <p>
              <span
                >The example companies, organizations, products, people and events depicted herein are fictitious. No association with any real company, organization, product, person, or event is intended or should be
                inferred.</span
              ><span></span>
            </p>
            <p><span>Any rights not expressly granted herein are reserved.</span><span></span></p>
            <p>
              <b><span>NOTICES AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT</span></b
              ><span></span>
            </p>
            <p>
              <span
                >Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of claimed copyright infringement under United States copyright law should be sent to Service Provider’s Designated Agent. ALL
                INQUIRIES NOT RELEVANT TO THE FOLLOWING PROCEDURE WILL RECEIVE NO RESPONSE. See Notice and Procedure for Making Claims of Copyright Infringement.</span
              ><span
                ><br />
                </span
              >
            </p>
            <p class="MsoNormal">&nbsp;</p>
            <p class="MsoNormal">
              We reserve the right to update or revise these Terms of Use at any time without&nbsp;notice. Please check the Terms of Use periodically for changes. The revised&nbsp;terms will be effective immediately as
              soon as they are posted on the WebSite&nbsp;and by continuing to use the Site you agree to be bound by the revised terms<span
                ><br />
                </span
              >
            </p></span
          >
        </div>
      </section>
    </article>
  </section>
</p-sidebar> -->
