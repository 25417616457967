import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { CommercialPropertyListing } from '../../../../../../bizmatch-server/src/models/db.model';
import { CommercialPropertyListingCriteria, LISTINGS_PER_PAGE, ResponseCommercialPropertyListingArray } from '../../../../../../bizmatch-server/src/models/main.model';
import { environment } from '../../../../environments/environment';
import { PaginatorComponent } from '../../../components/paginator/paginator.component';
import { ModalService } from '../../../components/search-modal/modal.service';
import { CriteriaChangeService } from '../../../services/criteria-change.service';
import { ImageService } from '../../../services/image.service';
import { ListingsService } from '../../../services/listings.service';
import { SearchService } from '../../../services/search.service';
import { SelectOptionsService } from '../../../services/select-options.service';
import { assignProperties, getCriteriaProxy, resetCommercialPropertyListingCriteria } from '../../../utils/utils';
@UntilDestroy()
@Component({
  selector: 'app-commercial-property-listings',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, PaginatorComponent],
  templateUrl: './commercial-property-listings.component.html',
  styleUrls: ['./commercial-property-listings.component.scss', '../../pages.scss'],
})
export class CommercialPropertyListingsComponent {
  environment = environment;
  listings: Array<CommercialPropertyListing>;
  filteredListings: Array<CommercialPropertyListing>;
  criteria: CommercialPropertyListingCriteria;
  realEstateChecked: boolean;
  first: number = 0;
  rows: number = 12;
  maxPrice: string;
  minPrice: string;
  type: string;
  statesSet = new Set();
  state: string;
  totalRecords: number = 0;
  env = environment;
  page = 1;
  pageCount = 1;
  ts = new Date().getTime();
  constructor(
    public selectOptions: SelectOptionsService,
    private listingsService: ListingsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private imageService: ImageService,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private modalService: ModalService,
    private criteriaChangeService: CriteriaChangeService,
  ) {
    this.criteria = getCriteriaProxy('commercialPropertyListings', this) as CommercialPropertyListingCriteria;
    this.init();
    this.searchService.currentCriteria.pipe(untilDestroyed(this)).subscribe(criteria => {
      if (criteria && criteria.criteriaType === 'commercialPropertyListings') {
        this.criteria = criteria as CommercialPropertyListingCriteria;
        this.search();
      }
    });
  }
  async ngOnInit() {}
  async init() {
    this.search();
  }
  async search() {
    const listingReponse = await this.listingsService.getListings(this.criteria, 'commercialProperty');
    this.listings = (<ResponseCommercialPropertyListingArray>listingReponse).results;
    this.totalRecords = (<ResponseCommercialPropertyListingArray>listingReponse).totalCount;
    this.pageCount = this.totalRecords % LISTINGS_PER_PAGE === 0 ? this.totalRecords / LISTINGS_PER_PAGE : Math.floor(this.totalRecords / LISTINGS_PER_PAGE) + 1;
    this.page = this.criteria.page ? this.criteria.page : 1;
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }
  onPageChange(page: any) {
    this.criteria.start = (page - 1) * LISTINGS_PER_PAGE;
    this.criteria.length = LISTINGS_PER_PAGE;
    this.criteria.page = page;
    this.search();
  }
  reset() {
    this.criteria.title = null;
  }
  getTS() {
    return new Date().getTime();
  }
  getDaysListed(listing: CommercialPropertyListing) {
    return dayjs().diff(listing.created, 'day');
  }
  // New methods for filter actions
  clearAllFilters() {
    // Reset criteria to default values
    resetCommercialPropertyListingCriteria(this.criteria);

    // Reset pagination
    this.criteria.page = 1;
    this.criteria.start = 0;

    this.criteriaChangeService.notifyCriteriaChange();

    // Search with cleared filters
    this.searchService.search(this.criteria);
  }

  async openFilterModal() {
    // Open the search modal with current criteria
    const modalResult = await this.modalService.showModal(this.criteria);
    if (modalResult.accepted) {
      this.searchService.search(this.criteria);
    } else {
      this.criteria = assignProperties(this.criteria, modalResult.criteria);
    }
  }
}
