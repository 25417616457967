import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { initFlowbite } from 'flowbite';
import { Subscription } from 'rxjs';
import { ValidationMessagesService } from '../validation-messages.service';

@Component({
  selector: 'app-base-input',
  template: ``,
  standalone: true,
  imports: [],
})
export abstract class BaseInputComponent implements ControlValueAccessor {
  @Input() value: any = '';
  validationMessage: string = '';
  onChange: any = () => {};
  onTouched: any = () => {};
  subscription: Subscription | null = null;
  @Input() label: string = '';
  // @Input() id: string = '';
  @Input() name: string = '';
  isTooltipVisible = false;
  constructor(protected validationMessagesService: ValidationMessagesService) {}
  ngOnInit() {
    this.subscription = this.validationMessagesService.messages$.subscribe(() => {
      this.updateValidationMessage();
    });
    setTimeout(() => {
      initFlowbite();
    }, 10);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  updateValidationMessage(): void {
    this.validationMessage = this.validationMessagesService.getMessage(this.name);
  }
  setDisabledState?(isDisabled: boolean): void {}
  toggleTooltip(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isTooltipVisible = !this.isTooltipVisible;
  }
}
