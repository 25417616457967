import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private previousUrl: string | undefined;
  private currentUrl: string | undefined;

  constructor(private router: Router, private location: Location) {
    this.setupRouterListener();
  }

  private setupRouterListener(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.urlAfterRedirects;
    });
  }

  get canGoBack(): boolean {
    return !!this.previousUrl || window.history.length > 2;
  }

  goBack(): void {
    if (this.canGoBack) {
      this.location.back();
    }
  }
}
