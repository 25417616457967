<div class="container mx-auto p-4">
  <div class="bg-white rounded-lg drop-shadow-custom-bg-mobile md:drop-shadow-custom-bg overflow-hidden relative">
    <button
      (click)="historyService.goBack()"
      class="absolute top-4 right-4 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 print:hidden"
    >
      <i class="fas fa-times"></i>
    </button>
    @if(listing){
    <div class="p-6 flex flex-col lg:flex-row">
      <!-- Left column -->
      <div class="w-full lg:w-1/2 pr-0 lg:pr-6">
        <h1 class="text-2xl font-bold mb-4">{{ listing.title }}</h1>
        <p class="mb-4" [innerHTML]="description"></p>

        <div class="space-y-2">
          <div *ngFor="let detail of listingDetails; let i = index" class="flex flex-col sm:flex-row" [ngClass]="{ 'bg-gray-100': i % 2 === 0 }">
            <div class="w-full sm:w-1/3 font-semibold p-2">{{ detail.label }}</div>

            <div class="w-full sm:w-2/3 p-2" *ngIf="!detail.isHtml && !detail.isListingBy">{{ detail.value }}</div>

            <div class="w-full sm:w-2/3 p-2 flex space-x-2" [innerHTML]="detail.value" *ngIf="detail.isHtml && !detail.isListingBy"></div>

            <div class="w-full sm:w-2/3 p-2 flex space-x-2" *ngIf="detail.isListingBy">
              <a routerLink="/details-user/{{ listingUser.id }}" class="text-blue-600 dark:text-blue-500 hover:underline">{{ listingUser.firstname }} {{ listingUser.lastname }}</a>
              <img src="{{ env.imageBaseUrl }}/pictures/logo/{{ listing.imageName }}.avif?_ts={{ ts }}" class="mr-5 lg:mb-0" style="max-height: 30px; max-width: 100px" />
            </div>
          </div>
        </div>
        <div class="py-4 print:hidden">
          @if(listing && listingUser && (listingUser?.email===user?.email || (authService.isAdmin() | async))){
          <div class="inline">
            <button class="share share-edit text-white font-bold text-xs py-1.5 px-2 inline-flex items-center" [routerLink]="['/editBusinessListing', listing.id]">
              <i class="fa-regular fa-pen-to-square"></i>
              <span class="ml-2">Edit</span>
            </button>
          </div>
          } @if(user){
          <div class="inline">
            <button class="share share-save text-white font-bold text-xs py-1.5 px-2 inline-flex items-center" (click)="save()" [disabled]="listing.favoritesForUser.includes(user.email)">
              <i class="fa-regular fa-heart"></i>
              @if(listing.favoritesForUser.includes(user.email)){
              <span class="ml-2">Saved ...</span>
              }@else {
              <span class="ml-2">Save</span>
              }
            </button>
          </div>
          }
          <share-button button="print" showText="true" (click)="createEvent('print')"></share-button>
          <!-- <share-button button="email" showText="true"></share-button> -->
          <div class="inline">
            <button class="share share-email text-white font-bold text-xs py-1.5 px-2 inline-flex items-center" (click)="showShareByEMail()">
              <i class="fa-solid fa-envelope"></i>
              <span class="ml-2">Email</span>
            </button>
          </div>

          <share-button button="facebook" showText="true" (click)="createEvent('facebook')"></share-button>
          <share-button button="x" showText="true" (click)="createEvent('x')"></share-button>
          <share-button button="linkedin" showText="true" (click)="createEvent('linkedin')"></share-button>
        </div>
        <!-- Karte hinzufügen, wenn Straße vorhanden ist -->
        <div *ngIf="listing.location.street" class="mt-6">
          <h2 class="text-lg font-semibold mb-2">Location Map</h2>
          <!-- <div style="height: 300px" leaflet [leafletOptions]="mapOptions" [leafletLayers]="mapLayers" [leafletCenter]="mapCenter" [leafletZoom]="mapZoom"></div> -->
          <div style="height: 400px" leaflet [leafletOptions]="mapOptions" [leafletLayers]="mapLayers" [leafletCenter]="mapCenter" [leafletZoom]="mapZoom" (leafletMapReady)="onMapReady($event)"></div>
        </div>
      </div>

      <!-- Right column -->
      <div class="w-full lg:w-1/2 mt-6 lg:mt-0 print:hidden">
        <!-- <h2 class="text-lg font-semibold my-4">Contact the Author of this Listing</h2> -->
        <div class="md:mt-8 mb-4 text-2xl font-bold mb-4">Contact the Author of this Listing</div>
        <p class="text-sm mb-4">Please include your contact info below</p>
        <form class="space-y-4">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <app-validated-input label="Your Name" name="name" [(ngModel)]="mailinfo.sender.name"></app-validated-input>
            <app-validated-input label="Your Email" name="email" [(ngModel)]="mailinfo.sender.email" kind="email"></app-validated-input>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <app-validated-input label="Phone Number" name="phoneNumber" [(ngModel)]="mailinfo.sender.phoneNumber" mask="(000) 000-0000"></app-validated-input>
            <!-- <app-validated-input label="Country/State" name="state" [(ngModel)]="mailinfo.sender.state"></app-validated-input> -->
            <app-validated-ng-select label="State" name="state" [(ngModel)]="mailinfo.sender.state" [items]="selectOptions?.states"></app-validated-ng-select>
          </div>

          <div>
            <app-validated-textarea label="Questions/Comments" name="comments" [(ngModel)]="mailinfo.sender.comments"></app-validated-textarea>
          </div>
          <button (click)="mail()" class="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">Submit</button>
        </form>
      </div>
    </div>
    }
  </div>
</div>
