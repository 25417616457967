import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Optional() @Inject('TIMEOUT_DURATION') private timeoutDuration: number = 5000) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Überprüfen, ob die URL mit '/ai' endet
    if (req.url.endsWith('/ai')) {
      return next.handle(req).pipe(
        timeout(this.timeoutDuration),
        catchError((error: any) => {
          if (error instanceof TimeoutError) {
            // Timeout error handling
            return throwError(
              () =>
                new HttpErrorResponse({
                  error: 'Request timed out',
                  status: 408, // HTTP status code for Request Timeout
                }),
            );
          }
          return throwError(() => error);
        }),
      );
    }

    // Für alle anderen URLs ohne Timeout fortfahren
    return next.handle(req);
  }
}
