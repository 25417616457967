import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRight, faEnvelope, faLock, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';
@Component({
  selector: 'app-login-register',
  standalone: true,
  imports: [CommonModule, FormsModule, FontAwesomeModule],
  templateUrl: './login-register.component.html',
})
export class LoginRegisterComponent {
  email: string = '';
  password: string = '';
  confirmPassword: string = '';
  isLoginMode: boolean = true; // true: Login, false: Registration
  errorMessage: string = '';
  envelope = faEnvelope;
  lock = faLock;
  arrowRight = faArrowRight;
  userplus = faUserPlus;
  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private loadingService: LoadingService) {}

  ngOnInit(): void {
    // Set mode based on query parameter "mode"
    this.route.queryParamMap.subscribe(params => {
      const mode = params.get('mode');
      this.isLoginMode = mode !== 'register';
    });
  }

  toggleMode(): void {
    this.isLoginMode = !this.isLoginMode;
    this.errorMessage = '';
  }

  // Login with Email
  onSubmit(): void {
    this.errorMessage = '';
    if (this.isLoginMode) {
      this.authService
        .loginWithEmail(this.email, this.password)
        .then(userCredential => {
          console.log('Successfully logged in:', userCredential);
          this.router.navigate([`home`]);
        })
        .catch(error => {
          console.error('Error during email login:', error);
          this.errorMessage = error.message;
        });
    } else {
      // Registration mode: also check if passwords match
      if (this.password !== this.confirmPassword) {
        console.error('Passwords do not match');
        this.errorMessage = 'Passwords do not match.';
        return;
      }
      this.loadingService.startLoading('googleAuth');
      this.authService
        .registerWithEmail(this.email, this.password)
        .then(userCredential => {
          console.log('Successfully registered:', userCredential);
          this.loadingService.stopLoading('googleAuth');
          this.router.navigate(['emailVerification']);
        })
        .catch(error => {
          this.loadingService.stopLoading('googleAuth');
          console.error('Error during registration:', error);
          if (error.code === 'auth/email-already-in-use') {
            this.errorMessage = 'This email address is already in use. Please try logging in.';
          } else {
            this.errorMessage = error.message;
          }
        });
    }
  }

  // Login with Google
  loginWithGoogle(): void {
    this.errorMessage = '';
    this.authService
      .loginWithGoogle()
      .then(userCredential => {
        console.log('Successfully logged in with Google:', userCredential);
        this.router.navigate([`home`]);
      })
      .catch(error => {
        console.error('Error during Google login:', error);
        this.errorMessage = error.message;
      });
  }
}
