<header class="w-full flex justify-between items-center p-4 bg-white top-0 z-10 h-16 md:h-20">
  <img src="assets/images/header-logo.png" alt="Logo" class="h-8 md:h-10" />
  <div class="hidden md:flex items-center space-x-4">
    @if(user){
    <a routerLink="/account" class="text-blue-600 border border-blue-600 px-3 py-2 rounded">Account</a>
    } @else {
    <!-- <a routerLink="/pricing" class="text-gray-800">Pricing</a> -->
    <a routerLink="/login" [queryParams]="{ mode: 'login' }" class="text-blue-600 border border-blue-600 px-3 py-2 rounded">Log In</a>
    <a routerLink="/login" [queryParams]="{ mode: 'register' }" class="text-white bg-blue-600 px-4 py-2 rounded">Register</a>
    <!-- <a routerLink="/login" class="text-blue-500 hover:underline">Login/Register</a> -->
    }
  </div>
  <button (click)="toggleMenu()" class="md:hidden text-gray-600">
    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
    </svg>
  </button>
</header>

<div *ngIf="isMenuOpen" class="fixed inset-0 bg-gray-800 bg-opacity-75 z-20">
  <div class="flex flex-col items-center justify-center h-full">
    <!-- <a href="#" class="text-white text-xl py-2">Pricing</a> -->
    @if(user){
    <a routerLink="/account" class="text-white text-xl py-2">Account</a>
    } @else {
    <a routerLink="/login" [queryParams]="{ mode: 'login' }" class="text-white text-xl py-2">Log In</a>
    <a routerLink="/login" [queryParams]="{ mode: 'register' }" class="text-white text-xl py-2">Register</a>
    }
    <button (click)="toggleMenu()" class="text-white mt-4">
      <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </button>
  </div>
</div>

<!-- ==== ANPASSUNGEN START ==== -->
<!-- 1. px-4 für <main> (vorher px-2 sm:px-4) -->
<main class="flex flex-col items-center justify-center px-4 w-full flex-grow">
  <div class="bg-cover-custom py-12 md:py-20 lg:py-32 flex flex-col w-full rounded-xl lg:rounded-2xl md:drop-shadow-custom-md lg:drop-shadow-custom-lg min-h-[calc(100vh_-_20rem)] lg:min-h-[calc(100vh_-_10rem)]">
    <div class="flex justify-center w-full">
      <!-- 3. Für Mobile: m-2 statt max-w-xs; ab sm: wieder max-width und kein Margin -->
      <div class="w-full m-2 sm:m-0 sm:max-w-md md:max-w-xl lg:max-w-2xl xl:max-w-3xl">
        <!-- Schriftgrößen angepasst (wie vorher) -->
        <h1 class="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-blue-900 mb-3 sm:mb-4 text-center">Find businesses for sale.</h1>
        <!-- Abstand unten angepasst (wie vorher) -->
        <p class="text-base md:text-lg lg:text-xl text-blue-600 mb-6 md:mb-8 text-center">Unlocking Exclusive Opportunities - Empowering Entrepreneurial Dreams</p>
        <!-- Restliche Anpassungen (Innenabstände, Button-Paddings etc.) bleiben wie im vorherigen Schritt -->
        <div class="bg-white bg-opacity-80 pb-4 md:pb-6 pt-2 px-3 sm:px-4 md:px-6 rounded-lg shadow-lg w-full" [ngClass]="{ 'pt-6': aiSearch }">
          @if(!aiSearch){
          <div class="text-sm lg:text-base mb-1 text-center text-gray-500 border-gray-200 dark:text-gray-400 dark:border-gray-700 flex justify-between">
            <ul class="flex flex-wrap -mb-px w-full">
              <li class="w-[33%]">
                <a
                  (click)="changeTab('business')"
                  [ngClass]="
                    activeTabAction === 'business'
                      ? ['text-blue-600', 'border-blue-600', 'active', 'dark:text-blue-500', 'dark:border-blue-500']
                      : ['border-transparent', 'hover:text-gray-600', 'hover:border-gray-300', 'dark:hover:text-gray-300']
                  "
                  class="hover:cursor-pointer inline-block px-1 py-2 md:p-4 border-b-2 rounded-t-lg"
                  >Businesses</a
                >
              </li>
              @if ((numberOfCommercial$ | async) > 0) {
              <li class="w-[33%]">
                <a
                  (click)="changeTab('commercialProperty')"
                  [ngClass]="
                    activeTabAction === 'commercialProperty'
                      ? ['text-blue-600', 'border-blue-600', 'active', 'dark:text-blue-500', 'dark:border-blue-500']
                      : ['border-transparent', 'hover:text-gray-600', 'hover:border-gray-300', 'dark:hover:text-gray-300']
                  "
                  class="hover:cursor-pointer inline-block px-1 py-2 md:p-4 border-b-2 rounded-t-lg"
                  >Properties</a
                >
              </li>
              } @if ((numberOfBroker$ | async) > 0) {
              <li class="w-[33%]">
                <a
                  (click)="changeTab('broker')"
                  [ngClass]="
                    activeTabAction === 'broker'
                      ? ['text-blue-600', 'border-blue-600', 'active', 'dark:text-blue-500', 'dark:border-blue-500']
                      : ['border-transparent', 'hover:text-gray-600', 'hover:border-gray-300', 'dark:hover:text-gray-300']
                  "
                  class="hover:cursor-pointer inline-block px-1 py-2 md:p-4 border-b-2 rounded-t-lg"
                  >Professionals</a
                >
              </li>
              }
            </ul>
          </div>
          } @if(criteria && !aiSearch){
          <div class="w-full max-w-3xl mx-auto bg-white rounded-lg flex flex-col md:flex-row md:border md:border-gray-300">
            <div class="md:flex-none md:w-48 flex-1 md:border-r border-gray-300 overflow-hidden mb-2 md:mb-0">
              <div class="relative max-sm:border border-gray-300 rounded-md">
                <select
                  class="appearance-none bg-transparent w-full py-3 px-4 pr-8 focus:outline-none md:border-none rounded-md md:rounded-none"
                  [ngModel]="criteria.types"
                  (ngModelChange)="onTypesChange($event)"
                  [ngClass]="{ 'placeholder-selected': criteria.types.length === 0 }"
                >
                  <option [value]="[]">{{ getPlaceholderLabel() }}</option>
                  @for(type of getTypes(); track type){
                  <option [value]="type.value">{{ type.name }}</option>
                  }
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <i class="fas fa-chevron-down text-xs"></i>
                </div>
              </div>
            </div>

            <div class="md:flex-auto md:w-36 flex-grow md:border-r border-gray-300 mb-2 md:mb-0">
              <div class="relative max-sm:border border-gray-300 rounded-md">
                <ng-select
                  class="custom md:border-none rounded-md md:rounded-none"
                  [multiple]="false"
                  [hideSelected]="true"
                  [trackByFn]="trackByFn"
                  [minTermLength]="2"
                  [loading]="cityLoading"
                  typeToSearchText="Please enter 2 or more characters"
                  [typeahead]="cityInput$"
                  [ngModel]="cityOrState"
                  (ngModelChange)="setCityOrState($event)"
                  placeholder="Enter City or State ..."
                  groupBy="type"
                >
                  @for (city of cities$ | async; track city.id) { @let state = city.type==='city'?city.content.state:''; @let separator = city.type==='city'?' - ':'';
                  <ng-option [value]="city">{{ city.content.name }}{{ separator }}{{ state }}</ng-option>
                  }
                </ng-select>
              </div>
            </div>
            @if (criteria.radius && !aiSearch){
            <div class="md:flex-none md:w-36 flex-1 md:border-r border-gray-300 mb-2 md:mb-0">
              <div class="relative max-sm:border border-gray-300 rounded-md">
                <select
                  class="appearance-none bg-transparent w-full py-3 px-4 pr-8 focus:outline-none md:border-none rounded-md md:rounded-none"
                  (ngModelChange)="onRadiusChange($event)"
                  [ngModel]="criteria.radius"
                  [ngClass]="{ 'placeholder-selected': !criteria.radius }"
                >
                  <option [value]="null">City Radius</option>
                  @for(dist of selectOptions.distances; track dist){
                  <option [value]="dist.value">{{ dist.name }}</option>
                  }
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <i class="fas fa-chevron-down text-xs"></i>
                </div>
              </div>
            </div>
            }
            <div class="bg-blue-600 hover:bg-blue-500 transition-colors duration-200 max-sm:rounded-md">
              @if(getNumberOfFiltersSet()>0 && numberOfResults$){
              <button class="w-full h-full text-white font-semibold py-2 px-4 md:py-3 md:px-6 focus:outline-none rounded-md md:rounded-none min-h-[48px]" (click)="search()">
                Search ({{ numberOfResults$ | async }})
              </button>
              }@else {
              <button class="w-full h-full text-white font-semibold py-2 px-4 md:py-3 md:px-6 focus:outline-none rounded-md md:rounded-none min-h-[48px]" (click)="search()">Search</button>
              }
            </div>
          </div>
          }
          <!-- <div class="mt-4 flex items-center justify-center text-gray-700">
            <span class="mr-2">AI-Search</span>
            <span [attr.data-tooltip-target]="tooltipTargetBeta" class="bg-sky-300 text-teal-800 text-xs font-semibold px-2 py-1 rounded">BETA</span>
            <app-tooltip [id]="tooltipTargetBeta" text="AI will convert your input into filter criteria. Please check them in the filter menu after search"></app-tooltip>
            <span class="ml-2">- Try now</span>
            <div class="ml-4 relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
              <input (click)="toggleAiSearch()" type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer" />
              <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</main>
<!-- ==== ANPASSUNGEN ENDE ==== -->
