import { HANDLED_MAP_METHODS } from '../methods/map.js';
import CloneObject from './clone-object.js';
export default class CloneMap extends CloneObject {
  static isHandledMethod(name) {
    return HANDLED_MAP_METHODS.has(name);
  }
  undo(object) {
    for (const [key, value] of this.clone.entries()) {
      object.set(key, value);
    }
    for (const key of object.keys()) {
      if (!this.clone.has(key)) {
        object.delete(key);
      }
    }
  }
}