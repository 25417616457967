import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loading$ = new BehaviorSubject<string[]>([]);
  private loadingTextSubject = new BehaviorSubject<string | null>(null);
  private excludedUrls: string[] = ['/findTotal', '/geo']; // Liste der URLs, für die kein Ladeindikator angezeigt werden soll

  loadingText$: Observable<string | null> = this.loadingTextSubject.asObservable();

  public isLoading$ = this.loading$.asObservable().pipe(
    map(loading => loading.length > 0),
    debounceTime(200),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public startLoading(type: string, url?: string): void {
    if (this.shouldShowLoading(url)) {
      if (!this.loading$.value.includes(type)) {
        this.loading$.next(this.loading$.value.concat(type));
        if (this.isImageUpload(type, url)) {
          this.loadingTextSubject.next("Please wait - we're processing your image...");
        } else {
          this.loadingTextSubject.next(null);
        }
      }
    }
  }

  public stopLoading(type: string): void {
    if (this.loading$.value.includes(type)) {
      this.loading$.next(this.loading$.value.filter(t => t !== type));
      this.loadingTextSubject.next(null);
    }
  }

  private shouldShowLoading(url?: string): boolean {
    if (!url) return true;
    return !this.excludedUrls.some(excludedUrl => url.includes(excludedUrl));
  }

  private isImageUpload(type: string, url?: string): boolean {
    return type === 'uploadImage' || url?.includes('uploadImage') || url?.includes('uploadPropertyPicture') || url?.includes('uploadProfile') || url?.includes('uploadCompanyLogo');
  }
}
