<div class="flex flex-col items-center justify-center min-h-screen">
  <div class="bg-white p-8 rounded-lg drop-shadow-custom-bg w-full max-w-md">
    <h2 class="text-2xl font-bold mb-6 text-center text-gray-800">
      {{ isLoginMode ? 'Login' : 'Sign Up' }}
    </h2>

    <!-- Toggle Switch mit Flowbite -->
    <div class="flex items-center justify-center mb-6">
      <span class="mr-3 text-gray-700 font-medium">Login</span>
      <label for="toggle-switch" class="inline-flex relative items-center cursor-pointer">
        <input type="checkbox" id="toggle-switch" class="sr-only peer" [checked]="!isLoginMode" (change)="toggleMode()" />
        <div
          class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:bg-gray-700 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
        ></div>
      </label>
      <span class="ml-3 text-gray-700 font-medium">Sign Up</span>
    </div>

    <!-- E-Mail Eingabe -->
    <div class="mb-4">
      <label for="email" class="block text-gray-700 mb-2 font-medium">E-Mail</label>
      <div class="relative">
        <input
          id="email"
          type="email"
          [(ngModel)]="email"
          placeholder="Please enter E-Mail Address"
          class="w-full px-3 py-2 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <fa-icon [icon]="envelope" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"></fa-icon>
      </div>
    </div>

    <!-- Passwort Eingabe -->
    <div class="mb-4">
      <label for="password" class="block text-gray-700 mb-2 font-medium">Password</label>
      <div class="relative">
        <input
          id="password"
          type="password"
          [(ngModel)]="password"
          placeholder="Please enter Password"
          class="w-full px-3 py-2 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <fa-icon [icon]="lock" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"></fa-icon>
      </div>
    </div>

    <!-- Passwort-Bestätigung nur im Registrierungsmodus -->
    <div *ngIf="!isLoginMode" class="mb-6">
      <label for="confirmPassword" class="block text-gray-700 mb-2 font-medium">Confirm Password</label>
      <div class="relative">
        <input
          id="confirmPassword"
          type="password"
          [(ngModel)]="confirmPassword"
          placeholder="Repeat Password"
          class="w-full px-3 py-2 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <fa-icon [icon]="lock" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"></fa-icon>
      </div>
    </div>

    <!-- Fehlermeldung -->
    <div *ngIf="errorMessage" class="text-red-500 text-center mb-4 text-sm">
      {{ errorMessage }}
    </div>

    <!-- Submit Button -->
    <button (click)="onSubmit()" class="w-full flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white py-2.5 rounded-lg mb-4 transition-colors duration-200">
      <!-- <fa-icon [icon]="isLoginMode ? 'fas fas-user-plus' : 'userplus'" class="mr-2"></fa-icon> -->
      <i *ngIf="isLoginMode" class="fa-solid fa-user-plus mr-2"></i>
      <i *ngIf="!isLoginMode" class="fa-solid fa-arrow-right mr-2"></i>
      {{ isLoginMode ? 'Sign in with Email' : 'Register' }}
    </button>

    <!-- Trennlinie -->
    <div class="flex items-center justify-center my-4">
      <span class="border-b w-1/5 md:w-1/4 border-gray-300"></span>
      <span class="text-xs text-gray-500 uppercase mx-2">or</span>
      <span class="border-b w-1/5 md:w-1/4 border-gray-300"></span>
    </div>

    <!-- Google Button -->
    <button (click)="loginWithGoogle()" class="w-full flex items-center justify-center bg-white border border-gray-300 hover:bg-gray-50 text-gray-700 py-2.5 rounded-lg transition-colors duration-200">
      <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path
          fill="#FFC107"
          d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"
        />
        <path fill="#FF3D00" d="M6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z" />
        <path fill="#4CAF50" d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0124 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z" />
        <path fill="#1976D2" d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 01-4.087 5.571l.003-.002 6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z" />
      </svg>
      Continue with Google
    </button>
  </div>
</div>
