import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BusinessListingCriteria, CommercialPropertyListingCriteria, UserListingCriteria } from '../../../../bizmatch-server/src/models/main.model';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private criteriaSource = new Subject<BusinessListingCriteria | CommercialPropertyListingCriteria | UserListingCriteria>();
  currentCriteria = this.criteriaSource.asObservable();

  constructor() {}

  search(criteria: BusinessListingCriteria | CommercialPropertyListingCriteria | UserListingCriteria): void {
    this.criteriaSource.next(criteria);
  }
}
