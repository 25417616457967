import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { KeyValue, KeyValueAsSortBy, KeyValueStyle } from '../../../../bizmatch-server/src/models/main.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SelectOptionsService {
  private apiBaseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}

  async init() {
    const allSelectOptions = await lastValueFrom(this.http.get<any>(`${this.apiBaseUrl}/bizmatch/select-options`));
    this.typesOfBusiness = allSelectOptions.typesOfBusiness;
    this.prices = allSelectOptions.prices;
    this.listingCategories = allSelectOptions.listingCategories;
    this.customerTypes = allSelectOptions.customerTypes;
    this.customerSubTypes = allSelectOptions.customerSubTypes;
    this.states = allSelectOptions.locations;
    this.gender = allSelectOptions.gender;
    this.typesOfCommercialProperty = allSelectOptions.typesOfCommercialProperty;
    this.distances = allSelectOptions.distances;
    this.sortByOptions = allSelectOptions.sortByOptions;
  }
  public typesOfBusiness: Array<KeyValueStyle>;

  public typesOfCommercialProperty: Array<KeyValueStyle>;

  public prices: Array<KeyValue>;

  public listingCategories: Array<KeyValue>;

  public customerTypes: Array<KeyValue>;

  public gender: Array<KeyValue>;

  public states: Array<any>;
  public customerSubTypes: Array<KeyValue>;
  public distances: Array<KeyValue>;
  public sortByOptions: Array<KeyValueAsSortBy>;
  getSortByOption(value: string) {
    return this.sortByOptions.find(l => l.value === value)?.name;
  }
  getState(value: string): string {
    return this.states.find(l => l.value === value)?.name;
  }
  getStateInitials(name: string): string {
    return this.states.find(l => l.name === name?.toUpperCase())?.value;
  }
  getBusiness(value: string): string {
    return this.typesOfBusiness.find(t => t.value === value)?.name;
  }
  getCommercialProperty(value: string): string {
    return this.typesOfCommercialProperty.find(t => t.value === value)?.name;
  }
  getListingsCategory(value: string): string {
    return this.listingCategories.find(l => l.value === value)?.name;
  }
  getCustomerType(value: string): string {
    return this.customerTypes.find(c => c.value === value)?.name;
  }
  getCustomerSubType(value: string): string {
    return this.customerSubTypes.find(c => c.value === value)?.name;
  }
  getGender(value: string): string {
    return this.gender.find(c => c.value === value)?.name;
  }
  getIconType(value: string): string {
    return this.typesOfBusiness.find(c => c.value === value)?.icon;
  }
  getTextColorType(value: string): string {
    return this.typesOfBusiness.find(c => c.value === value)?.textColorClass;
  }
  getIconAndTextColorType(value: string): string {
    const category = this.typesOfBusiness.find(c => c.value === value);
    return `${category?.icon} ${category?.textColorClass}`;
  }
  getIconTypeOfCommercials(value: string): string {
    return this.typesOfCommercialProperty.find(c => c.value === value)?.icon;
  }
  getIconAndTextColorTypeOfCommercials(value: string): string {
    const category = this.typesOfCommercialProperty.find(c => c.value === value);
    return `${category?.icon} ${category?.textColorClass}`;
  }
  getTextColorTypeOfCommercial(value: string): string {
    return this.typesOfCommercialProperty.find(c => c.value === value)?.textColorClass;
  }
}
