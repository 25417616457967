import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ValidationMessage {
  field: string;
  message: string;
}

export const VALIDATION_MESSAGES = new InjectionToken<ValidationMessagesService>('VALIDATION_MESSAGES');

@Injectable({
  providedIn: 'root',
})
export class ValidationMessagesService {
  private messagesSubject = new BehaviorSubject<ValidationMessage[]>([]);
  messages$: Observable<ValidationMessage[]> = this.messagesSubject.asObservable();

  updateMessages(messages: ValidationMessage[]): void {
    this.messagesSubject.next(messages);
  }

  clearMessages(): void {
    this.messagesSubject.next([]);
  }

  getMessage(field: string): string | null {
    const messages = this.messagesSubject.value;
    const message = messages.find(m => m.field === field);
    return message ? message.message : null;
  }
}
