import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { createLogger } from '../utils/utils';
const logger = createLogger('AuthGuard');
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    const token = await this.authService.getToken();
    if (token) {
      return true;
    } else {
      this.router.navigate(['/login-register']);
      return false;
    }
  }
}
