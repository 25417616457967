import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Prüfe, ob die Anfrage an die apiBaseUrl gerichtet ist
    const isApiRequest = req.url.startsWith(environment.apiBaseUrl);

    if (!isApiRequest) {
      // Wenn es keine API-Anfrage ist, leite die Anfrage unverändert weiter
      return next.handle(req);
    }

    // Wenn es eine API-Anfrage ist, füge den Token hinzu (falls vorhanden)
    return from(this.authService.getToken()).pipe(
      switchMap(token => {
        if (token) {
          const clonedReq = req.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
          });
          return next.handle(clonedReq);
        }
        return next.handle(req);
      }),
    );
  }
}
